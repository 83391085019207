const TagItemTypes = {
  Case: {
    TotalLoss: {
      color: '#fcb38d',
      text: 'Copart',
    },
    NeedsAction: {
      text: 'Has a question',
    },
    FailedMessage: {
      color: '#B00020',
      text: 'Failed to Send',
      textColor: 'white',
    },
    OptedOut: {
      text: 'Opted-Out',
      textColor: '#5E7388',
      color: '#DDE3E9',
    },
    NoteMention: {
      color: '#FFF5B4',
      textColor: '#5E7388',
      text: 'Note Mention',
    },
    ConfirmedFNOL: {
      text: 'Total Loss',
      color: '#FFBA87',
    },
    PossibleFNOL: {
      text: 'Possible Total Loss',
      color: '#CEDCFF',
    },
    LockedOut: {
      text: 'Locked Out',
      color: '#B00020',
      textColor: 'white',
    },
    NotOptedIn: {
      text: 'Not Opted-In',
      color: '#DDE3E9',
      textColor: '#5E7388',
    },
    ReleaseIssue: {
      color: '#fcb38d',
      text: 'Release Issue',
      resolvedColor: '#f2f3f7',
      unresolvedColor: '#fcb38d',
    },
  },
  Message: {
    NeedsAction: {
      text: 'Has a question',
    },
  },
};
export default TagItemTypes;
