/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-filename-extension */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import _ from 'lodash';
import DateInput from '@app/components/chakra/date-input';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { ContactCaseRoleEnum } from '@app/types/create-case';
import { FNOL_WORKFLOW_CREATION_SOURCES, WORKFLOW_FNOL_CREATED_BY } from '@app/constants/workflows';
import { useDataTray, useShowDataTray } from './use-user-details';
import EditableLine from '../../../elements/EditableLine/EditableLine';
import { titleCase } from '../../../../helpers/common';
import { formatDate } from '../../../../helpers/datetime';
import { formatPhoneNumberV2 } from '../../../../helpers/format';
import { UserFormValidation } from '../../../../helpers/formValidation/userForm/userFormValidation';
import { ClaimsFormValidation } from '../../../../helpers/formValidation/claimsForm/claimsFormValidation';
import CollapsibleSection from '../../../elements/CollapsibleSection/CollapsibleSection';
import {
  PROPERTY_CLAIM_DATA,
  AUTO_CLAIM_DATA,
} from '../../../../constants/integration-constants';
import Notes from './Notes/Notes';
import LoadingIcon from '../../../icons/LoadingIcon';
import CaseProgress from '../../../CaseProgress/CaseProgress';
import selector from '../../../CaseProgress/selector';
import DataTray from '../../../NetworkDataTray/DataTray';
import './UserDetails.less';
import { usePrevious } from '../../../Hooks/usePrevious';
import MediaDetails from './Media/MediaDetails';
import CaseData from './CaseData/CaseData';

const formatMarleyNumber = (phone) => {
  const numbers = phone.replace('+1', '').replace(/\D/g, '');
  if (numbers.length < 10) {
    return numbers;
  }
  return formatPhoneNumberV2(numbers);
};

const NOTES = 'NOTES';
const USER_DETAILS = 'USER_DETAILS';
const MEDIA = 'MEDIA';
const CLAIM_DATA = 'CLAIM_DATA';
const CLAIM_PROGRESS = 'CLAIM_PROGRESS';

const UserDetails = ({
  authId,
  customer,
  jobPutRequest,
  activeCase,
  updateUser,
  caseTypes,
  createNote,
  deleteNote,
  getNotes,
  notes,
  mediaMessages,
  caseData,
}) => {
  const { currentStep } = useSelector(selector);

  const { autoClaimData, propertyClaimData } = useDataTray(activeCase);
  const {
    showAutoClaimData,
    showPropertyClaimData,
    showPizzaTracker,
    showNotes,
  } = useShowDataTray(activeCase);
  const chatView = useSelector((state) => state.ui.chatView);

  const [showEdit, setShowEdit] = useState(false);
  const [savedCustomer, setSavedCustomer] = useState({});
  const [modifiedCustomer, setModifiedCustomer] = useState({});
  const [savedCase, setSavedCase] = useState({});
  const [modifiedCase, setModifiedCase] = useState({});
  const [currentCaseType, setCurrentCaseType] = useState();
  const [caseErrors, setCaseErrors] = useState([]);
  const [customerErrors, setCustomerErrors] = useState([]);
  const [canSave, setCanSave] = useState(true);
  const [openSection, setOpenSection] = useState(USER_DETAILS);

  useEffect(() => {
    if (activeCase?.unreadNoteMentions?.[authId] !== undefined) {
      setOpenSection(NOTES);
    }
  }, [activeCase?.unreadNoteMentions, authId]);

  useEffect(() => {
    setCanSave(customerErrors.length === 0 && caseErrors.length === 0);
  }, [customerErrors, caseErrors]);

  useEffect(() => {
    setSavedCustomer(customer);
    setModifiedCustomer(customer);
  }, [customer]);

  const previousCaseId = usePrevious(_.get(activeCase, 'id'));
  useEffect(() => {
    if (previousCaseId !== _.get(activeCase, 'id')) {
      setSavedCase(activeCase);
      setModifiedCase(activeCase);
    }
    if (activeCase && caseTypes) {
      const { caseTypeId } = activeCase;
      setCurrentCaseType(
        caseTypes && caseTypes.find((caseType) => caseType.id === caseTypeId),
      );
    }
  }, [activeCase, caseTypes, previousCaseId, savedCase]);

  const resetData = () => {
    setModifiedCustomer(savedCustomer);
    setModifiedCase(savedCase);
    setCustomerErrors([]);
    setCaseErrors([]);
  };

  const toggleOpenSection = (sectionId) => {
    // allows closing an open section
    setOpenSection(sectionId === openSection ? null : sectionId);
  };

  const validateUserChange = useCallback(
    ({ id, value, label }) => {
      const validation = UserFormValidation.userFormValidation({
        fieldName: id,
        userFormData: _.set({}, id, value),
      });
      const { isValid } = validation;
      const newError = { id, name: label.toLowerCase() };
      let errors;
      if (!isValid) {
        if (id === 'phoneNumber' || id === 'email') newError.isInvalid = true;
        errors = [...customerErrors, newError];
      } else {
        errors = customerErrors.filter((ce) => ce.id !== id);
      }
      setCustomerErrors(errors);

      return isValid;
    },
    [customerErrors],
  );

  const handleUpdate = useCallback(
    ({ id, value, label }) => {
      validateUserChange({ id, value, label });
      const modCus = { ...modifiedCustomer };
      _.set(
        modCus,
        id,
        id === 'phoneNumber' ? UserFormValidation.cleanPhone(value) : value,
      );
      _.set(modCus, 'name', `${modCus.firstName} ${modCus.lastName}`);
      setModifiedCustomer(modCus);
    },
    [modifiedCustomer, validateUserChange],
  );

  const validateCaseChange = useCallback(({ value, metaDataItem }) => {
    const { id, name } = metaDataItem;
    const isRequired = _.get(metaDataItem, 'required', false);
    const dataType = _.get(metaDataItem, 'dataType', 'Text');
    let isValid = true;

    // Checking if required and if valid format
    const newError = { id, name };
    if (isRequired && value.length === 0) {
      isValid = false;
      newError.isEmpty = true;
    }
    if (
      dataType === 'Number'
      && !ClaimsFormValidation.validateDeductible(value)
    ) {
      isValid = false;
      newError.isInvalid = true;
    }

    const errors = !isValid
      ? [...caseErrors, newError]
      : caseErrors.filter((ce) => ce.id !== id);
    setCaseErrors(errors);

    return isValid;
  }, [caseErrors]);

  const handleCaseUpdate = useCallback(({ id, value }) => {
    const metaDataItem = _.get(currentCaseType, 'metaData', []).find(
      (m) => m.id === id,
    );
    if (metaDataItem) {
      validateCaseChange({ value, metaDataItem });
      const newCase = _.cloneDeep(modifiedCase);
      _.set(newCase, `metaData.${id}`, value);
      setModifiedCase(newCase);
    }
  }, [currentCaseType, modifiedCase, validateCaseChange]);

  const saveData = () => {
    putUser();
    putCase();
    setShowEdit(false);
    setCaseErrors([]);
  };

  const putUser = () => {
    const {
      email, firstName, lastName, phoneNumber,
    } = modifiedCustomer;
    const {
      phoneNumber: oldPhoneNumber,
      firstName: oldFirstName,
      lastName: oldLastName,
      email: oldEmail,
    } = customer;
    const updatedData = {
      ...(firstName !== oldFirstName && { firstName }),
      ...(lastName !== oldLastName && { lastName }),
      ...(phoneNumber !== oldPhoneNumber && { phoneNumber }),
      ...(email !== oldEmail && { email }),
    };
    if (Object.keys(updatedData).length > 0) {
      updateUser(savedCustomer.id, updatedData);
    }
  };

  const putCase = () => {
    jobPutRequest(modifiedCase, null, false);
  };

  const formatErrorMessage = ({ isInvalid, label }) => `You must enter a ${isInvalid ? 'valid ' : ''}${label}`;

  const metaData = _.get(modifiedCase, 'metaData', []);
  const customerObj = modifiedCustomer;
  const userData = useMemo(
    () => [
      { id: 'firstName', label: 'First Name ', initHidden: true },
      { id: 'lastName', label: 'Last Name ', initHidden: true },
      {
        id: 'phoneNumber',
        label: 'Phone Number',
        format: formatPhoneNumberV2,
        subtext:
          "If you update a user's phone number, a new welcome message must be sent.",
      },
      {
        id: 'marleyNumber',
        label: 'Marley Number',
        format: formatMarleyNumber,
        disableEdit: true,
        hidden: !activeCase?.marleyNumber,
        value: activeCase?.marleyNumber,
      },
      { id: 'email', label: 'Email' },
    ],
    [activeCase?.marleyNumber],
  );

  const isEnduser = _.get(customer, 'role') === 'enduser';

  const notesNumber = useMemo(
    () => notes?.notes?.filter(
      (note) => !note?.archived || (note?.archived && note?.replies?.length > 0),
    )?.length || 0,
    [notes?.notes],
  );

  const renderModifiedCustomerFields = useMemo(
    () => (
      <>
        {modifiedCustomer
          && userData
            .filter((ud) => !((!showEdit && ud.initHidden) || ud.hidden))
            .map((ud) => {
              const rawValue = ud.value || _.get(modifiedCustomer, ud.id, '');
              const format = _.get(ud, 'format', (e) => e);
              const value = format(rawValue);
              const error = customerErrors.find((ce) => ce.id === ud.id);
              let subtext = showEdit && ud.subtext;
              if (error) {
                subtext = formatErrorMessage({
                  isInvalid: error.isInvalid,
                  label: error.name,
                });
              }

              return (
                <EditableLine
                  testId={`${ud?.id}-edit-field`}
                  key={ud?.id}
                  show={!ud.disableEdit && showEdit}
                  disableEdit={ud.disableEdit}
                  error={error}
                  className="detail-row"
                  placeholder={ud.value || 'N/A'}
                  label={ud.label}
                  subtext={subtext}
                  value={value}
                  onBlur={(e) => handleUpdate({
                    id: ud.id,
                    value: e.target.value,
                    label: ud.label,
                  })}
                  highlightMarleyNumberInRed={
                    ud.label === 'Marley Number'
                    && modifiedCase.marleyNumberStatus === 'NEW_MARLEY_NUMBER'
                  }
                />
              );
            })}
      </>
    ),
    [
      customerErrors,
      handleUpdate,
      modifiedCase.marleyNumberStatus,
      modifiedCustomer,
      showEdit,
      userData,
    ],
  );

  const creatorIdName = FNOL_WORKFLOW_CREATION_SOURCES.includes(activeCase?.creationSource)
    ? WORKFLOW_FNOL_CREATED_BY
    : _.get(activeCase, 'creatorId.name', '');

  return chatView?.status === 'loading' ? (
    <div className="chatComponent-state-box">
      <LoadingIcon size="lg" />
    </div>
  ) : (
    <div className="case-details sidebar-panel">
      {_.get(customerObj, 'name') && (
        <CollapsibleSection
          show={openSection === USER_DETAILS}
          onToggle={() => toggleOpenSection(USER_DETAILS)}
          label={_.get(customerObj, 'name', '')}
        >
          <div className="user-details-wrapper">
            <div className="user-name-wrap">
              {isEnduser && !showEdit && (
                <Button
                  onClick={() => setShowEdit(true)}
                  variant="outline"
                  size="sm"
                >
                  Edit
                </Button>
              )}
              {isEnduser && showEdit && (
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      setShowEdit(false);
                      resetData();
                    }}
                    variant="outline"
                    size="sm"
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={canSave ? saveData : () => { }}
                    isDisabled={!canSave}
                    size="sm"
                  >
                    Save
                  </Button>
                </ButtonGroup>
              )}
            </div>
            {renderModifiedCustomerFields}
            {activeCase && creatorIdName && (
              <EditableLine
                disableEdit
                className="detail-row"
                label="Created By"
                value={creatorIdName}
              />
            )}

            {currentCaseType
              && currentCaseType.metaData
                .sort((a, b) => (a.sortOrder || 1000) - (b.sortOrder || 1000))
                .filter((metaDataFieldDefinition) => {
                  if (!metaDataFieldDefinition) return false;
                  const metaDataValue = metaData[metaDataFieldDefinition.id];
                  const isEmptyString = metaDataValue === '';
                  return metaDataValue || isEmptyString;
                })
                .map((metaDataFieldDefinition) => {
                  const error = caseErrors.find(
                    (ce) => ce.id === metaDataFieldDefinition.id,
                  );
                  let subtext;
                  if (error) {
                    subtext = formatErrorMessage({
                      isInvalid: error.isInvalid,
                      label: error.name,
                    });
                  }
                  const label = titleCase(metaDataFieldDefinition.name);
                  const value = metaData[metaDataFieldDefinition.id];
                  const uneditableItems = ['insurance branding'];

                  if (metaDataFieldDefinition.dataType === 'calendarDay') {
                    return (
                      <EditableLine
                        key={metaDataFieldDefinition.name}
                        show={showEdit}
                        error={error}
                        className="detail-row"
                        label={label}
                        value={formatDate(value, 'just-date')}
                      >
                        <div className="form-field">
                          <DateInput
                            selectedDate={value ? new Date(value) : null}
                            handleDateChange={(date) => handleCaseUpdate({
                              id: metaDataFieldDefinition.id,
                              value: date?.toISOString() || '',
                            })}
                            dateLimit="past"
                            placeholderText="Select date"
                            useOldStyle
                          />
                        </div>
                      </EditableLine>
                    );
                  }
                  return (
                    <EditableLine
                      key={metaDataFieldDefinition.name}
                      disableEdit={uneditableItems.includes(
                        metaDataFieldDefinition.name,
                      )}
                      show={showEdit}
                      error={error}
                      subtext={subtext}
                      className="detail-row"
                      label={label}
                      value={value}
                      onBlur={(e) => handleCaseUpdate({
                        id: metaDataFieldDefinition.id,
                        value: e.target.value,
                      })}
                    />
                  );
                })}
            {activeCase?.roleType && (
              <EditableLine
                label="Case Role"
                value={ContactCaseRoleEnum[activeCase?.roleType]}
                disableEdit
                className="detail-row"
              />
            )}
          </div>
        </CollapsibleSection>
      )}
      {caseData && Object.keys(caseData).length > 0 ? (
        <CollapsibleSection
          show={openSection === CLAIM_DATA}
          onToggle={() => toggleOpenSection(CLAIM_DATA)}
          label="FNOL Data"
          dataTestId="collapsible-case-data"
        >
          <CaseData caseData={caseData} />
        </CollapsibleSection>
      ) : null}
      {showPizzaTracker && ( // copart pizza tracker
        <CollapsibleSection
          show={openSection === CLAIM_PROGRESS}
          onToggle={() => toggleOpenSection(CLAIM_PROGRESS)}
          label="Case Progress"
          dataTestId="collapsible-case-progress"
          className="collapsible-section-case-progress"
          subHeader={openSection !== CLAIM_PROGRESS && currentStep}
        >
          <CaseProgress />
        </CollapsibleSection>
      )}
      { // auto claim data tray
        showAutoClaimData && (
          <CollapsibleSection
            show={openSection === AUTO_CLAIM_DATA}
            onToggle={() => toggleOpenSection(AUTO_CLAIM_DATA)}
            label={AUTO_CLAIM_DATA}
            dataTestId="collapsible-auto-claim-data"
            className="collapsible-section-auto-claim-data"
          >
            <DataTray data={autoClaimData} type={AUTO_CLAIM_DATA} />
          </CollapsibleSection>
        )
      }
      { // property claim data tray
        showPropertyClaimData && (
          <CollapsibleSection
            show={openSection === PROPERTY_CLAIM_DATA}
            onToggle={() => toggleOpenSection(PROPERTY_CLAIM_DATA)}
            label={PROPERTY_CLAIM_DATA}
            dataTestId="collapsible-property-claim-data"
            className="collapsible-section-property-claim-data"
          >
            <DataTray data={propertyClaimData} type={PROPERTY_CLAIM_DATA} />
          </CollapsibleSection>
        )
      }

      <CollapsibleSection
        show={openSection === MEDIA}
        onToggle={() => toggleOpenSection(MEDIA)}
        disabled={mediaMessages?.length === 0}
        label={`Media (${mediaMessages?.length})`}
        dataTestId="collapsible-media"
        className={mediaMessages?.length ? 'collapsible-section-notes' : ''}
      >
        {
          openSection === MEDIA
          && (
            <MediaDetails
              mediaMessages={mediaMessages}
              referenceId={activeCase?.referenceId}
            />
          )
        }
      </CollapsibleSection>
      {showNotes && (
        <CollapsibleSection
          show={openSection === NOTES}
          onToggle={() => toggleOpenSection(NOTES)}
          className="collapsible-section-notes"
          label={`Notes (${notesNumber})`}
          dataTestId="collapsible-section-notes"
        >
          <Notes
            notes={notes}
            authId={authId}
            getNotes={getNotes}
            deleteNote={deleteNote}
            createNote={createNote}
            activeCase={activeCase}
          />
        </CollapsibleSection>
      )}
    </div>
  );
};

UserDetails.propTypes = {
  updateUser: PropTypes.func.isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  mediaMessages: PropTypes.arrayOf(PropTypes.shape(
    {
      providerId: PropTypes.string,
      fileUrl: PropTypes.string,
    },
  )).isRequired,
  caseTypes: PropTypes.arrayOf(Object).isRequired,
  activeCase: PropTypes.instanceOf(Object).isRequired,
  caseData: PropTypes.shape({}).isRequired,
};

export default UserDetails;
