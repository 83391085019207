import {
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  Box,
  MenuItem,
  Button,
  MenuGroup,
  Icon,
  type As,
  type MenuButtonProps,
  type MenuItemProps,
  type PlacementWithLogical,
  type MenuProps as ChakraMenuProps,
  useDisclosure,
  Badge,
  Flex,
  Spinner,
  MenuDivider,
  Tooltip,
} from '@chakra-ui/react';
import {
  CaretUpIcon, CaretDownIcon, CaretRightIcon, SearchBar,
} from '@himarley/unity';
import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';

interface ChildrenItems {
  id?: string;
  label: React.ReactNode | string;
  onClick?: () => void;
  rightIcon?: As;
}

interface InnerMenuProps {
  id: string;
  label: React.ReactNode;
  childrenItems: ChildrenItems[];
  placement?: PlacementWithLogical;
  leftIcon?: As;
  searchComponent?: React.ReactNode;
  openInnerMenuRef: React.RefObject<HTMLButtonElement> | null;
  setOpenInnerMenuRef: React.Dispatch<React.SetStateAction<
  React.RefObject<HTMLButtonElement> | null
  >>;
  badgeCount?: number;
}

const renderInnerMenuItem = (item: ChildrenItems) => {
  const isSelected = item.rightIcon !== undefined;

  return (
    <MenuItem
      key={item.id}
      onClick={item.onClick}
      data-multiselected={isSelected}
    >
      {item.label}
      {item.rightIcon && <Icon float="right" as={item.rightIcon} />}
    </MenuItem>
  );
};

const InnerMenu: React.FC<InnerMenuProps> = ({
  id,
  label,
  childrenItems,
  placement,
  leftIcon,
  searchComponent,
  openInnerMenuRef,
  setOpenInnerMenuRef,
  badgeCount,
}) => {
  const refInnerMenuButton = useRef<HTMLButtonElement>(null);
  const refInnerMenuList = useRef<HTMLDivElement>(null);

  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();

  const openInnerMenu = useCallback(() => {
    if (openInnerMenuRef?.current && openInnerMenuRef.current !== refInnerMenuButton.current) {
      openInnerMenuRef.current.click();
    }
    setOpenInnerMenuRef(refInnerMenuButton);
    onOpen();
  }, [onOpen, openInnerMenuRef, setOpenInnerMenuRef]);

  const toggleInnerMenu = useCallback(() => {
    if (isOpen) {
      setOpenInnerMenuRef(null);
      onClose();
    } else {
      openInnerMenu();
    }
  }, [isOpen, onClose, openInnerMenu, setOpenInnerMenuRef]);

  return (
    <ChakraMenu
      offset={[0, 0]}
      isOpen={isOpen}
      placement={placement || 'right-start'}
    >
      <MenuButton
        ref={refInnerMenuButton}
        textAlign="left"
        as={MenuItem}
        w="100%"
        icon={leftIcon && <Icon as={leftIcon} />}
        onMouseEnter={openInnerMenu}
        onClick={toggleInnerMenu}
        data-testid={`${id}-inner-menu`}
      >
        {label}
        <Icon as={CaretRightIcon} float="right" />
        {badgeCount ? (
          <Box float="right" ml={2} mr={2}>
            <Badge variant="number" top="0px" left="6px">
              {badgeCount}
            </Badge>
          </Box>
        ) : null}
      </MenuButton>
      <MenuList
        ref={refInnerMenuList}
        sx={{
          maxHeight: '300px',
          overflowY: 'auto',
        }}
        data-testid={`${id}-inner-menu-list`}
        zIndex={5}
      >
        {searchComponent}
        {childrenItems.map(renderInnerMenuItem)}
      </MenuList>
    </ChakraMenu>
  );
};

interface MenuProps extends Omit<ChakraMenuProps, 'children'> {
  id: string;
  selectedId?: string;
  defaultLabel?: string;
  options: Array<MenuItemProps & {
    id: string
    label: string | React.ReactNode
    rightIcon?: As
    rightIconTooltip?: string
    leftIcon?: As
    isDisabled?: boolean
    onClick?: () => void
    group?: string
    leftIconElement?: MenuItemProps['icon']
    childrenItems?: ChildrenItems[]
    searchComponent?: React.ReactNode
    badgeCount?: number;
  }>;
  isLazy?: boolean;
  handleSelect?: (e: string) => void;
  menuButtonProps?: {
    size?: string
    variant?: string
    textAlign?: MenuButtonProps['textAlign']
    as?: As,
    w?: string
    icon?: As
    sx?: MenuButtonProps['sx']
    'aria-labelledby'?: string
  };
  sx?: MenuButtonProps['sx']
  placement?: PlacementWithLogical;
  gutter?: number;
  onClose?: () => void;
  matchWidth?: boolean;
  searchable?: boolean;
  searchValue?: string;
  onSearchChange?: (value: string) => void;
  clearSelected?: () => void;
  isLoading?: boolean;
  increaseDataSet?: () => void;
  shouldLoadMoreItems?: boolean;
}

const Menu: React.FC<MenuProps> = ({
  id,
  selectedId,
  defaultLabel,
  options,
  isLazy = true,
  menuButtonProps = {},
  handleSelect,
  sx,
  placement,
  onClose,
  matchWidth,
  searchable,
  searchValue,
  onSearchChange,
  clearSelected,
  gutter,
  isLoading,
  closeOnSelect = true,
  onOpen,
  increaseDataSet,
  shouldLoadMoreItems,
}) => {
  const [openInnerMenuRef, setOpenInnerMenuRef] = React.useState<
  React.RefObject<HTMLButtonElement> | null
  >(null);
  const menuListRef = useRef<HTMLDivElement>(null);
  const [menuHeight, setMenuHeight] = useState<number | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const lastPostElementRef = useCallback(
    (node: Element | null) => {
      if (!shouldLoadMoreItems || !increaseDataSet) {
        return;
      }
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && increaseDataSet) {
          increaseDataSet();
        }
      });

      if (node) observer.current?.observe(node);
    },
    [shouldLoadMoreItems, increaseDataSet],
  );

  useEffect(() => () => {
    if (observer?.current) {
      observer.current?.disconnect();
    }
  }, []);

  useEffect(() => {
    if (contentRef.current && !isLoading) {
      setMenuHeight(contentRef.current.clientHeight);
    }
  }, [options, isLoading]);

  const defaultMenuButtonProps = {
    size: 'sm',
    variant: 'outline',
    textAlign: 'left' as MenuButtonProps['textAlign'],
    as: Button,
  };

  const mergedMenuButtonProps = { ...defaultMenuButtonProps, ...menuButtonProps };

  const selectedItem = options?.length ? options.find((option) => option.id === selectedId) : null;

  const hasGroup = options?.some((option) => option.group);

  let groupedOptions: { [key: string]: typeof options } = {};
  if (hasGroup) {
    groupedOptions = options.reduce((acc, option) => {
      if (!option.group) {
        if (!acc.ungrouped) {
          acc.ungrouped = [];
        }
        acc.ungrouped.push(option);
      } else if (option.group) {
        if (!acc[option.group]) {
          acc[option.group] = [];
        }
        acc[option.group].push(option);
      }
      return acc;
    }, {} as { [key: string]: typeof options });
  }

  const renderMenuItem = (option: MenuProps['options'][0]) => {
    let leftIconElement;
    if (option.leftIconElement || option.leftIcon) {
      leftIconElement = option.leftIconElement
        ? option.leftIconElement
        : <Icon as={option.leftIcon} />;
    }

    const isSelected = option.rightIcon !== undefined || option.id === selectedId;

    let rightIcon = <Icon data-testid="menu-item-right-icon" ml={1} float="right" as={option.rightIcon} />;
    if (option.rightIconTooltip) {
      rightIcon = (
        <Tooltip hasArrow label={option.rightIconTooltip}>
          {rightIcon}
        </Tooltip>
      );
    }

    return (
      <MenuItem
        key={option.id}
        data-testid={`menu-item-${option.id}`}
        onClick={handleSelect ? () => handleSelect(option.id) : option.onClick}
        isDisabled={option.isDisabled}
        justifyContent="space-between"
        icon={leftIconElement}
        closeOnSelect={option.closeOnSelect}
        data-multiselected={isSelected}
      >
        {option.label}
        {option.rightIcon && rightIcon}
      </MenuItem>
    );
  };

  const renderInnerMenu = (option: MenuProps['options'][0]) => (
    <InnerMenu
      id={option.id}
      key={option.id}
      placement={placement}
      label={option.label}
      leftIcon={option.leftIcon}
      childrenItems={option.childrenItems || []}
      searchComponent={option.searchComponent}
      openInnerMenuRef={openInnerMenuRef}
      setOpenInnerMenuRef={setOpenInnerMenuRef}
      badgeCount={option.badgeCount}
    />
  );

  return (
    <ChakraMenu
      isLazy={isLazy}
      data-testid={`${id}-menu`}
      onClose={onClose}
      matchWidth={matchWidth}
      placement={placement}
      gutter={gutter}
      closeOnSelect={closeOnSelect}
      onOpen={onOpen}
    >
      {function MenuContent({ isOpen }) {
        const renderContent = () => {
          if (hasGroup) {
            return (
              <>
                {groupedOptions.ungrouped?.map(renderMenuItem)}
                {Object.keys(groupedOptions)
                  .filter((group) => group !== 'ungrouped')
                  .map((group) => (
                    <>
                      <MenuGroup key={group} title={group}>
                        {groupedOptions[group].map(renderMenuItem)}
                      </MenuGroup>
                      <MenuDivider />
                    </>
                  ))}
                <div ref={lastPostElementRef} />
              </>
            );
          }

          return (
            <>
              {options?.map(
                (option) => (option.childrenItems
                  ? renderInnerMenu(option)
                  : renderMenuItem(option)),
              )}
              <div ref={lastPostElementRef} />
            </>
          );
        };

        return (
          <>
            <MenuButton
              data-testid={`${id}-menu-button`}
              size={mergedMenuButtonProps.size}
              variant={mergedMenuButtonProps.variant}
              textAlign={mergedMenuButtonProps.textAlign}
              as={mergedMenuButtonProps.as}
              w={mergedMenuButtonProps.w}
              aria-labelledby={mergedMenuButtonProps['aria-labelledby']}
              icon={mergedMenuButtonProps.icon && <Icon as={mergedMenuButtonProps.icon} />}
              rightIcon={
                !mergedMenuButtonProps.icon
                  ? <Icon as={isOpen ? CaretUpIcon : CaretDownIcon} />
                  : undefined
              }
              sx={mergedMenuButtonProps.sx}
            >
              <Box isTruncated>{selectedItem ? selectedItem.label : defaultLabel}</Box>
            </MenuButton>
            <MenuList
              ref={menuListRef}
              data-testid={`${id}-menu-list`}
              minW={matchWidth ? '100%' : 'unset'}
              sx={{
                overflowY: searchable ? 'unset' : 'auto',
                position: 'relative',
                ...sx,
              }}
              zIndex={5}
            >
              {searchable && (
                <Box
                  top={0}
                  bg="white"
                  zIndex={1}
                  borderBottom="1px solid"
                  borderColor="gray.200"
                  pb={1}
                  px={2}
                >
                  <Flex justify="space-between">
                    <Box w="75%" sx={{ div: { width: 'unset' } }}>
                      <SearchBar
                        id={`${id}-search`}
                        placeholder="Search"
                        value={searchValue}
                        onValueChange={onSearchChange}
                        onClear={() => onSearchChange?.('')}
                      />
                    </Box>
                    {clearSelected && (
                      <Button
                        aria-label="Clear Selected"
                        onClick={clearSelected}
                        cursor="pointer"
                        color="gray.500"
                        fontSize="sm"
                        variant="link"
                      >
                        Clear Selected
                      </Button>
                    )}
                  </Flex>
                </Box>
              )}
              {isLoading ? (
                <Flex
                  justify="center"
                  align="center"
                  minH={menuHeight ? `${menuHeight}px` : '250px'}
                  py={4}
                >
                  <Spinner size="sm" color="gray.400" />
                </Flex>
              ) : (
                <Box maxH="300px" overflowY="auto">
                  {renderContent()}
                </Box>
              )}
            </MenuList>
          </>
        );
      }}
    </ChakraMenu>
  );
};

export default Menu;
