import {
  Badge,
  Card,
  MessageDirectionIndicator,
  TagsList,
  DropdownMenu,
  Tooltip,
} from '@himarley/unity';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames/bind';
import React, {
  useRef, useEffect, useState, useMemo,
  useCallback,
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router';

import { markChatUnread, setActiveJob } from '@app/actions/job';
import { updatePinnedChats } from '@app/actions/profile';
import { handleCaseCacheItem } from '@app/cache/caseCache';
import { REDACTED_FROM_VIEWER } from '@app/constants/jobState';
import { isElementVisibleInContainer } from '@app/helpers/common';
import {
  daysDifferenceFromDate,
  formatDate,
} from '@app/helpers/datetime';
import {
  capitalizeEachWord,
  truncateText,
  middleTruncateText,
} from '@app/helpers/format';
import generateTagArray from '@app/helpers/Tags/generateTagArray';
import { StateType } from '@app/types/reducer-state';
import ErrorIcon from '@images/icons/error.svg';
import LockClosedIcon from '@images/icons/lock-closed.svg';
import VerticalEllipsesMenuIcon from '@images/icons/verticalEllipsesMenu.svg';

import { menuItems, menuItemsUnpinned } from './constants';
import styles from './InboxContent.module.less';
import type { PageType } from './types';
import { CARD_DENSITY } from '../constants';

interface ChatCardProps {
  index: number;
  style: React.CSSProperties;
  data: {
    pages: PageType[];
    hasPinnedChats: boolean;
    pinnedChatsCount: string | number;
    unpinnedLabelIndex?: string | number;
    toggleShowPinnedChats: () => void;
    containerRef?: React.RefObject<HTMLDivElement> | null;
  };
  setSize: (index: number, size: number) => void;
  windowWidth?: number | string;
}

interface ChatCardMenuItem {
  label: string;
}

const cx = classNames.bind(styles);

const ChatCard: React.FC<ChatCardProps> = ({
  index,
  style,
  data,
  setSize,
  windowWidth,
}) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const {
    authId,
    activeJobId,
    viewedChats,
    newInboxCardDensity,
  } = useSelector(
    (state: StateType) => ({
      authId: state.auth?.user?._id,
      activeJobId: state.jobs?.activeJob.id || state.jobs?.activeJob?.raw?._id,
      viewedChats: state.jobs?.viewedChats,
      newInboxCardDensity:
        state?.profile?.properties?.newInboxCardDensity
        || CARD_DENSITY.EXPANDED,
    }),
    shallowEqual,
  );

  const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;

  const rowRef = useRef<HTMLElement>(null);
  const { pages, containerRef } = data || {};

  const chat = pages[index];

  const viewedChatsUnreadMessageCount = viewedChats && !viewedChats.has(chat?._id || '') && 1 ? 1 : 0;

  const [unreadMessageCount, setUnreadMessageCount] = useState(
    isUnreadStateRefactorEnabled ? (chat?.unreadMessageCount || 0) : viewedChatsUnreadMessageCount,
  );
  const [isCardVisible, setIsCardVisible] = useState(true);

  const store = useSelector((state) => state);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (rowRef.current?.firstChild) {
      const firstChild = rowRef.current.firstChild as HTMLElement;
      const { height } = firstChild.getBoundingClientRect();
      setSize(index, height + 1);
    }
  }, [setSize, index, windowWidth, newInboxCardDensity, pages]);

  const isLastMessageAuthorLoggedInUser = useMemo(
    () => !viewedChats?.has(chat._id || '')
      && authId
      && chat?.lastMessage?.authorId
      && authId === chat?.lastMessage?.authorId,
    [viewedChats, authId, chat?._id, chat?.lastMessage?.authorId],
  );

  useEffect(() => {
    if (rowRef?.current && containerRef?.current) {
      setIsCardVisible(
        isElementVisibleInContainer(
          rowRef?.current,
          containerRef?.current,
        ),
      );
    }
  }, [containerRef, rowRef]);

  useEffect(() => {
    if (isUnreadStateRefactorEnabled) {
      setUnreadMessageCount(chat?.unreadMessageCount || 0);
    } else {
      setUnreadMessageCount(
        !isLastMessageAuthorLoggedInUser && viewedChats && !viewedChats.has(chat?._id || '') && 1 ? 1 : 0,
      );
    }
  }, [
    viewedChats,
    chat?._id,
    isLastMessageAuthorLoggedInUser,
    isUnreadStateRefactorEnabled,
    chat?.unreadMessageCount,
  ]);

  const handlePinChat = useCallback(() => {
    const pinnedState = !chat?.isPinned;
    dispatch(updatePinnedChats(authId, chat?._id, pinnedState));
    handleCaseCacheItem({ ...chat, isPinned: pinnedState }, 'toggle-pin', queryClient, store);
  }, [chat, dispatch, authId, queryClient, store]);

  const handleMarkUnread = useCallback(() => {
    dispatch(markChatUnread(queryClient, chat?._id));
  }, [dispatch, queryClient, chat?._id]);

  const handleSelectChatCardDropdownItem = useCallback((item: ChatCardMenuItem) => {
    if (item.label === 'Mark Unread') {
      handleMarkUnread();
    } else if (item.label === 'Pin Chat' || item.label === 'Unpin Chat') {
      handlePinChat();
    }
  }, [handleMarkUnread, handlePinChat]);

  const handleSelectChat = () => {
    if (chat?.state === REDACTED_FROM_VIEWER) {
      return;
    }
    if (chat._id !== activeJobId) {
      dispatch(setActiveJob(chat._id));
      history(`/chats/${chat._id}`);
    }
  };

  const isActiveChat = useMemo(
    () => chat?._id === activeJobId,
    [activeJobId, chat?._id],
  );

  const allTags = useMemo(
    () => chat?.tags?.filter((tag) => typeof tag === 'string' && tag?.trim()) || [],
    [chat?.tags],
  );

  const isCaseCorrupt = useMemo(() => {
    if (!chat?.customerName?.trim() || !chat?.lastMessage?.sentAt) {
      return true;
    }
    return false;
  }, [chat?.customerName, chat?.lastMessage?.sentAt]);

  const messageIndicator = useMemo(() => {
    if (chat?.state === REDACTED_FROM_VIEWER) {
      return <LockClosedIcon />;
    }
    if (unreadMessageCount > 0) {
      return (
        <Badge
          className={styles.unreadMessagesCount}
          testId={`badge-unread-${chat?._id}`}
        />
      );
    }
    if (isCaseCorrupt) {
      return <ErrorIcon data-testId="errorIcon" />;
    }
    return (
      <MessageDirectionIndicator
        isActive={isActiveChat}
        isInbound={chat?.lastMessage?.isInbound}
      />
    );
  }, [
    chat?._id,
    chat?.lastMessage?.isInbound,
    chat?.state,
    isActiveChat,
    isCaseCorrupt,
    unreadMessageCount,
  ]);

  const renderTagsRow = useMemo(
    () => (
      <div
        className={cx(styles.tagsRow, {
          animateFadeIn: newInboxCardDensity === CARD_DENSITY.EXPANDED,
          animateFadeOut: newInboxCardDensity === CARD_DENSITY.EXPANDED,
        })}
        style={{
          ...(isCardVisible
            ? { ['viewTransitionName' as string]: `tags-row-${chat?.id || chat?._id || index}` }
            : {}),
        }}
      >
        <div className={styles.tagItemsContainer}>
          <TagsList
            minimalDisplay={newInboxCardDensity !== CARD_DENSITY.EXPANDED}
            items={
              allTags?.length === 0
                ? generateTagArray(['error'])
                : generateTagArray(allTags)
            }
            testId="chat-tag-list"
          />
        </div>
      </div>
    ),
    [allTags, chat?._id, chat?.id, index, isCardVisible, newInboxCardDensity],
  );

  const chatSectionClasses = cx({
    chatScrollableContainer: true,
    unreadLeftBar:
      unreadMessageCount > 0
      && chat?.state !== REDACTED_FROM_VIEWER,
  });

  return (
    <section
      key={chat?.id || chat?._id || index}
      className={chatSectionClasses}
      data-testid={`chat-card-${chat?.id || chat?._id}`}
      style={style}
      ref={rowRef}
    >
      <Card
        type="shallow"
        className={
          chat?.state === REDACTED_FROM_VIEWER
            ? `${styles.inboxCard} ${styles.hiddenCard}`
            : `${
              unreadMessageCount > 0
                ? styles.unreadCard
                : styles.whiteLeftBorder
            } ${isActiveChat ? styles.activeChatCard : styles.inboxCard} ${
              isCaseCorrupt ? styles.corruptCard : ''
            }`
        }
        onClick={handleSelectChat}
      >
        <div className={styles.chatCardContainer}>
          <div className={styles.messageIndicationContainer}>
            {messageIndicator}
          </div>
          <div className={styles.chatDetailsContainer}>
            <div className={styles.column}>
              <h1
                data-testid={`customerName-${index}`}
                className={`${styles.customerName} ${
                  !chat?.customerName?.trim() ? styles.missingInfo : ''
                }`}
              >
                {!chat?.customerName?.trim()
                  ? 'Name Missing'
                  : truncateText(chat?.customerName, 20)}
              </h1>
              <p
                className={`${styles.greyText} ${styles.alignRight} ${
                  !chat?.lastMessage?.sentAt ? styles.missingInfo : ''
                }`}
              >
                {!chat?.lastMessage?.sentAt
                  ? 'Date Missing'
                  : capitalizeEachWord(
                    formatDate(chat?.lastMessage?.sentAt, 'human'),
                  )}
              </p>
            </div>

            <p
              className={cx(styles.lastMessage, styles.teaser, {
                animateFadeIn: newInboxCardDensity !== CARD_DENSITY.COMPACT,
                animateFadeOut: newInboxCardDensity === CARD_DENSITY.COMPACT,
              })}
              style={{
                ...(isCardVisible
                  ? { ['viewTransitionName' as string]: `last-message-${chat?.id || chat?._id || index}` }
                  : {}),
              }}
            >
              {truncateText(chat?.lastMessage?.body, 41)}
            </p>
            <div
              className={cx({
                expandedRow: newInboxCardDensity === CARD_DENSITY.EXPANDED,
                compactRow: newInboxCardDensity !== CARD_DENSITY.EXPANDED,
              })}
            >
              <div
                className={cx(styles.column, styles.caseNumberRow, {
                  extraPaddingRow:
                    newInboxCardDensity === CARD_DENSITY.EXPANDED,
                })}
              >
                <p
                  className={`${styles.greyText} ${styles.caseNumber}`}
                  style={{
                    ...(isCardVisible
                      ? { ['viewTransitionName' as string]: `case-number-${chat?.id || chat?._id || index}` }
                      : {}),
                  }}
                >
                  <Tooltip
                    side="right"
                    trigger={`Case: ${middleTruncateText(
                      chat?.caseNumber,
                      15,
                    )}`}
                    tooltip={chat?.caseNumber}
                    tooltipContentClassName={styles.caseNumberTooltip}
                    className={
                      chat?.state === REDACTED_FROM_VIEWER
                        ? styles.hiddenCaseTooltip
                        : ''
                    }
                  />
                </p>
                <p
                  className={cx(styles.greyText, styles.alignRight, {
                    animateFadeIn:
                      newInboxCardDensity === CARD_DENSITY.EXPANDED,
                    animateFadeOut:
                      newInboxCardDensity !== CARD_DENSITY.EXPANDED,
                  })}
                  data-testid="daysOpen"
                >
                  Open:
                  {' '}
                  {daysDifferenceFromDate(chat?.createdAt)}
                </p>
              </div>
              <div className={styles.tagsActionRow}>
                {renderTagsRow}
                {chat?.state === REDACTED_FROM_VIEWER ? null : (
                  <div
                    data-testid={`chat-card-dropdown-${chat?.id || chat?._id}`}
                    className={styles.ellipsesContainer}
                    onClick={(e) => e.stopPropagation()}
                    aria-hidden="true"
                  >
                    <DropdownMenu
                      isCheckMark={false}
                      className={styles.DropdownMenuSubContent}
                      menuIcon={<VerticalEllipsesMenuIcon />}
                      displaySubIcon
                      displayLabel={false}
                      menuItems={chat?.isPinned ? menuItemsUnpinned : menuItems}
                      onSelectItem={handleSelectChatCardDropdownItem}
                      testId={`ellipses-chat-btn-${chat?.id || chat?._id}`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </section>
  );
};

export default ChatCard;
