import { CaseTypeEnum } from '@app/types/create-case';

export const getCaseTypeFromPath = (
  pathname: string,
  isClaimTabEnabled: boolean,
  isPolicyTabEnabled: boolean,
): {
  type: CaseTypeEnum;
  tabIndex: number;
} => {
  if (pathname.includes('policies')) {
    return { type: CaseTypeEnum.policy, tabIndex: 1 };
  }
  if (pathname.includes('claims')) {
    return { type: CaseTypeEnum.claim, tabIndex: 0 };
  }
  if (pathname.includes('cases')) {
    if (isClaimTabEnabled) {
      return { type: CaseTypeEnum.claim, tabIndex: 0 };
    }
    if (isPolicyTabEnabled) {
      return { type: CaseTypeEnum.policy, tabIndex: 1 };
    }
    return { type: CaseTypeEnum.general, tabIndex: 2 };
  }
  return { type: CaseTypeEnum.claim, tabIndex: 0 };
};

export default getCaseTypeFromPath;
