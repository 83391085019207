import {
  Button, ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  Tabs,
  TabList,
  TabPanel, Tab,
  TabPanels,
  Heading,
  chakra,
  Flex,
  Spacer,
  Tooltip,
  Tag,
  TagLabel,
  TagRightIcon,
} from '@chakra-ui/react';
import { InfoCircleIcon } from '@himarley/unity';
import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  useForm, FormProvider,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import { useCustomStyles } from '@app/chakra-theme/hooks/use-custom-styles';
import { DISABLE_MARLEY_OPT_IN, CREATE_CLAIMS_FROM_CASES_TAB, CREATE_POLICY_FROM_CASES_TAB } from '@app/constants/permissions';
import { useCheckPermissions } from '@app/helpers/common';
import { cleanPhone } from '@app/helpers/format';
import {
  CaseVisibilityEnum,
  CaseTypeEnum,
  ContactCaseRoleEnum,
  IFormInput,
  Contact,
} from '@app/types/create-case';
import { StateType } from '@app/types/reducer-state';

import { getCaseTypeFromPath } from './case-type-utils';
import ClaimForm from './claim-form';
import { TAB_FIELDS, META_DATA_MAPPING } from './constants';
import ContactDetailsForm from './contact-details-form';
import GeneralForm from './general-form';
import { useCaseForm } from './hooks/use-case-form';
import PolicyForm from './policy-form';

const StyledTab = chakra(Tab, {
  baseStyle: {
    bg: 'gray.50',
    borderColor: 'gray.200',
    borderWidth: '1px',
    _selected: {
      bg: 'white',
      color: 'black',
      borderColor: 'navy.500',
    },
  },
});

const CreateCaseModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [isPhoneNumberEntered, setIsPhoneNumberEntered] = useState(false);

  const location = useLocation();
  const history = useNavigate();

  const isEditMode = location.pathname.includes('/edit/');
  const caseId = isEditMode ? location.pathname.split('/').pop() : undefined;
  const isPolicy = location.pathname.includes('policies');
  const isClaim = location.pathname.includes('claims');
  const isCase = location.pathname.includes('cases');
  const getEditTitle = () => {
    switch (true) {
      case isClaim:
        return 'Edit Claim';
      case isPolicy:
        return 'Edit Policy';
      default:
        return 'Edit Case';
    }
  };

  const isCreateClaimsFromCasesTabEnabled = useCheckPermissions([
    CREATE_CLAIMS_FROM_CASES_TAB,
  ]);
  const isCreatePoliciesFromCasesTabEnabled = useCheckPermissions([
    CREATE_POLICY_FROM_CASES_TAB,
  ]);
  const isClaimTabEnabled = !isCase || isCreateClaimsFromCasesTabEnabled;
  const isPolicyTabEnabled = !isCase || isCreatePoliciesFromCasesTabEnabled;

  const { tabIndex: initialTab, type: initialCaseType } = getCaseTypeFromPath(
    location.pathname,
    isClaimTabEnabled,
    isPolicyTabEnabled,
  );
  const [activeTab, setActiveTab] = useState(initialTab);

  const caseData = useSelector((state: StateType) => {
    if (!caseId) return null;
    return state.jobs?.list?.find((item) => item.id === caseId);
  });

  const userBrand = useSelector((state: StateType) => state.profile.properties.brandId);
  const brands = useSelector((state: StateType) => state.organizations?.branding ?? []);
  const currentUserId = useSelector((state: StateType) => state.auth?.user?._id);

  const isDisableMarleyOptIn = useCheckPermissions([DISABLE_MARLEY_OPT_IN]);
  const { scrollbarStyles } = useCustomStyles();

  const methods = useForm<IFormInput>({
    defaultValues: {
      contactLanguage: 'en',
      caseType: initialCaseType,
      caseVisibility: CaseVisibilityEnum.public,
      insuranceBrand: brands.find((brand) => brand === userBrand),
      assignedTo: currentUserId,
      contactCaseRole: 'insured' as keyof typeof ContactCaseRoleEnum,
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    if (isEditMode && caseData && caseData.customer) {
      setIsPhoneNumberEntered(true);
      const {
        customer,
      } = caseData;

      setSelectedContact({
        id: customer.id || customer._id || '',
        profile: {
          firstName: customer.firstName || '',
          lastName: customer.lastName || '',
          email: customer.email || '',
          phoneNumber: customer.phoneNumber || '',
        },
        optIn: {
          status: customer.optIn?.status || '',
        },
      });

      const formData: IFormInput = {
        id: customer.id || customer._id || '',
        contactCaseRole: caseData.roleType as keyof typeof ContactCaseRoleEnum,
        caseType: caseData.type as CaseTypeEnum,
        insuranceBrand: caseData.branding || '',
        lineOfBusiness: caseData.lineOfBusiness?._id || '',
        assignedTo: caseData.assignedOperator?.id || '',
        caseVisibility: caseData.privacy as CaseVisibilityEnum,
      };

      if (isClaim) {
        formData.claimNumber = caseData?.referenceId;
        formData.claimStatus = caseData?.metaData?.[META_DATA_MAPPING.CLAIM.claimStatus];
        formData.policyNumber = caseData?.metaData?.[META_DATA_MAPPING.CLAIM.policyNumber];
        formData.dateOfLoss = caseData?.metaData?.[META_DATA_MAPPING.CLAIM.dateOfLoss];
        formData.deductible = caseData?.metaData?.[META_DATA_MAPPING.CLAIM.deductible];
      }

      if (isPolicy) {
        formData.policyNumber = caseData?.referenceId;
        formData.deductible = caseData?.metaData?.[META_DATA_MAPPING.POLICY.deductible];
        formData.policyType = caseData?.metaData?.[META_DATA_MAPPING.POLICY.policyType];
        formData.effectiveDate = caseData?.metaData?.[META_DATA_MAPPING.POLICY.effectiveDate];
        formData.expirationDate = caseData?.metaData?.[META_DATA_MAPPING.POLICY.expirationDate];
        formData.streetAddress1 = caseData?.metaData?.[META_DATA_MAPPING.POLICY.streetAddress1];
        formData.streetAddress2 = caseData?.metaData?.[META_DATA_MAPPING.POLICY.streetAddress2];
        formData.city = caseData?.metaData?.[META_DATA_MAPPING.POLICY.city];
        formData.state = caseData?.metaData?.[META_DATA_MAPPING.POLICY.state];
        formData.zipCode = caseData?.metaData?.[META_DATA_MAPPING.POLICY.zipCode];
        formData.yearBuilt = caseData?.metaData?.[META_DATA_MAPPING.POLICY.yearBuilt];
      }

      if (isCase) {
        formData.reference = caseData?.referenceId;
      }

      methods.reset(formData);
    }
  }, [isEditMode,
    caseData,
    methods,
    currentUserId,
    isPhoneNumberEntered,
    isClaim,
    isPolicy,
    isCase,
  ]);

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting, isValid },
    unregister,
    reset,
  } = methods;

  const handleClose = useCallback(() => {
    setIsOpen(false);
    const pathSegments = location.pathname.split('/').filter(Boolean);
    pathSegments.pop();

    if (isEditMode) {
      pathSegments.pop();
    }

    const basePath = `/${pathSegments.join('/')}`;

    history(basePath);
    reset();
  }, [location.pathname, history, reset, isEditMode]);

  const { onSubmit } = useCaseForm(handleClose);

  useEffect(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleFormSubmit = handleSubmit((data) => {
    onSubmit(data, isEditMode, caseId, caseData);
  });

  const handleTabsChange = useCallback((index: number) => {
    const caseTabArray = [
      CaseTypeEnum.claim,
      CaseTypeEnum.policy,
      CaseTypeEnum.general,
    ];

    setValue('caseType', caseTabArray[index]);

    // Policy number is not required for claim and general tabs
    if (index === 0 || index === 2) {
      unregister('policyNumber');
    }

    const allFields = Object.values(TAB_FIELDS).flat();
    allFields.forEach((field) => {
      if (!TAB_FIELDS[caseTabArray[index]].includes(field)) {
        unregister(field);
      }
    });

    setActiveTab(index);
  }, [setValue, unregister]);

  const isValidPhoneNumber = cleanPhone(getValues('phoneNumber')).length === 10;

  const submitButtonText = isEditMode ? 'Save Changes' : 'Create Case';

  const modalTitle = isEditMode ? getEditTitle() : 'Create Case';

  const preventAndStopEvent = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={{ base: 'full', md: 'xl' }}
      scrollBehavior="inside"
    >
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent
          as="form"
          onSubmit={handleFormSubmit}
          onWheel={preventAndStopEvent}
          onTouchMove={preventAndStopEvent}
          onScroll={preventAndStopEvent}
        >
          <ModalHeader borderBottom="1px solid" borderColor="gray.200">
            {modalTitle}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody sx={{ ...scrollbarStyles, overflowX: 'hidden' }}>
            <Grid width="100%" templateColumns={{ base: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' }} gap={4}>
              <GridItem colSpan={2}>
                <Heading fontWeight="medium" size="sm">Contact Details</Heading>
              </GridItem>
              <ContactDetailsForm
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
                setIsPhoneNumberEntered={setIsPhoneNumberEntered}
                isEditMode={isEditMode}
              />
              {isPhoneNumberEntered && (
              <>
                <GridItem colSpan={2}>
                  <Heading fontWeight="medium" size="sm">Case Details</Heading>
                </GridItem>
                <GridItem colSpan={2}>
                  <Tabs variant="unstyled" index={activeTab as number} isFitted onChange={handleTabsChange}>
                    <TabList
                      as={ButtonGroup}
                      isAttached
                      border={0}
                      variant="unstyled"
                      sx={{
                        flexDirection: { base: 'column', md: 'row' },
                        '> button': {
                          borderRadius: '4px',
                          width: { base: '100%', md: 'auto' },
                        },
                      }}
                    >
                      <StyledTab isDisabled={isEditMode || !isClaimTabEnabled}>Claim</StyledTab>
                      <StyledTab isDisabled={isEditMode || !isPolicyTabEnabled}>Policy</StyledTab>
                      <StyledTab isDisabled={isEditMode}>General</StyledTab>
                    </TabList>
                    <TabPanels>
                      <TabPanel px={0}>
                        {activeTab === 0 ? <ClaimForm /> : null}
                      </TabPanel>
                      <TabPanel px={0}>
                        {activeTab === 1 ? <PolicyForm /> : null}
                      </TabPanel>
                      <TabPanel px={0}>
                        {activeTab === 2 ? <GeneralForm /> : null}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </GridItem>
              </>
              )}
            </Grid>
          </ModalBody>
          <ModalFooter borderTop="1px solid" borderColor="gray.200">
            <Flex justifyContent="space-between" width="100%">
              {isDisableMarleyOptIn && isPhoneNumberEntered && (
                <Tag
                  bg="gray.50"
                  py={2}
                  borderRadius="md"
                  px={3}
                >
                  <TagLabel>Did you confirm opt-in?</TagLabel>
                  <Tooltip
                    hasArrow
                    label="By creating this case you confirm that this customer has already consented to receive messages from your organization."
                    placement="top"
                  >
                    <TagRightIcon ml={1} boxSize={4} as={InfoCircleIcon} />
                  </Tooltip>
                </Tag>
              )}
              <Spacer />
              <ButtonGroup spacing={3}>
                <Button
                  variant="ghost"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  data-testid="create-case-submit-button"
                  isLoading={isSubmitting}
                  isDisabled={!isValid || (!isValidPhoneNumber && !isEditMode)}
                >
                  {submitButtonText}
                </Button>
              </ButtonGroup>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};

export default CreateCaseModal;
