/* eslint-disable no-restricted-syntax */
const FILE_SIZE_LIMIT = 52428800; // 50MB
const FILE_TYPE = 'text/csv';
const MAX_USER_SIZE = 10000;

export const COLUMNS_INDEX = {
  'First Name': null,
  'Last Name': null,
  Email: null,
  'Phone Number': null,
  'Office Line': null,
};

const isStringWithValue = (value: unknown) => value != null && (typeof value !== 'string' || value.trim() !== '');
const isEmailValid = (email: string) => email != null && /\S+@\S+\.\S+/.test(email);
export const isPhoneNumberValid = (number: string) => /^\+?1?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})(?:\s*(?:[xX]|[eE]xt\.?|[eE]xtension)?\s*[.#]?\s*(\d+))?$/.test(number);

interface FileValidationResult {
  errors: string[];
}

interface ParsedDataResult {
  data: string[][];
  meta: {
    delimiter: string;
    linebreak: string;
    aborted: boolean;
    fields?: string[];
    truncated: boolean;
    cursor: number;
  };
}

export const isUniqueGroups = (groups: string, groupLeads: string) => {
  if (!groups || !groupLeads) {
    return true;
  }
  const groupSet = new Set(groups.split(';').map((group) => group.trim()));
  const groupLeadSet = new Set(groupLeads.split(';').map((group) => group.trim()));
  for (const group of groupSet) {
    if (groupLeadSet.has(group)) {
      return false;
    }
  }
  return true;
};

export const validateCSV = (parsedData: ParsedDataResult) => {
  if (!(parsedData?.data?.length > 0)) {
    return {
      errors: ['The CSV file is empty. Please fill in the data and try again.'],
    };
  }
  const result: FileValidationResult = {
    errors: [],
  };

  const UNIQUE_ID = 'Unique Id';
  const FIRST_NAME = 'First Name';
  const LAST_NAME = 'Last Name';
  const EMAIL = 'Email';
  const TITLE = 'Title';
  const PHONE_NUMBER = 'Phone Number';
  const OFFICE_LINE = 'Office Line';
  const ROLES = 'Roles';
  const GROUP_MEMBERS = 'Group Members';
  const GROUP_LEADS = 'Group Leads';
  const LAST_LOGIN = 'Last Login';
  const ACTIVE_COLUMN = 'Active';

  const requiredColumns = [UNIQUE_ID, EMAIL];
  const validColumns = [
    UNIQUE_ID, FIRST_NAME, LAST_NAME, EMAIL,
    TITLE, PHONE_NUMBER, OFFICE_LINE, ROLES,
    GROUP_MEMBERS, GROUP_LEADS, LAST_LOGIN,
    ACTIVE_COLUMN,
  ];

  const headers = parsedData.data[0] as string[];
  const rows = parsedData.data.slice(1) as string[][];

  // Step 1. Check for general errors

  // check for missing required columns
  const hasRequiredColumn = headers.includes(UNIQUE_ID) || headers.includes(EMAIL);
  if (!hasRequiredColumn) {
    result.errors.push(`Missing required columns: '${UNIQUE_ID}' or '${EMAIL}'`);
  }

  // check if all columns are valid
  const invalidColumns = headers.filter((col) => !validColumns.includes(col));
  if (invalidColumns.length > 0) {
    const formattedInvalidColumns = invalidColumns.map((column) => `'${column}'`).join(', ');
    result.errors.push(`The CSV file contains invalid columns: ${formattedInvalidColumns}`);
  }
  const invalidColumnsSet = new Set(invalidColumns);

  const providedColumns = headers.filter((col) => col && !invalidColumnsSet.has(col));

  // check if there is at least one column other than Unique Id or Email to update
  const updateableColumns = providedColumns.filter((col) => !requiredColumns.includes(col));
  if (updateableColumns.length === 0) {
    result.errors.push(`The CSV file must have at least one column to update besides '${UNIQUE_ID}' or '${EMAIL}'`);
  }

  // check for empty columns
  providedColumns.forEach((column) => {
    const columnIndex = headers.indexOf(column);
    const columnData = rows.map((row) => row[columnIndex]);
    const hasValue = columnData.some((value) => value && value.toString().trim() !== '');
    if (!hasValue && column !== LAST_LOGIN && column !== ACTIVE_COLUMN) {
      result.errors.push(`Delete or fill empty column '${column}'`);
    }
  });

  // Step 2. check for row specific errors

  const uniqueIdIndex = headers.indexOf(UNIQUE_ID);
  const emailIndex = headers.indexOf(EMAIL);
  const firstNameIndex = headers.indexOf(FIRST_NAME);
  const lastNameIndex = headers.indexOf(LAST_NAME);
  const phoneNumberIndex = headers.indexOf(PHONE_NUMBER);
  const officeLineIndex = headers.indexOf(OFFICE_LINE);
  const rolesIndex = headers.indexOf(ROLES);
  const groupsIndex = headers.indexOf(GROUP_MEMBERS);
  const groupLeadsIndex = headers.indexOf(GROUP_LEADS);

  const hasUniqueId = uniqueIdIndex > -1;
  const hasEmail = emailIndex > -1;
  const hasFirstName = firstNameIndex > -1;
  const hasLastName = lastNameIndex > -1;
  const hasPhoneNumber = phoneNumberIndex > -1;
  const hasOfficeLine = officeLineIndex > -1;
  const hasRoles = rolesIndex > -1;
  const hasGroups = groupsIndex > -1;
  const hasGroupLeads = groupLeadsIndex > -1;

  // validate each row
  rows.forEach((row: string[], index: number) => {
    const rowNumber = index + 2; // Account for header row

    let uniqueIdProvided = false;
    let emailAddressProvided = false;
    let firstNameProvided = false;
    let lastNameProvided = false;

    // validate Unique Id
    if (hasUniqueId && isStringWithValue(row[uniqueIdIndex])) {
      uniqueIdProvided = true;
    }

    // validate Email
    if (hasEmail && !uniqueIdProvided && !isEmailValid(row[emailIndex])) {
      result.errors.push(`Row ${rowNumber}: Missing or invalid '${EMAIL}'`);
    } else {
      emailAddressProvided = true;
    }

    if (!uniqueIdProvided && !emailAddressProvided) {
      result.errors.push(`Row ${rowNumber}: One of '${UNIQUE_ID}' or '${EMAIL}' should be provided`);
    }

    // validate First Name, Last Name, Groups and Group Leads
    if (hasFirstName && !isStringWithValue(row[firstNameIndex])) {
      result.errors.push(`Row ${rowNumber}: Invalid value for required '${FIRST_NAME}'`);
    } else {
      firstNameProvided = true;
    }
    if (hasLastName && !isStringWithValue(row[lastNameIndex])) {
      result.errors.push(`Row ${rowNumber}: Invalid value for required '${LAST_NAME}'`);
    } else {
      lastNameProvided = true;
    }

    if (!uniqueIdProvided && emailAddressProvided
      && !(hasFirstName && firstNameProvided && hasLastName && lastNameProvided)) {
      result.errors.push(`Row ${rowNumber}: '${FIRST_NAME}' and '${LAST_NAME}' are required if '${EMAIL}' is provided and no '${UNIQUE_ID}' to update`);
    }

    if (hasGroups && row[groupsIndex] && typeof row[groupsIndex] !== 'string') {
      result.errors.push(`Row ${rowNumber}: Group names under '${GROUP_MEMBERS}' must be semicolon (;) separated`);
    }
    if (hasGroupLeads && row[groupLeadsIndex] && typeof row[groupLeadsIndex] !== 'string') {
      result.errors.push(`Row ${rowNumber}: Group names under '${GROUP_LEADS}' must be semicolon (;) separated`);
    }

    // check to make sure user cannot be group lead and member in same group
    if (!isUniqueGroups(row[groupsIndex], row[groupLeadsIndex])) {
      result.errors.push(`Row ${rowNumber}: Cannot be member and lead of the same group`);
    }

    // validate roles
    if (hasRoles && row[rolesIndex] && typeof row[rolesIndex] !== 'string') {
      result.errors.push(`Row ${rowNumber}: Missing or invalid '${ROLES}'`);
    }

    // validate Phone Number and Office Line
    if (hasPhoneNumber && row[phoneNumberIndex] && !isPhoneNumberValid(row[phoneNumberIndex])) {
      result.errors.push(`Row ${rowNumber}: Invalid phone number format under '${PHONE_NUMBER}'`);
    }
    if (hasOfficeLine && row[officeLineIndex] && !isPhoneNumberValid(row[officeLineIndex])) {
      result.errors.push(`Row ${rowNumber}: Invalid phone number format under '${OFFICE_LINE}'`);
    }
  });

  return result;
};

export const INVALID_COLUMNS_ERROR = 'There is an issue with column header names in your file. Please reference column header names in our sample CSV template and try again.';

export const verifyFile = (file: { type: string, size: number }) => {
  const errors = [];
  if (file?.type !== FILE_TYPE) {
    errors.push('Invalid file type. Please upload a CSV file.');
  }
  if (file?.size > FILE_SIZE_LIMIT) {
    errors.push('File size exceeds the 50MB limit. Please upload a smaller file.');
  }
  return errors;
};

export const verifyUserSize = (users: unknown[]) => {
  if (users.length - 1 > MAX_USER_SIZE) {
    return 'Too many rows of data. File must have 10,000 rows or less.';
  }
  return '';
};

export const radioButtonValues = [
  { id: 'IGNORE', label: 'Ignore existing users' },
  { id: 'UPDATE', label: 'Update existing users' },
];
