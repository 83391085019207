import { HStack, Tooltip, Icon } from '@chakra-ui/react';
import { EmojiLoveIcon, PaperStackIcon, PaperclipIcon } from '@himarley/unity';
import React, {
  useCallback,
} from 'react';

import IconButtonWithPermissions from '@app/components/chakra/buttons/icon-button-with-permissions';
import isLoadedFromMobile from '@app/helpers/platform';

declare global {
  interface Window {
    handleCameraPermissionState?: (state: string) => void;
    webkit?: {
      messageHandlers?: {
        fetchCameraPermissionState?: {
          postMessage: (message?: string | null) => void;
        };
        showAlert?: {
          postMessage: (message?: string | null) => void;
        };
      };
    };
    AndroidInterface?: {
      fetchCameraPermissionState: () => void;
      showAlert: (message: string) => void;
    };
  }
}

// Function to fetch the current camera permission state
function fetchCameraPermissionState() {
  return new Promise((resolve, reject) => {
    if (window?.webkit?.messageHandlers?.fetchCameraPermissionState) {
      // Store the resolve function to be called later
      window.handleCameraPermissionState = (state) => {
        resolve(state);
      };
      window.webkit.messageHandlers.fetchCameraPermissionState.postMessage(null);
    } else if (window?.AndroidInterface?.fetchCameraPermissionState) {
      window.handleCameraPermissionState = (state) => {
        resolve(state);
      };
      window.AndroidInterface.fetchCameraPermissionState();
    } else {
      reject(new Error('Camera permission handler not available.'));
    }
  });
}

interface ChatInputButtonsProps {
  clearAndFocusTokenSelection: () => void;
  togglePanel: (panel: string) => void;
  uploadFileIsInProgress: boolean;
  disabled?: boolean;
  verifiedUser: boolean;
  handleFileSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  jobId: string;
}

const ChatInputButtons: React.FC<ChatInputButtonsProps> = ({
  clearAndFocusTokenSelection,
  togglePanel,
  uploadFileIsInProgress,
  disabled,
  verifiedUser,
  handleFileSelectChange,
  jobId,
}) => {
  let outBoundMediaRef: HTMLInputElement | null = null;
  const outBoundMediaEle = (
    <input
      data-testid={`hidden-file-input-${jobId}`}
      type="file"
      className="hidden-file-input"
      onChange={handleFileSelectChange}
      disabled={disabled}
      ref={(el) => {
        outBoundMediaRef = el;
      }}
    />
  );

  const onTemplateButtonClick = useCallback(() => {
    clearAndFocusTokenSelection();
    togglePanel('TEMPLATE');
  }, [clearAndFocusTokenSelection, togglePanel]);

  const disableIcon = uploadFileIsInProgress ? disabled : disabled || !verifiedUser;

  const iconButtons = [
    {
      id: 'templates',
      label: 'Use Template',
      icon: PaperStackIcon,
      onClick: onTemplateButtonClick,
      disabled: disableIcon,
    },
    {
      id: 'image-upload',
      label: 'Add Image',
      icon: PaperclipIcon,
      onClick: () => {
        if (isLoadedFromMobile()) {
          fetchCameraPermissionState()
            .then((state) => {
              console.log(`Current camera permission state: ${state}`);
              if (state === 'granted') {
                togglePanel('imageUpload');
                outBoundMediaRef?.click();
              } else if (window?.webkit?.messageHandlers?.showAlert) {
                window.webkit.messageHandlers.showAlert.postMessage('Camera access is not enabled');
              } else if (window?.AndroidInterface?.showAlert) {
                window.AndroidInterface.showAlert('Camera access is not enabled');
              } else {
                console.log('Camera permission not granted');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          togglePanel('imageUpload');
          outBoundMediaRef?.click();
        }
      },
      disabled: disableIcon,
    },
    ...(isLoadedFromMobile() ? [] : [{
      id: 'emoji',
      label: 'Add Emoji',
      icon: EmojiLoveIcon,
      onClick: () => {
        togglePanel('EMOJI');
      },
      disabled: disableIcon,
    }]),
  ];

  return (
    <HStack
      gap={1}
      sx={{
        "input[type='file']": {
          display: 'none',
        },
      }}
    >
      {outBoundMediaEle}
      {iconButtons.map((button) => (
        <Tooltip key={button.label} hasArrow label={button.label}>
          <span>
            <IconButtonWithPermissions
              aria-label={button.label}
              data-testid={`chat-input-button-${button.id}`}
              variant="ghost"
              onClick={button.onClick}
              disabled={button.disabled}
              icon={<Icon as={button.icon} />}
              size="sm"
            />
          </span>
        </Tooltip>
      ))}
    </HStack>
  );
};

export default ChatInputButtons;
