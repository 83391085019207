/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { Spinner } from '@chakra-ui/react';
import { useOktaAuth } from '@okta/okta-react';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import isLoadedFromMobile from '@app/helpers/platform';
import MarleyIcon from '../icons/MarleyIcon';
import { Logout } from '../Logout/Logout';
import { checkInitDone } from '../../helpers/common';
import { FAILURE, SUCCESS } from '../../constants/general';

const ErrorMessage = ({ message, loginId }) => (
  <div className="text-container">
    <div className="connectingText error">
      {`${message}\n`}
    </div>
    <div>
      {`User id: ${loginId}\n`}
    </div>
    <div>
      Please contact support@himarley.com with a screenshot of this page
    </div>
    <Logout />
  </div>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  loginId: PropTypes.string.isRequired,
};

const withLoadingScreen = (WrappedComponent) => {
  const LoadingScreen = (props) => {
    const { authState } = useOktaAuth();
    const loginId = authState?.idToken?.claims?.preferred_username;
    const { loadingStatus, accessTokenErrorMessage } = useSelector((state) => ({
      loadingStatus: checkInitDone(state),
      accessTokenErrorMessage: state.auth.accessTokenErrorMessage,
    }));
    const { children } = props;
    const isSmallScreen = useScreenSize();
    let message;
    if (loadingStatus === FAILURE) {
      message = 'There was an error loading the page';
    } else if (loadingStatus === 'health-check-failure') {
      message = 'Health check failure';
    } else if (loadingStatus === 'user-lookup-failure') {
      message = 'Error fetching user information';
    } else if (loadingStatus === 'websockets-failure') {
      message = 'WebSocket initialization failure';
    }

    if (accessTokenErrorMessage) {
      message = accessTokenErrorMessage;
    }

    const Error = (
      <div className="splashScreen">
        <div className="logo-container"><MarleyIcon /></div>
        <ErrorMessage message={message} loginId={loginId} />
      </div>
    );

    const Loading = (
      <div className="splashScreen">
        <div className="logo-container"><MarleyIcon /></div>
        {
          !isSmallScreen
            ? <div className="connectingText"> Pulling up your dashboard </div>
            : null
        }
        <div className="validatorSpinner">
          {
            isLoadedFromMobile() ? (
              <Spinner size="xl" color="marleyBlue.500" mt="16px" />
            ) : (
              <Loader type="ThreeDots" color="#44C7E9" height={60} width={70} />
            )
          }
        </div>
      </div>
    );

    if (accessTokenErrorMessage) {
      return Error;
    }

    if (loadingStatus === SUCCESS) {
      return (
        <WrappedComponent>
          {children}
        </WrappedComponent>
      );
    }

    if (message) {
      return Error;
    }

    return Loading;
  };

  LoadingScreen.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return LoadingScreen;
};

export default withLoadingScreen;
