import PropTypes from 'prop-types';

export const messageTemplatePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  tokens: PropTypes.arrayOf(PropTypes.shape({
    token: PropTypes.string,
  })),
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export const messageTemplateListPropTypes = PropTypes.shape({
  list: PropTypes.arrayOf(messageTemplatePropTypes),
  count: PropTypes.number,
});

export const messageTemplateDataPropTypes = PropTypes.shape({
  pinned: messageTemplateListPropTypes,
  templates: messageTemplateListPropTypes,
  suggested: messageTemplateListPropTypes,
});

export const templateStateFactory = () => ({
  pinned: {
    list: [],
    count: 0,
  },
  suggested: {
    list: [],
    count: 0,
  },
  templates: {
    list: [],
    count: 0,
  },
});

export const toggleMessageTemplatePin = (templateId, messageTemplates, pin = true) => {
  const updatedState = templateStateFactory();
  const pushGroup = pin ? 'pinned' : 'templates';
  const pullGroup = pin ? 'templates' : 'pinned';
  const selectedTemplate = messageTemplates[pullGroup].list.find(({ id }) => templateId === id);
  // add item to pinned or org templates and update count
  updatedState[pushGroup].list = [...messageTemplates[pushGroup].list, selectedTemplate];
  updatedState[pushGroup].count = updatedState[pushGroup].list.length;
  // remove item from pinned or org templates and update count
  updatedState[pullGroup].list = messageTemplates[pullGroup].list.filter(
    ({ id }) => templateId !== id,
  );
  updatedState[pullGroup].count = updatedState[pullGroup].list.length;
  updatedState.suggested = messageTemplates.suggested;
  return updatedState;
};

const parseTokensFromText = (text, tokens) => {
  const parts = text.split(/\${TOKEN}/g);
  const tokenizedArray = parts.map(
    (part, i) => `${part}${i < parts.length - 1 ? `$${tokens[i].token}` : ''}`,
  );
  return tokenizedArray.join('').toLowerCase();
};

const filteredMessageTemplates = (messageTemplates, searchText) => messageTemplates.filter(
  (msgTemplate) => {
    const { tokens } = msgTemplate;
    const msgTemplateText = parseTokensFromText(msgTemplate.body, tokens);
    const msgTemplateTitle = msgTemplate.displayName.toLowerCase();
    return (
      msgTemplateTitle.includes(searchText)
      || msgTemplateText.includes(searchText)
    );
  },
);

export const TEMPLATE_TAB_LOB = 'lob';
export const TEMPLATE_TAB_ORG = 'org';

export const selectQueryPath = (activeJob, selectedTab, active) => {
  if (!active) return null;
  // in the organization tab we overide the job lob id with null
  const queryParams = new URLSearchParams({
    category: 'CHAT',
    jobId: activeJob.id,
    includeSuggested: true,
    includePinned: true,
    sort: 'displayName:asc',
  });
  if (selectedTab === TEMPLATE_TAB_ORG) queryParams.set('lineOfBusinessId', null);
  return `/api/templates?${queryParams.toString()}`;
};

export const searchMessageTemplatesByText = (messageTemplates, searchText) => {
  const { templates, suggested, pinned } = messageTemplates;
  const searchTextLower = searchText.toLowerCase();
  const filteredOrgTemplates = filteredMessageTemplates(templates.list, searchTextLower);
  const filteredPinnedTemplates = filteredMessageTemplates(pinned.list, searchTextLower);
  const filteredSuggestedTemplates = filteredMessageTemplates(suggested.list, searchTextLower);
  return {
    templates: {
      list: filteredOrgTemplates,
      count: filteredOrgTemplates.length,
    },
    suggested: {
      list: filteredSuggestedTemplates,
      count: filteredSuggestedTemplates.length,
    },
    pinned: {
      list: filteredPinnedTemplates,
      count: filteredPinnedTemplates.length,
    },
  };
};
