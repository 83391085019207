import {
  IFormInput,
  CaseTypeEnum,
  META_DATA_CLAIM_STATUS,
  META_DATA_DATE_OF_LOSS,
  META_DATA_POLICY_NUMBER,
  META_DATA_DEDUCTIBLE,
  META_DATA_CLAIM,
  META_DATA_BRANDING,
  META_DATA_EFFECTIVE_DATE,
  META_DATA_EXPIRATION_DATE,
  META_DATA_GENERAL,
  META_DATA_YEAR_BUILT,
  META_DATA_POLICY_TYPE,
  META_DATA_ZIP_CODE,
  META_DATA_CITY,
  META_DATA_STREET_ADDRESS_1,
  META_DATA_STREET_ADDRESS_2,
  META_DATA_STATE,
  META_DATA_POLICY,
} from '@app/types/create-case';

// Add type definition for the mapping
type MetaDataMappingType = {
  [CaseTypeEnum.claim]: {
    insuranceBrand: string;
    claimNumber: string;
    claimStatus: string;
    policyNumber: string;
    dateOfLoss: string;
    deductible: string;
  };
  [CaseTypeEnum.policy]: {
    insuranceBrand: string;
    policyNumber: string;
    deductible: string;
    policyType: string;
    effectiveDate: string;
    expirationDate: string;
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: string;
    zipCode: string;
    yearBuilt: string;
  };
  [CaseTypeEnum.general]: {
    insuranceBrand: string;
    reference: string;
  };
};

export const META_DATA_MAPPING: MetaDataMappingType = {
  [CaseTypeEnum.claim]: {
    insuranceBrand: META_DATA_BRANDING,
    claimStatus: META_DATA_CLAIM_STATUS,
    dateOfLoss: META_DATA_DATE_OF_LOSS,
    policyNumber: META_DATA_POLICY_NUMBER,
    deductible: META_DATA_DEDUCTIBLE,
    claimNumber: META_DATA_CLAIM,
  },
  [CaseTypeEnum.policy]: {
    insuranceBrand: META_DATA_BRANDING,
    policyNumber: META_DATA_POLICY,
    deductible: META_DATA_DEDUCTIBLE,
    effectiveDate: META_DATA_EFFECTIVE_DATE,
    expirationDate: META_DATA_EXPIRATION_DATE,
    yearBuilt: META_DATA_YEAR_BUILT,
    policyType: META_DATA_POLICY_TYPE,
    zipCode: META_DATA_ZIP_CODE,
    city: META_DATA_CITY,
    streetAddress1: META_DATA_STREET_ADDRESS_1,
    streetAddress2: META_DATA_STREET_ADDRESS_2,
    state: META_DATA_STATE,
  },
  [CaseTypeEnum.general]: {
    insuranceBrand: META_DATA_BRANDING,
    reference: META_DATA_GENERAL,
  },
};

export const TAB_FIELDS: {
  [key: string]: Array<keyof IFormInput>
} = {
  CLAIM: Object.keys(META_DATA_MAPPING[CaseTypeEnum.claim]) as Array<keyof IFormInput>,
  POLICY: Object.keys(META_DATA_MAPPING[CaseTypeEnum.policy]) as Array<keyof IFormInput>,
  CASE: Object.keys(META_DATA_MAPPING[CaseTypeEnum.general]) as Array<keyof IFormInput>,
};

export const STATES_OPTIONS = [
  { id: 'AL', label: 'Alabama' },
  { id: 'AK', label: 'Alaska' },
  { id: 'AZ', label: 'Arizona' },
  { id: 'AR', label: 'Arkansas' },
  { id: 'CA', label: 'California' },
  { id: 'CO', label: 'Colorado' },
  { id: 'CT', label: 'Connecticut' },
  { id: 'DE', label: 'Delaware' },
  { id: 'FL', label: 'Florida' },
  { id: 'GA', label: 'Georgia' },
  { id: 'HI', label: 'Hawaii' },
  { id: 'ID', label: 'Idaho' },
  { id: 'IL', label: 'Illinois' },
  { id: 'IN', label: 'Indiana' },
  { id: 'IA', label: 'Iowa' },
  { id: 'KS', label: 'Kansas' },
  { id: 'KY', label: 'Kentucky' },
  { id: 'LA', label: 'Louisiana' },
  { id: 'ME', label: 'Maine' },
  { id: 'MD', label: 'Maryland' },
  { id: 'MA', label: 'Massachusetts' },
  { id: 'MI', label: 'Michigan' },
  { id: 'MN', label: 'Minnesota' },
  { id: 'MS', label: 'Mississippi' },
  { id: 'MO', label: 'Missouri' },
  { id: 'MT', label: 'Montana' },
  { id: 'NE', label: 'Nebraska' },
  { id: 'NV', label: 'Nevada' },
  { id: 'NH', label: 'New Hampshire' },
  { id: 'NJ', label: 'New Jersey' },
  { id: 'NM', label: 'New Mexico' },
  { id: 'NY', label: 'New York' },
  { id: 'NC', label: 'North Carolina' },
  { id: 'ND', label: 'North Dakota' },
  { id: 'OH', label: 'Ohio' },
  { id: 'OK', label: 'Oklahoma' },
  { id: 'OR', label: 'Oregon' },
  { id: 'PA', label: 'Pennsylvania' },
  { id: 'RI', label: 'Rhode Island' },
  { id: 'SC', label: 'South Carolina' },
  { id: 'SD', label: 'South Dakota' },
  { id: 'TN', label: 'Tennessee' },
  { id: 'TX', label: 'Texas' },
  { id: 'UT', label: 'Utah' },
  { id: 'VT', label: 'Vermont' },
  { id: 'VA', label: 'Virginia' },
  { id: 'WA', label: 'Washington' },
  { id: 'WV', label: 'West Virginia' },
  { id: 'WI', label: 'Wisconsin' },
  { id: 'WY', label: 'Wyoming' },
];
