import { useToast } from '@chakra-ui/react';
import { CheckmarkFilledIcon, LockIcon } from '@himarley/unity';
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import { updateGroups } from '@app/actions/users';
import Menu from '@app/components/chakra/menu';
import { useGroupsQuery } from '@app/services/group';
import { Group } from '@app/types/api/group';
import { User } from '@app/types/api/user';

interface AssignGroupProps {
  user: User;
}

const AssignGroup: React.FC<AssignGroupProps> = ({ user }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    user.groups ?? [],
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  useEffect(() => {
    setSelectedGroups(user.groups ?? []);
  }, [user.groups]);

  const userGroupsQuery = useGroupsQuery({
    searchText: debouncedSearch || '',
    ids: [...(user.groups ?? []), ...(user.groupLeads ?? [])],
  });

  const allGroupsQuery = useGroupsQuery({
    searchText: debouncedSearch || '',
  });

  const { data: userGroupsData, isLoading: isLoadingUserGroups } = userGroupsQuery;

  const {
    data: allGroupsData,
    isLoading: isLoadingAllGroups,
    fetchNextPage,
    hasNextPage,
  } = allGroupsQuery;

  const groups = useMemo(
    () => {
      const userGroups = userGroupsData?.pages.reduce(
        (accumulator, item) => [...accumulator, ...item.groups],
        [],
      ) || [];

      const otherGroups = allGroupsData?.pages.reduce(
        (accumulator, item) => [...accumulator, ...item.groups],
        [],
      ) || [];

      // Filter out duplicates and combine user's groups with other groups
      const userGroupIds = new Set(userGroups.map((g: Group) => g._id));
      const uniqueOtherGroups = otherGroups.filter((g: Group) => !userGroupIds.has(g._id));

      return [...userGroups, ...uniqueOtherGroups];
    },
    [userGroupsData?.pages, allGroupsData?.pages],
  );

  const isLoading = isLoadingUserGroups || isLoadingAllGroups;

  const handleMenuClose = useCallback(async () => {
    const addGroups = selectedGroups.filter((groupId) => !user.groups?.includes(groupId)) ?? [];
    const removeGroups = user.groups?.filter((groupId) => !selectedGroups.includes(groupId)) ?? [];

    if (addGroups.length > 0 || removeGroups.length > 0) {
      dispatch(
        // This is because the function is not typed
        updateGroups(user, { add: addGroups as never[], remove: removeGroups as never[] }, toast),
      );
      setSelectedGroups(user.groups ?? []);
    }
    setSearchQuery('');
  }, [dispatch, selectedGroups, user, toast]);

  const buildButtonLabel = (
    userGroups: string[] = [],
    orgGroups: Group[] = [],
  ) => {
    const actualUserGroups = userGroups.filter(
      (groupId) => orgGroups.some((group) => group._id === groupId),
    );

    const groupsCount = userGroups.length || 0;
    const hasMultipleGroups = groupsCount > 1;

    if (hasMultipleGroups) {
      return `${groupsCount} Groups`;
    }

    if (groupsCount === 1) {
      const groupName = orgGroups.find(
        (group) => group._id === actualUserGroups[0],
      )?.name;
      return groupName || 'No Groups';
    }

    return 'No Groups';
  };

  const buttonLabel = buildButtonLabel(
    [...(user.groups || []), ...(user.groupLeads || [])],
    groups,
  );

  const menuOptions = useMemo(() => {
    const hasGroup = (groupId: string) => selectedGroups.some((group) => group === groupId);

    const hasGroupLead = (groupId: string) => user.groupLeads?.some((group) => group === groupId);

    const getRightIcon = (
      isLead: boolean | undefined,
      hasGroupAccess: boolean | undefined,
    ) => {
      if (isLead) return LockIcon;
      if (hasGroupAccess) return CheckmarkFilledIcon;
      return undefined;
    };

    const getGroupTitle = (
      isLead: boolean | undefined,
      hasGroupAccess: boolean | undefined,
    ) => {
      if (isLead) return 'Lead';
      if (hasGroupAccess) return 'Member';
      return 'Member';
    };

    const handleGroupSelect = (groupId: string) => {
      setSelectedGroups((current) => {
        if (hasGroup(groupId)) {
          return current.filter((id) => id !== groupId);
        }
        return [...current, groupId];
      });
    };

    const leads = [...groups].filter((group) => hasGroupLead(group._id || ''));
    const presSelectedGroups = new Set(user?.groups || []);
    const members = [...groups].filter((group) => !hasGroupLead(group._id || '')).sort((a, b) => {
      const aIsSelected = presSelectedGroups.has(a._id || '');
      const bIsSelected = presSelectedGroups.has(b._id || '');
      if (aIsSelected && !bIsSelected) return -1;
      if (!aIsSelected && bIsSelected) return 1;
      return 0;
    });

    return [...leads, ...members]
      .map((group: Group) => {
        const isLead = hasGroupLead(group._id || '');
        const hasGroupAccess = hasGroup(group._id || '');
        return {
          id: group._id || '',
          label: group.name,
          onClick: isLead
            ? undefined
            : () => handleGroupSelect(group._id || ''),
          rightIcon: getRightIcon(isLead, hasGroupAccess),
          rightIconTooltip: isLead
            ? 'You can change the lead status from the group management page.'
            : undefined,
          closeOnSelect: false,
          group: getGroupTitle(isLead, hasGroupAccess),
          isDisabled: isLead,
        };
      });
  }, [groups, selectedGroups, user?.groupLeads, user?.groups]);

  return (
    <Menu
      id="assign-group"
      defaultLabel={buttonLabel}
      onClose={handleMenuClose}
      options={menuOptions}
      menuButtonProps={{
        w: '120px',
      }}
      searchable
      searchValue={searchQuery}
      onSearchChange={setSearchQuery}
      isLoading={isLoading}
      increaseDataSet={fetchNextPage}
      shouldLoadMoreItems={!searchQuery?.trim() && hasNextPage}
      isLazy
      sx={{
        '.chakra-menu__menuitem': {
          _disabled: {
            opacity: 1,
          },
        },
      }}
    />
  );
};

export default AssignGroup;
