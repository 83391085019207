import React, {
  useRef, useLayoutEffect, useState, useEffect,
} from 'react';
import { Dispatch } from 'redux';

import type { File as FileType } from '@app/types/media';

import MediaItem from './media-item';
import MediaUploadStatusBadge from './media-upload-status-badge';
import type { MediaContainerConfiguration, MediaItem as MediaItemType } from './types';
import useFileCache from '../../hooks/file-loader';
import './MediaContainer.less';

const fixedHeightItems = ['audio', 'video', 'text/x-vcard'];

const determineContainerHeight = (
  fixedHeight: string | number | null,
  isFixed: boolean,
  isThumbnail: boolean,
  contentType: string | null,
) => {
  const normalizedContentType = contentType?.toLowerCase() || '';
  const alwaysFixedHeight = fixedHeightItems.some(
    (fixedContentType) => normalizedContentType.includes(fixedContentType),
  );
  if (isThumbnail || (!alwaysFixedHeight && isFixed)) {
    return fixedHeight;
  }
  if (!alwaysFixedHeight || !isFixed) {
    return '100%';
  }
  return null;
};

interface MediaContainerProps {
  configuration?: MediaContainerConfiguration;
  onExpand: () => Dispatch | null;
  onLoad?: (file: FileType) => void;
  mediaItem: MediaItemType;
  isViewingRedactedImage: boolean;
}

const MediaContainer: React.FC<MediaContainerProps> = ({
  configuration,
  onExpand,
  onLoad,
  mediaItem,
  isViewingRedactedImage,
}) => {
  const {
    mode,
    fixedHeight = 0,
    fixedWidth = 0,
    isThumbnail = false,
  } = configuration || {};
  const {
    fileUrl,
    isImageRedacted = false,
    providerId = '',
    veriskUploadStatus,
  } = mediaItem || {};
  const file = useFileCache(fileUrl, isImageRedacted);
  const ref = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: fixedWidth, height: fixedHeight });
  const isFixed = (mode === 'fixed');

  useEffect(() => {
    if (!onLoad || file.loadingState === 'loading') {
      return;
    }
    onLoad({ ...file });
  }, [file, onLoad]);

  useLayoutEffect(() => {
    if (isFixed) {
      return () => {};
    }

    // if mode is dynamic, setup dynamic sizing
    const updateDimensions = () => {
      if (ref.current) {
        setDimensions({
          width: ref.current?.offsetWidth || 0,
          height: ref.current?.offsetHeight || 0,
        });
      }
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [mode, isFixed]);

  return (
    <div
      ref={ref}
      className={`media-item-boundary media-item-boundary-${mode}`}
      data-testid={`media-item-boundary-${providerId}`}
      style={{
        height: determineContainerHeight(
          fixedHeight,
          isFixed,
          isThumbnail,
          mediaItem?.mediaInfo?.contentType || null,
        ) || undefined,
        width: isFixed ? `${fixedWidth}px` : '100%',
      }}
    >
      <MediaItem
        containerDimensions={dimensions}
        file={file}
        onExpand={onExpand}
        mediaItem={mediaItem}
        isThumbnail={isThumbnail}
        isImageRedacted={isViewingRedactedImage}
      />
      <MediaUploadStatusBadge
        mediaUploadStatus={veriskUploadStatus}
      />
    </div>
  );
};

export default MediaContainer;
