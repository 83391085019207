/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import isLoadedFromMobile from '@app/helpers/platform';
import Media from './Media';
import DownloadIcon from '../../../../../../images/icons/download.svg';
import { downloadAll } from './helpers';
import { updateMediaModalIndex } from '../../../../../actions/ui';

import './MediaDetails.less';

const MediaDetails = ({ referenceId, mediaMessages }) => {
  const dispatch = useDispatch();
  const [allFiles, updateAllFiles] = useState([]);
  const [allLoaded, updateAllLoaded] = useState(false);
  const expandFileIndex = (index) => () => dispatch(updateMediaModalIndex(index));
  useEffect(() => {
    if (allFiles.every((file) => file?.loadingState !== 'loading')) {
      updateAllLoaded(true);
    }
  }, [allFiles]);

  const updateFileOnLoad = useCallback((index) => (file) => {
    let updated = allFiles;
    if (updated.length !== mediaMessages.length) {
      updated = mediaMessages.map(() => ({ loadingState: 'loading' }));
    }
    if (allFiles[index]?.loadingState !== file.loadingState) {
      const extension = mediaMessages[index].fileUrl.split('.')[1] || '';
      updated[index] = {
        ...file,
        extension,
      };
      updateAllFiles(updated);
    }
  }, [allFiles, mediaMessages]);
  if (!mediaMessages.length) return null;
  return (
    <div className="user-details-media">
      {!isLoadedFromMobile() && (
        <div className="user-details-media-header">
          <button
            className="user-details-download-all-button"
            data-testid="user-details-download-all-button"
            onClick={() => downloadAll(allFiles, referenceId)}
            disabled={!allLoaded}
            type="button"
          >
            <div>Download all</div>
            <DownloadIcon />
          </button>
        </div>
      )}
      <div
        className="user-details-media-items"
        data-testid="user-details-media-items"
      >
        { mediaMessages.map((mediaMessage, index) => (
          <Media
            key={mediaMessage.fileUrl}
            fileUrl={mediaMessage.fileUrl}
            onExpand={expandFileIndex(index)}
            onLoad={updateFileOnLoad(index)}
            veriskUploadStatus={mediaMessage.veriskUploadStatus}
          />
        ))}
      </div>
    </div>
  );
};

MediaDetails.propTypes = {
  mediaMessages: PropTypes.arrayOf(PropTypes.shape({
    fileUrl: PropTypes.string,
    providerId: PropTypes.string,
  })),
  referenceId: PropTypes.string.isRequired,
};

MediaDetails.defaultProps = {
  mediaMessages: [],
};

export default MediaDetails;
