/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  shallowEqual, useDispatch, useSelector,
} from 'react-redux';
import _ from 'lodash';
import { addUserToChat, removeUserFromChat, getChatParticipants } from '../../../../actions/chat';
import { operatorType } from '../../../../models/marleyTypes';
import { jobPutRequest } from '../../../../actions/job';

import './InCurrentConversation.less';
import SearchDropdown from '../../../elements/dropdowns/SearchDropdown/SearchDropdown';
import SearchOperators from '../../../elements/SearchOperators/SearchOperators';
import Ghosts from '../../../elements/Ghosts/Ghosts';
import CaseVisibility from './CaseVisibility/CaseVisibility';
import PermissionVerifier from '../../../PermissionVerifier/PermissionVerifier';
import UserList from '../UserList/user-list';
import Participants from './Participants/participants';
import { CASE_VISIBILITY } from '../../../../constants/permissions';
import { handleUserAssignment } from '../../../../helpers/cases';

const InCurrentConversation = () => {
  const [clearSearchFilter, setClearSearchFilter] = useState(false);
  const {
    assignedOperatorId, activeChatId, activeCase, participants, secondaryOperatorIds,
  } = useSelector((state) => {
    const activeChatId = _.get(state, 'jobs.activeChat.id', '');
    const activeCase = _.get(state, 'jobs.activeJob', {});
    const assignedOperatorId = _.get(state, 'jobs.activeJob.assignedOperator.id', '');
    const secondaryOperatorIds = _.get(state, 'jobs.activeJob.secondaryOperatorIds', []);

    return {
      activeChatId,
      activeCase,
      assignedOperatorId,
      secondaryOperatorIds,
      participants: _.get(state, `jobs.chats.${activeChatId}.participants`, []),
    };
  }, shallowEqual);

  const dispatch = useDispatch();
  const userAssignment = handleUserAssignment(activeCase, jobPutRequest, dispatch);

  useEffect(() => {
    if (activeChatId) {
      dispatch(getChatParticipants(activeChatId));
    }
  }, [activeChatId, JSON.stringify([assignedOperatorId, ...secondaryOperatorIds]), dispatch]);

  const handleSelectOperator = (userId) => {
    dispatch(addUserToChat({ userId, chatId: activeChatId }));
    setClearSearchFilter(true);
  };

  const toggleFilterCleared = () => {
    setClearSearchFilter(false);
  };
  const participantIds = participants.map((p) => p.id);

  return (
    <div className="in-current-conversation sidebar-panel">
      <div className="search-and-participants">
        <div className="title">Manage Users</div>
        <SearchDropdown
          placeholder="Search to add to conversation"
          clearFilter={clearSearchFilter}
          toggleFilterCleared={toggleFilterCleared}
          testId="add-operator-to-conversation"
        >
          <SearchOperators
            type={operatorType}
            onSelect={handleSelectOperator}
            ListItem={UserList}
            excludeIdList={participantIds}
          />
        </SearchDropdown>
        {participants.length === 0 ? <Ghosts className="user-row" numItems={2} />
          : (
            <Participants
              jobPutRequest={(userId) => userAssignment(userId)}
              participants={_.uniqBy(participants, 'id')}
              activeCase={activeCase}
              removeUser={
                (userId) => dispatch(removeUserFromChat({ userId, chatId: activeChatId }))
              }
            />
          )}
      </div>
      <PermissionVerifier neededPermissions={[CASE_VISIBILITY]}>
        <CaseVisibility participants={participants} />
      </PermissionVerifier>
    </div>
  );
};

export default InCurrentConversation;
