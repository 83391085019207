/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'

const TableCell = ({
  id,
  children,
  link,
  secondaryLabel,
}) => (
  <div className={`table-col table-col-${id}`} data-test={`table-col-${id}`}>
    {link ? (
      <Link
        data-test="table-cell-link"
        to={link}
        className="table-cell-link"
      >
        { children }
      </Link>
    ) : (
      <div className="table-cell-label-container">
        { children }
        { secondaryLabel && <div className="table-cell-secondary-label">{ secondaryLabel }</div> }
      </div>
    )}
  </div>
)

TableCell.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  link: PropTypes.string,
  secondaryLabel: PropTypes.string,
};

TableCell.defaultProps = {
  children: null,
  id: '',
  link: '',
  secondaryLabel: '',
};

export default TableCell
