import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateVirusScanStatus } from '@app/actions/media';
import { VIRUS_SCAN_STATUS } from '@app/components/Media/constants';
import type { File as FileType } from '@app/types/media';

import globalMediaCacheFactory from './global-file-cache';

const loadingStage = {
  loaded: 'loaded',
  error: 'error',
  loading: 'loading',
};

const useFileCache = (fileUrl: string | undefined, refetch?: unknown): FileType => {
  const [loadingState, setLoadingState] = useState(loadingStage.loading);
  const [localFileUrl, setLocalFileUrl] = useState<string | undefined>(undefined);
  const [contentType, setContentType] = useState<string | undefined>(undefined);
  const [virusScanStatus, setVirusScanStatus] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchImageWithAuthorization = async () => {
      if (!fileUrl) {
        console.warn('file url is not defined!');
        return;
      }

      const cache: {
        getItem: (key: string) => Promise<Response>;
        setItem?: (key: string, value: Response) => Promise<void>;
      } = await globalMediaCacheFactory();
      const cachedResponse = await cache.getItem(fileUrl);

      if (!cachedResponse || !cachedResponse.ok) {
        console.error(`error loading file at url: ${fileUrl} status: ${cachedResponse?.statusText}`);
        setLoadingState(loadingStage.error);
        return;
      }

      const blob = await cachedResponse.blob();
      const fileURL = URL.createObjectURL(blob);
      setLocalFileUrl(fileURL);

      const headerVirusScanStatus: string | null = cachedResponse.headers.get('X-Virus-Scanned');
      setVirusScanStatus(headerVirusScanStatus || undefined);

      setContentType(cachedResponse.headers.get('Content-Type') || undefined);
      setLoadingState(loadingStage.loaded);
    };

    fetchImageWithAuthorization();

    let intervalId: NodeJS.Timeout | null = null;

    if (virusScanStatus) {
      dispatch(updateVirusScanStatus(fileUrl, virusScanStatus));
    }

    if (virusScanStatus === VIRUS_SCAN_STATUS.QUEUED) {
      intervalId = setInterval(async () => {
        const globalFileCache = await globalMediaCacheFactory();
        await globalFileCache?.deleteItem(fileUrl);
        fetchImageWithAuthorization();
      }, 5000); // Poll every 5 seconds
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fileUrl, loadingState, refetch, virusScanStatus]);

  return {
    loadingState,
    localFileUrl,
    contentType,
    virusScanStatus,
  };
};

export default useFileCache;
