import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';

import isLoadedFromMobile, { isLoadedFromHiMarleyConnect } from '@app/helpers/platform';

// eslint-disable-next-line import/prefer-default-export
export const RequiredAuth: React.FC = () => {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      oktaAuth.setOriginalUri(originalUri);
      if (isLoadedFromMobile() || isLoadedFromHiMarleyConnect()) {
        oktaAuth.signInWithRedirect({
          scopes: ['openid', 'profile', 'email', 'offline_access'],
        });
      } else {
        navigate('/login');
      }
    }
  }, [oktaAuth, authState?.isAuthenticated, authState, navigate]);

  if (!authState || !authState?.isAuthenticated) {
    return null;
  }

  return <Outlet />;
};
