import { QUICK_FILTER_MENU_LABELS } from '../components/ChatsContainer/ChatInbox/InboxHeader/QuickFilter/constants';
import { PAGE_SIZE } from '../constants/general';
import { ASSIST_RELEASE_ISSUE_CREATED, ASSIST_RELEASE_ISSUE_UPDATED } from '../constants/integration-constants';

export const getOperators = (items, groups, operatorIds, userId) => {
  let operators = [];
  if (
    Object.keys(groups || {})?.length === 0
    && Object.keys(operatorIds || {})?.length === 0
    && !items?.allChats
    && !items?.unassigned
    && !items?.myChats
    && !items?.secondaryOperatorIds
  ) {
    // default to my chats
    return [userId];
  }
  if (items?.myChats) {
    operators.push(userId);
  }
  if (items?.unassigned) {
    operators.push('unassigned');
  }
  if (Object.keys(operatorIds || {})?.length > 0) {
    operators = [...operators, ...Object.keys(operatorIds || {})];
  }

  return operators;
};

// used to build out parameters in url for inbox filters
export function buildInboxFilterObject(customFilters, userId) {
  const {
    onlyActive = false,
    groups = {},
    operatorIds = {},
    items = {},
    tags = [],
    secondarySort, secondaryOrder,
    refineBy = null,
  } = customFilters?.inboxFilters || {};
  const filterObject = {};
  filterObject.limit = customFilters?.limit || PAGE_SIZE;
  filterObject.view = customFilters?.view || 'full';
  filterObject.order = customFilters?.inboxFilters?.order || 'DESC';
  filterObject.sort = customFilters?.inboxFilters?.sort || 'lastMessage.sentAt';

  if (secondarySort && secondaryOrder) {
    filterObject.secondarySort = secondarySort;
    filterObject.secondaryOrder = secondaryOrder;
  }

  if (onlyActive === true) {
    filterObject.onlyActive = onlyActive;
  }
  filterObject.isOpen = true;
  if (Object.keys(groups)?.length > 0) {
    filterObject.groupIds = Object.keys(groups);
  }
  const allOperatorIds = getOperators(items, groups, operatorIds, userId);
  if (allOperatorIds?.length > 0) {
    filterObject.operatorIds = allOperatorIds;
  }
  if (!tags.includes('all') && tags.length > 0) {
    filterObject.tags = tags;
  }
  if (items?.secondaryOperatorIds) {
    filterObject.secondaryOperatorIds = [userId];
  }
  if (refineBy) {
    filterObject[refineBy] = true;
  }

  filterObject.expandInboxSearchBar = customFilters?.expandInboxSearchBar;

  if (customFilters?.searchMessages) {
    filterObject.searchMessages = customFilters?.searchMessages;
  }
  return filterObject;
}

export const parseParams = (params) => Object.keys(params)
  .map((p) => (Array.isArray(params[p])
    ? `${p}[]=${params[p].join(`&${p}[]=`)}`
    : `${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`))
  .join('&');

export function buildPaginatedQueryString(pageParam, filters) {
  const params = {
    ...filters,
    page: pageParam,
  };

  return params ? parseParams(params) : '';
}

export const checkInboxFilterOperatorSet = (inboxFilters) => {
  if (inboxFilters?.items?.myChats === QUICK_FILTER_MENU_LABELS.primaryOpLabel
    || inboxFilters?.items?.secondaryOperatorIds === QUICK_FILTER_MENU_LABELS.secondaryOpsLabel
    || Object.keys(inboxFilters?.operatorIds || {}).length > 0
    || Object.keys(inboxFilters?.groups || {}).length > 0) {
    return true;
  }
  return false;
};

export const checkPrefetchData = (
  isInboxFilterOperatorSet,
  prefetchCasesCount,
) => isInboxFilterOperatorSet && prefetchCasesCount === 0;

export const filterTotalLossAssistEvents = (response) => {
  const totalLossAssistEvents = [
    ASSIST_RELEASE_ISSUE_CREATED,
    ASSIST_RELEASE_ISSUE_UPDATED,
  ];
  return response.data.filter((event) => !totalLossAssistEvents.includes(event.eventType));
};
