import { Button, Icon } from '@chakra-ui/react';
import { AddCircleIcon, EditIcon } from '@himarley/unity';
import React from 'react';

import useScreenSize from '../../Hooks/use-screen-size';

interface CreateWorkflowSettingsButtonProps {
  buttonText?: string;
  size?: 'sm' | 'md' | 'lg';
  onClick: () => void;
}

const CreateWorkflowSettingsButton = ({
  buttonText,
  size = 'md',
  onClick,
}: CreateWorkflowSettingsButtonProps) => {
  const isSmallScreen = useScreenSize();

  if (isSmallScreen) {
    return (
      <Button
        data-testid="add-workflow-settings-button"
        size={size}
        onClick={onClick}
        bg="transparent"
        sx={{
          _hover: {
            bg: 'transparent',
          },
        }}
      >
        <Icon
          as={EditIcon}
          className="edit-icon"
          sx={{ color: 'blue.60 !important' }}
        />
      </Button>
    );
  }

  return (
    <Button
      data-testid="add-workflow-settings-button"
      leftIcon={<Icon as={AddCircleIcon} />}
      size={size}
      onClick={onClick}
    >
      {buttonText || 'Add workflow settings'}
    </Button>
  );
};

export default CreateWorkflowSettingsButton;
