import {
  Flex, Spacer, Box,
} from '@chakra-ui/react';
import { SearchBar } from '@himarley/unity';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { subscribeToTopics as subscribeToTopicsAction, unsubscribeFromTopics as unsubscribeFromTopicsAction } from '@app/actions/socket';
import CloseClaimConfirmation from '@app/components/confirmationModals/CloseClaimConfirmation/CloseClaimConfirmation';
// eslint-disable-next-line import/no-named-as-default
import SendEmailConfirmation from '@app/components/confirmationModals/SendEmailConfirmation/SendEmailConfirmation';
import SendSurveyConfirmation from '@app/components/confirmationModals/SendSurveyConfirmation/SendSurveyConfirmation';
import CreateCaseButton from '@app/components/CreateCase/create-case-button';
import DisabledBanner from '@app/components/elements/Banner/DisabledBanner/DisabledBanner';
import './Claims.less';
import useTopicHandler from '@app/components/elements/hooks/useTopicHandler';
import withLoading from '@app/components/HigherOrderComponents/withLoading';
import Panel from '@app/components/Panel/Panel';
import withLazyLoad from '@app/components/withLazyLoad/withLazyLoad';
import { DISABLE_CREATE_CASE } from '@app/constants/permissions';
import { getAllOperatorsTopics } from '@app/helpers/liveUpdates';
import useSearchBar from '@app/hooks/search-bar';
import { claimType, caseType } from '@app/models/marleyTypes';
import selector from '@app/selectors/case';
import { StateType } from '@app/types/reducer-state';

import ClaimsTable from './ClaimTable/ClaimTable';

export const modalTitle = (type: { title: () => string }, mode: string) => {
  const title = type.title();
  if (mode === 'create') {
    return `Create ${title}`;
  } if (mode === 'edit') {
    return `Edit ${title}`;
  }
  return `${title} Information`;
};

export const mapDispatchToProps = {
  subscribeToTopics: subscribeToTopicsAction,
  unsubscribeFromTopics: unsubscribeFromTopicsAction,
};

export const mapStateToProps = (state: StateType) => selector(state);

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ClaimsProps = PropsFromRedux & {
  form: unknown;
  organization: unknown;
  permissions: string[];
};

const Claims: React.FC<ClaimsProps> = (props) => {
  const {
    form = { mode: 'isCreating' },
    subscribeToTopics,
    unsubscribeFromTopics,
    organization,
    permissions,
  } = props;

  const {
    isEditing,
    isCreating,
    isViewing,
  } = form;

  const [searchFilter, setSearchFilter] = useSearchBar(caseType.id());

  const showModal = isEditing || isCreating || isViewing;
  const isCreationDisabled = permissions.find((v: string) => v === DISABLE_CREATE_CASE);

  const memoizedTopicHandler = useTopicHandler();
  useEffect(() => {
    subscribeToTopics(getAllOperatorsTopics(organization._id), memoizedTopicHandler);
    return () => {
      unsubscribeFromTopics(getAllOperatorsTopics(organization._id));
    };
  }, []);

  return (
    <Panel
      className="claims-page"
      header={(
        <Flex>
          <Box>
            {isCreationDisabled
              ? (
                <div>
                  <DisabledBanner
                    bannerText="Creating a claim is unavailable for your organization. Please go to your claims management system to create a new claim."
                    includesLink={undefined}
                    linkRef={undefined}
                    linkText={undefined}
                  />
                </div>
              )
              : <CreateCaseButton buttonText="Create Claim" />}
          </Box>
          <Spacer />
          <Box>
            <SearchBar
              id="claims"
              className="claims-search-bar"
              placeholder="Search claims"
              value={searchFilter}
              onValueChange={setSearchFilter}
              onClear={() => setSearchFilter('')}
            />
          </Box>
        </Flex>
      )}
      title="Claims"
      titleFooter={undefined}
      titleNotification={undefined}
      right={undefined}
    >
      <SendEmailConfirmation id="job" />
      <CloseClaimConfirmation id="claim" />
      <SendSurveyConfirmation id="job" />
      <ClaimsTable
        id="claim"
        label="claims"
        type={claimType}
        isFormOpen={showModal}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />

    </Panel>
  );
};

export { Claims };

const ClaimsWithLoading = withLoading(Claims, { type: caseType });
const LoadedClaims = withLazyLoad(
  ClaimsWithLoading,
  {
    type: caseType,
    listLocation: 'jobs',
    defaultSort: {
      column: 'createdAt',
    },
    query: {
      caseTypeId: ['36ad685a-bc4b-5213-c3e1-8c28e4549c13', '75dd685b-b04b-4113-83e1-8c23f1149c11'],
    },
  },
);

export default connector(LoadedClaims);
