import {
  FormControl, FormLabel, FormErrorMessage, Text,
} from '@chakra-ui/react';
import { CheckmarkFilledIcon } from '@himarley/unity';
import React, { useMemo, useState, useEffect } from 'react';
import {
  useFormContext, type RegisterOptions, type FieldValues, Path,
  PathValue,
} from 'react-hook-form';

import { useCustomStyles } from '@app/chakra-theme/hooks/use-custom-styles';
import Menu from '@app/components/chakra/menu';

interface SelectMenuProps<T extends FieldValues> {
  id: string;
  label: string;
  name: Path<T>;
  options: Array<{
    id: string;
    label: string;
    group?: string;
    handleSelect?: (value: string) => void;
  }>;
  defaultValue?: string;
  registerOptions?: RegisterOptions<T>;
  isDisabled?: boolean;
}

const SelectMenu = <T extends FieldValues>({
  id,
  label,
  name,
  options = [],
  defaultValue,
  registerOptions,
  isDisabled,
}: SelectMenuProps<T>) => {
  const {
    setValue,
    getValues,
    trigger,
    register,
    formState: { errors },
  } = useFormContext<T>();
  const { formControlStyles, menuButtonStyles } = useCustomStyles();

  register(name, registerOptions);

  let valueLabel = '';
  const valuesName = getValues(name);
  if (valuesName) {
    valueLabel = options.find((option) => option.id === valuesName)?.label || '';
  }

  const [selectedOption, setSelectedOption] = useState<string>(valueLabel || defaultValue || '');

  useEffect(() => {
    setSelectedOption(valueLabel || defaultValue || '');
  }, [valueLabel, defaultValue]);

  const menuOptions = useMemo(() => {
    const handleSelect = (option: {
      id: string;
      label: string;
      group?: string;
      handleSelect?: (value: string) => void;
    }) => {
      if (option.handleSelect) {
        option.handleSelect(option.id);
      }
      setSelectedOption(option.label);
      setValue(name, option.id as PathValue<T, Path<T>>);
      trigger(name);
    };

    const sortedOptions = [...(options || [])].sort((a, b) => {
      if (!a.group && b.group) return -1;
      if (a.group && !b.group) return 1;
      if (a.group && b.group) {
        return a.group.localeCompare(b.group);
      }
      return 0;
    });

    return sortedOptions.map((option) => ({
      onClick: () => handleSelect(option),
      ...option,
      rightIcon: option.label === selectedOption ? CheckmarkFilledIcon : undefined,
    }));
  }, [name, options, selectedOption, setValue, trigger]);

  const isRequired = registerOptions?.required;
  const labelId = `${id}-label`;

  // Dynamic styles based on selection state
  const dynamicMenuButtonStyles = {
    ...menuButtonStyles,
    color: selectedOption === defaultValue ? 'gray.500' : 'black',
    fontSize: selectedOption === defaultValue ? 'sm' : 'md',
  };

  return (
    <FormControl
      id={name}
      isInvalid={!!errors[name]}
      sx={formControlStyles}
    >
      <FormLabel
        id={labelId}
        cursor="default"
      >
        {`${label}${isRequired ? ' (Required)' : ''}`}
      </FormLabel>
      {isDisabled ? (
        <Text>{selectedOption}</Text>
      ) : (
        <Menu
          id={id}
          defaultLabel={selectedOption}
          options={menuOptions}
          menuButtonProps={{
            size: 'md',
            w: '100%',
            'aria-labelledby': labelId,
            sx: dynamicMenuButtonStyles,
          }}
          matchWidth
        />
      )}
      <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
    </FormControl>
  );
};

export default SelectMenu;
