import React from 'react';

import SubMessage from '@app/components/Chats/Chat/ChatLog/ChatMessage/SubMessage';
import { formatMonthDayYear } from '@app/helpers/datetimeWithoutMoment';

import StarRate from '../../../StarRate/StarRate';
import './FeedbackItem.less';

interface FeedbackItemProps {
  templateKey: string | number,
  rate: number,
  userName: string,
  review: string,
  metricAnswerTimestamp: number | string,
  translatedReview?: string,
}

const FeedbackItem: React.FC<FeedbackItemProps> = ({
  templateKey,
  rate,
  userName,
  review,
  metricAnswerTimestamp,
  translatedReview,
}) => {
  const time = metricAnswerTimestamp ? formatMonthDayYear(metricAnswerTimestamp) : '';

  return (
    <div className="customer-feedback-item" key={templateKey} data-testid={`customer-feedback-item-${templateKey}`}>
      <div className="customer-feedback-item-rate-wrap">
        <div className="customer-feedback-item-timestamp">{time}</div>
        <div className="customer-feedback-item-rate-num">
          {rate.toFixed(1)}
        </div>
        <StarRate className="customer-feedback-item-rate-stars" score={rate} type="small" />
      </div>
      <div className="customer-feedback-user">
        User
        <div className="customer-feedback-user-value">
          {userName}
        </div>
      </div>
      <div className="customer-feedback-review">
        Review
        <div className="customer-feedback-review-value">
          {translatedReview ? (
            <div data-testid="translated-review">
              {translatedReview}
              <SubMessage isOriginal message={review} />
            </div>
          ) : (
            review
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackItem;
