/* eslint-disable no-nested-ternary */
import { formatPhoneNumberV2 } from '../../helpers/format';

import {
  COPART_SELLER_PORTAL,
  PROPERTY_CLAIM_DATA,
  XACT_PORTAL,
  AUTO_CLAIM_DATA,
} from '../../constants/integration-constants';

const formatTotalLossAddress = (addressData) => {
  const {
    companyName = '',
    yardName = '',
    contactPhone = '',
    yardPhoneNumber = '',
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    state = '',
    zipCode = '',
    type = '',
  } = addressData;
  const name = companyName || yardName;
  const addressPhone = contactPhone || yardPhoneNumber;
  const streetAddress = `${addressLine1} ${addressLine2}`;
  const cityStateZip = `${city}, ${state && state.concat(' ')}${zipCode}`;
  const locationType = type;
  return {
    ...locationType && { locationType: `Vehicle at ${locationType}` },
    name,
    fullAddress: `${streetAddress}, ${cityStateZip}`,
    addressPhone: formatPhoneNumberV2(addressPhone),
  };
};

const buildCopartData = (totalLossData) => {
  const {
    vehicleLocation = {},
    yardLocation = {},
    ownerDetails = {},
    vehicleDetails = {},
    stockNumber = '',
    titleExpressUrl = '',
  } = totalLossData;

  const { firstName = '', lastName = '', phoneNumber = '' } = ownerDetails;
  const {
    year = '',
    color = '',
    model = '',
    make = '',
    licensePlateNumber = '',
  } = vehicleDetails;

  const ownerInfo = {
    fullName: `${firstName} ${lastName}`,
    phoneNumber: formatPhoneNumberV2(phoneNumber),
  };

  const vehicleInfo = {
    makeDetail: `${make} ${model} ${year} ${color} ${licensePlateNumber}`,
  };

  const vehicleLocationInfo = formatTotalLossAddress(vehicleLocation);
  const yardLocationInfo = formatTotalLossAddress(yardLocation);

  const sellerPortalLink = {
    link: {
      url: `${COPART_SELLER_PORTAL}${stockNumber}`,
      text: 'Copart Seller Portal',
    },
  };

  const titleExpressLink = {
    link: {
      url: titleExpressUrl,
      text: 'Title Express Portal',
    },
  };
  const ownerInfoData = Object.entries(ownerDetails).length && ownerInfo;
  const vehicleInfoData = Object.entries(vehicleDetails).length && vehicleInfo;
  const vehicleLocationData = Object.entries(vehicleLocation).length && vehicleLocationInfo;
  const yardLocationData = Object.entries(yardLocation).length && yardLocationInfo;
  const sellerPortalLinkData = stockNumber && sellerPortalLink;
  const titleExpressLinkData = titleExpressUrl && titleExpressLink;
  const defaultCopartDataSet = { header: 'Copart' };
  const copartDataSet = [
    sellerPortalLinkData && {
      title: 'Seller Portal',
      key: 'sellerPortalLink',
      data: sellerPortalLink,
    },
    titleExpressLinkData && {
      title: 'Title Express',
      key: 'titleExpressLink',
      data: titleExpressLink,
    },
    ownerInfoData && {
      title: 'Vehicle Owner',
      key: 'ownerInfo',
      data: ownerInfo,
    },
    vehicleInfoData && {
      title: 'Vehicle Info',
      key: 'vehicleInfo',
      data: vehicleInfoData,
    },
    vehicleLocationData && {
      title: 'Vehicle Location',
      key: 'vehicleLocation',
      data: vehicleLocationData,
    },
    yardLocationData && {
      title: 'Yard Info',
      key: 'yardLocation',
      data: yardLocationData,
    },
  ].filter(Boolean);
  return copartDataSet.length ? [defaultCopartDataSet, ...copartDataSet] : [];
};

const getAssignedParties = ({
  adjuster, isIndependentAdjuster = false, isDeskAdjuster = false,
}) => {
  if (!Object.entries(adjuster).length) return null;
  const { name, email, phoneNumber } = adjuster;
  const title = isDeskAdjuster ? null : isIndependentAdjuster ? 'Independent Adjuster' : 'Staff Adjuster';
  return {
    ...title && { title },
    name,
    email,
    ...phoneNumber && { phoneNumber: formatPhoneNumberV2(phoneNumber) },
  };
};

const buildVeriskData = (veriskData) => {
  const {
    inspectionAddress = '',
    isIndependentAdjuster = false,
    originalAssignmentId,
    adjuster = {},
    deskAdjuster = {},
    endUserName,
    damageType = '',
  } = veriskData;
  const xactPoratlLink = {
    link: {
      url: XACT_PORTAL(originalAssignmentId),
      text: 'XactAnalysis Assignment',
    },
  };
  const assigneeParsed = getAssignedParties({ adjuster, isIndependentAdjuster });
  const deskAdjusterParsed = getAssignedParties({ adjuster: deskAdjuster, isDeskAdjuster: true });
  const defaultVeriskDataSet = { header: 'Verisk' };
  const veriskDataSet = [
    {
      title: 'XactAnalysis',
      key: 'xactAnalysis',
      data: xactPoratlLink,
    },
    endUserName && {
      title: 'Named Insured(s)',
      key: 'endUser',
      data: { endUserName },
    },
    inspectionAddress && {
      title: 'Loss Location',
      key: 'lossLocation',
      data: { inspectionAddress },
    },
    damageType && {
      title: 'Type of Loss',
      key: 'typeOfLoss',
      data: { damageType },
    },
    assigneeParsed && {
      title: 'Assignee',
      key: 'assignee',
      data: assigneeParsed,
    },
    deskAdjusterParsed && {
      title: 'Desk Adjuster',
      key: 'deskAdjuster',
      data: deskAdjusterParsed,
    },
  ].filter(Boolean);

  return veriskDataSet.length ? [defaultVeriskDataSet, ...veriskDataSet] : [];
};

const buildPartnerData = (body) => {
  const { partnerData } = body;
  if (!partnerData?.length) return [];

  return partnerData.flatMap((partner) => {
    const partnerEntries = [];
    const defaultPartnerDataSet = { header: partner.partnerName };
    if (partner.properties) {
      const partnerDataEntries = Object.entries(partner.properties);
      partnerDataEntries.forEach(([k, v]) => {
        if (typeof v === 'object' && v.label && v.value) {
          partnerEntries.push({
            title: v.label,
            key: k,
            data: v.value.includes('http') 
              ? { link: { url: v.value, text: v.value } }
              : { [k]: v.value },
          });
        }
      });
    }
    return partnerEntries.length ? [defaultPartnerDataSet, ...partnerEntries] : [];
  });
};

const buildAutoClaimData = (autoClaimData) => [
  ...buildCopartData(autoClaimData),
  ...buildPartnerData(autoClaimData),
];

const buildPropertyClaimData = (propertyClaimData) => [
  ...buildVeriskData(propertyClaimData),
  ...buildPartnerData(propertyClaimData),
];

const buildTrayData = (data, type) => {
  switch (type) {
    case AUTO_CLAIM_DATA:
      return buildAutoClaimData(data);
    case PROPERTY_CLAIM_DATA:
      return buildPropertyClaimData(data);
    default:
      return null;
  }
};

export default buildTrayData;
