/* eslint-disable no-underscore-dangle */
/* global pendo */
const { isLoadedFromHiMarleyConnect } = require('@app/helpers/platform');

const commonHelpers = require('../common');

const environments = {
  'https://chat.marley.ai': 'Production',
  'https://chat.dr.marley.ai': 'Disaster Recovery',
  'https://chat.uat.marley.ai': 'UAT',
  'https://chat.staging.marley.ai': 'Staging',
  'https://chat.dev.marley.ai': 'Dev',
  'http://localhost:8083': 'Localhost',
  'https://chat.preview1.marley.ai': 'Preview1',
  'https://chat.preview2.marley.ai': 'Preview2',
  'https://chat.preview3.marley.ai': 'Preview3',
  'https://chat.preview4.marley.ai': 'Preview4',
  'https://chat.preview5.marley.ai': 'Preview5',
  'https://chat.chubb.marley.ai': 'Chubb',
  'https://chat.chubb-uat.marley.ai': 'Chubb UAT',
  'https://chat.allstate.marley.ai': 'Allstate',
};

const initialize = (user) => {
  const org = user?.organization;
  const environment = environments[window.location.origin] || 'Unknown';
  try {
    pendo.initialize({
      visitor: {
        id: commonHelpers.getObjectId(user),
        displayName: `${user?.firstName} ${user?.lastName}`,
        email: user?.email,
        uniqueId: user?.oktaUserId,
        roles: user?.roles,
        ...(isLoadedFromHiMarleyConnect() && { isLoadedFromHiMarleyConnect: true }),
      },
      account: {
        id: commonHelpers.getObjectId(org),
        name: org?.name,
        creationDate: org?.createdAt,
        environment,
        permissions: user?.permissions,
      },
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Pendo.js error', err);
  }
};

module.exports = {
  initialize,
};
