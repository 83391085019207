import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import FeedbackItem from './FeedbackItem/feedback-item';
import BinocularsIcon from '../../../../images/icons/binoculars.svg';
import { getCustomerFeedback } from '../../../actions/profile';
import StarRate from '../../StarRate/StarRate';
import './CustomerFeedback.less';

export const countSummaryRate = (items: { score: string | number }[]) => {
  if (items.length > 0) {
    return (
      items.reduce(
        (sum: number, item: { score: string | number }) => sum + +item.score,
        0,
      ) / items.length
    );
  }
  return 0;
};

interface CustomerFeedbackProps {
  profileUserId: string;
  getCustomerFeedback: () => void;
  list: {
    translatedFeedback: string | undefined;
    metricAnswerTimestamp: string | number;
    feedback: string;
    userName: string;
    userId: unknown;
    score: string | number;
  }[];
}

const CustomerFeedback: React.FC<CustomerFeedbackProps> = ({
  profileUserId,
  getCustomerFeedback: getCustomerFeedbackProp,
  list,
}) => {
  useEffect(() => {
    getCustomerFeedbackProp();
  }, [getCustomerFeedbackProp, profileUserId]);

  const rate = countSummaryRate(list).toFixed(1);

  return list.length > 0 ? (
    <div className="customer-feedback-wrap">
      <div className="customer-feedback-title">Customer Feedback</div>
      <div className="customer-feedback-rate-wrap">
        <div className="customer-feedback-rate-num">{rate}</div>
        <StarRate
          className="customer-feedback-rate-stars"
          type="large"
          score={+rate}
        />
      </div>
      <div className="customer-feedback-list">
        {list.map((item, index) => (
          <FeedbackItem
            key={`${item.userId}-${index}` || index}
            templateKey={`${item.userId}-${index}` || index}
            rate={+item.score}
            userName={item.userName || 'Unknown'}
            review={item.feedback || 'no feedback'}
            metricAnswerTimestamp={item.metricAnswerTimestamp}
            translatedReview={item?.translatedFeedback}
          />
        ))}
      </div>
    </div>
  ) : (
    <div className="customer-feedback-wrap">
      <div className="customer-feedback-empty">
        <div className="customer-feedback-empty-icon">
          <BinocularsIcon />
        </div>
        <div className="customer-feedback-empty-header">No Surveys Found</div>
        <div className="customer-feedback-empty-help">
          We&apos;re looking, but no surveys found
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getCustomerFeedback,
};
const mapStateToProps = ({
  profile,
}: {
  profile: { _id: string; customerFeedback: unknown };
}) => ({
  profileUserId: profile?._id,
  list: Array.isArray(profile?.customerFeedback)
    ? profile.customerFeedback.filter(
      (item: { score: number }) => item?.score >= 0,
    )
    : [],
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeedback);
