import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './TagItem.less';
import VerticalEllipsesMenu from '../../../../images/icons/verticalEllipsesMenu.svg';

const TagList = ({ children, displayHorizontalLine }) => (
  <>
    {children.filter((c) => c !== null).length && displayHorizontalLine ? (
      <hr id="tag-item-horizontal-line" />
    ) : null}
    <div className="tag-list-container">{children}</div>
  </>
);

const TagItem = ({
  text,
  handleDismissFunction,
  backgroundColor,
  textColor,
  icon,
  testId,
}) => {
  const [isBubbleOpen, setisBubbleOpen] = useState(false);

  const toggleBubble = () => {
    setisBubbleOpen((isOpen) => !isOpen);
  };

  const handleDismiss = () => {
    handleDismissFunction();
    toggleBubble();
  };

  return (
    <div className="tag-item" data-testId={testId}>
      <div
        className="tag-item-container"
        style={{ background: backgroundColor ?? '' }}
      >
        {icon ?? null}
        {text && <p style={{ color: textColor ?? '' }}>{text}</p>}
        {handleDismissFunction ? (
          <VerticalEllipsesMenu
            data-testid="tag-item-ellipse-btn"
            onClick={toggleBubble}
          />
        ) : null}
      </div>
      {isBubbleOpen && (
        <div className="bubble">
          <button
            type="button"
            className="tag-item-btn"
            onClick={handleDismiss}
            data-testid="tag-item-dismiss-alert-btn"
          >
            Dismiss Alert
          </button>
        </div>
      )}
    </div>
  );
};

TagItem.propTypes = {
  text: PropTypes.string,
  handleDismissFunction: PropTypes.func,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  icon: PropTypes.node,
  testId: PropTypes.string,
};

TagItem.defaultProps = {
  text: null,
  backgroundColor: '',
  textColor: '',
  icon: null,
  testId: '',
  handleDismissFunction: null,
};

TagList.propTypes = {
  children: PropTypes.node.isRequired,
  displayHorizontalLine: PropTypes.bool,
};

TagList.defaultProps = {
  displayHorizontalLine: false,
};

export { TagList, TagItem };
