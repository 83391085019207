/* eslint-disable @typescript-eslint/no-shadow */
import {
  Button,
  Icon,
  IconButton,
  Box,
  useToast,
  Flex,
  Text,
} from '@chakra-ui/react';
import {
  MoreIcon,
  UploadIcon,
  DownloadIcon,
  UserIcon,
  Ghosts,
  AddCircleIcon,
  EnvelopeIcon,
  StopIcon,
  EditIcon,
  SearchBar,
  AddUserIcon,
} from '@himarley/unity';
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { showSnackbar } from '@app/actions/notification';
import {
  getUserProfile,
  getListOfTitles,
  persistUserProperties,
} from '@app/actions/profile';
import { setConfigItem } from '@app/actions/ui';
import {
  createUsers,
  updateOperatorsUniqueIds,
  getOrganizationRoles,
  downloadCSVTemplate,
  getUsersToExport,
  getActiveOperatorCount,
  reactivateOperator,
} from '@app/actions/users';
import { useAlertDialog } from '@app/components/chakra/alert-dialog';
import Menu from '@app/components/chakra/menu';
import Modal, { useModal } from '@app/components/chakra/modal';
import Table from '@app/components/chakra/table';
import GroupDropdown from '@app/components/GroupDropdown/group-dropdown';
import { CHANGE_UNIQUE_ID } from '@app/constants/general';
import { isPasswordlessAuthSession } from '@app/helpers/common';
import { operatorType } from '@app/models/marleyTypes';
import selector from '@app/selectors/usermgmt';
import { type User } from '@app/types/api/user';
import { StateType } from '@app/types/reducer-state';

import BulkUsersUploadModal from './BulkUsersUpload/BulkUsersUploadModal';
import DeleteOperator from './delete-operator';
import InviteUserForm from './invite-user-form';
import OperatorRow from './operator-row';
import DisabledBanner from '../../../elements/Banner/DisabledBanner/DisabledBanner';
import withLoading from '../../../HigherOrderComponents/withLoading';
import Panel from '../../../Panel/Panel';
import ResetPassword from "../../../confirmationModals/ResetPassword/ResetPassword"; // eslint-disable-line
import withLazyLoad from '../../../withLazyLoad/withLazyLoad';

import './UserMgmt.less';

const columns: {
  id: string;
  label: string;
  sort?: { order: string; column: string }[];
}[] = [
  {
    id: 'name-table-format',
    label: 'Name',
    sort: [
      {
        order: 'ASC',
        column: 'profile.firstName',
      },
      {
        order: 'DESC',
        column: 'profile.firstName',
      },
    ],
  },
  {
    id: 'oktaUserId',
    label: 'Unique Id',
  },
  {
    id: 'email',
    label: 'Email',
    sort: [
      {
        order: 'ASC',
        column: 'email',
      },
      {
        order: 'DESC',
        column: 'email',
      },
    ],
  },
  {
    id: 'phone-number',
    label: 'Phone Number',
  },
  {
    id: 'last-login-time',
    label: 'Last active',
    sort: [
      {
        order: 'DESC',
        column: 'lastLoginTime',
      },
      {
        order: 'ASC',
        column: 'lastLoginTime',
      },
    ],
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'groups',
    label: 'Groups',
  },
  {
    id: 'actions',
    label: '',
  },
];

interface UserMgmtProps {
  createUsers: () => void;
  updateOperatorsUniqueIds: () => void;
  increaseDataSet: () => void;
  auth: {
    user: {
      id: string;
      roles: string[];
      _id: string;
      organization: {
        jit: boolean;
      };
    };
  };
  getOrganizationRoles: () => void;
  getUserProfile: (name: string) => void;
  modifyQuery: (
    params: {
      searchText: string;
      groups?: string[];
      includeArchived?: boolean;
    },
    order: string,
    column: string
  ) => void;
  loadedItems: Array<unknown>;
  requestCompleted: boolean;
  showSnackbar: ({ text }: { text: string }) => void;
  getListOfTitles: () => void;
  getUsersToExport: () => void;
  setConfigItem: (type: string) => void;
  activeOperatorCount: number;
  getActiveOperatorCount: (toast: unknown) => void;
  isFetchingActiveOperatorCount: boolean;
  persistUserProperties: ({
    userManageSettings,
  }: {
    userManageSettings: unknown;
  }) => void;
  userManageSettings: { order: string; column: string };
  loadedItem: boolean;
  reactivateOperator: (
    selectedUser: User,
    toast: unknown,
    toastIdRef: unknown
  ) => void;
}

const UserMgmt: React.FC<UserMgmtProps> = ({
  createUsers,
  updateOperatorsUniqueIds,
  increaseDataSet,
  auth,
  getOrganizationRoles,
  getUserProfile,
  modifyQuery,
  loadedItems = [],
  requestCompleted,
  showSnackbar = () => {},
  getListOfTitles = () => {},
  getUsersToExport: getUsersToExportProp,
  setConfigItem,
  activeOperatorCount,
  getActiveOperatorCount: getActiveOperatorCountProp,
  isFetchingActiveOperatorCount,
  persistUserProperties: persistUserPropertiesProp,
  userManageSettings,
  loadedItem,
  reactivateOperator: reactivateOperatorProp,
}) => {
  const [sorting, setSorting] = useState({
    order: 'ASC',
    column: 'profile.firstName',
  });
  const { isModalOpen: isCreateOperatorModalOpen, toggleModal: toggleCreateOperatorModal } = useModal();
  const { isDialogOpen: isDeleteAlertOpen, toggleDialog: toggleDeleteAlert } = useAlertDialog();
  const [userSearchText, setUserSearchText] = useState('');
  const toast = useToast();
  const toastIdRef = useRef<HTMLDivElement | unknown>(null);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [selectedGroups, setSelectedGroups] = useState(new Map());
  const selectedGroupsArray = useMemo(
    () => Array.from(selectedGroups.keys()),
    [selectedGroups],
  );

  useEffect(() => {
    const defaultSort = columns[0].sort
      ? columns[0].sort[0]
      : { order: 'ASC', column: 'profile.firstName' };
    const existingSort = columns
      .find(
        (col) => col.sort
          && col.sort.find(
            (sort) => sort?.order === userManageSettings?.order
              && sort?.column === userManageSettings?.column,
          ),
      )
      ?.sort?.find(
        (sort) => sort?.order === userManageSettings?.order
          && sort?.column === userManageSettings?.column,
      );
    const sort = existingSort?.order && existingSort.column ? existingSort : defaultSort;
    const { column, order } = sort;
    setSorting(sort || {});
    const params: {
      searchText: string;
      groups?: string[];
      includeArchived?: boolean;
    } = { searchText: userSearchText };
    if (selectedGroupsArray.length > 0) {
      params.groups = selectedGroupsArray;
    } else {
      params.groups = undefined;
    }
    params.includeArchived = true;
    modifyQuery(params, order, column);
  }, [modifyQuery, userManageSettings, userSearchText, selectedGroupsArray]);

  const [isBulkUploadModalShown, setIsBulkUploadModalShown] = useState(false);
  const [modalType, setModalType] = useState<string>('');

  const history = useNavigate();
  const handleOpenProfile = useCallback(() => history('/profile'), [history]);

  useEffect(() => {
    getOrganizationRoles();
  }, [getOrganizationRoles]);

  useEffect(() => {
    getListOfTitles();
  }, [getListOfTitles]);

  useEffect(() => {
    if (requestCompleted) {
      toggleCreateOperatorModal(false);
    }
  }, [requestCompleted]);

  useEffect(() => {
    getActiveOperatorCountProp(toast);
  }, [getActiveOperatorCountProp, toast]);

  const passwordlessAuthSession = isPasswordlessAuthSession(auth);
  const nonFederatedUserActions = [
    !passwordlessAuthSession
      ? {
        id: 'resetpassword',
        label: 'Send password reset',
        confirmation: true,
        action: () => {},
        leftIcon: EnvelopeIcon,
      }
      : null,
  ].filter(
    (
      action,
    ): action is {
      id: string;
      label: string;
      confirmation: boolean;
      action: () => void;
      leftIcon: React.ReactNode;
    } => action !== null,
  );

  const adminActionList = useCallback(
    (archived: boolean) => (!archived
      ? [
        {
          id: 'editOperator',
          label: 'Edit Profile',
          confirmation: false,
          action: (id: string) => {
            getUserProfile(id);
            handleOpenProfile();
            setConfigItem('GENERAL_INFO');
          },
          leftIcon: EditIcon,
        },
        {
          id: 'userdelete',
          label: 'Deactivate User',
          confirmation: false,
          leftIcon: StopIcon,
          action: (id: string) => {
            setSelectedUser(
              (loadedItems as User[])?.find((user) => user?.id === id)
                    || undefined,
            );
            toggleDeleteAlert();
          },
        },
        ...nonFederatedUserActions,
      ]
      : [
        {
          id: 'userReactivate',
          label: 'Re-activate User',
          confirmation: false,
          leftIcon: AddUserIcon,
          action: (userId: string) => {
            toastIdRef.current = toast({
              description: 'Re-activating user...',
              status: 'loading',
            });
            const user = (loadedItems as User[])?.find(
              (user) => user?.id === userId,
            );
            if (user) {
              reactivateOperatorProp(user, toast, toastIdRef);
            }
          },
        },
      ]),
    [
      getUserProfile,
      handleOpenProfile,
      loadedItems,
      nonFederatedUserActions,
      reactivateOperatorProp,
      setConfigItem,
      toast,
      toggleDeleteAlert,
    ],
  );

  const getActionsList = useCallback(
    (archived: boolean) => {
      const authUserRoles = auth?.user?.roles;
      if (archived && !authUserRoles?.includes('ADMIN')) {
        return [];
      }
      const actionList = authUserRoles?.includes('ADMIN')
        ? adminActionList(archived)
        : nonFederatedUserActions;
      return actionList;
    },
    [auth?.user?.roles, adminActionList, nonFederatedUserActions],
  );

  const jit = auth?.user?.organization?.jit || false;

  const toggleImportCSVModal = useCallback(
    (val: boolean, type: string) => {
      setIsBulkUploadModalShown(val);
      setModalType(type);
    },
    [setIsBulkUploadModalShown],
  );

  const handleSortSelection = useCallback(
    (sort: { order: string; column: string }) => {
      setSorting(sort || {});
      persistUserPropertiesProp({
        userManageSettings: sort,
      });
    },
    [persistUserPropertiesProp],
  );

  const tableRows = useMemo(
    () => (loadedItems as User[]).map((user: User, index) => (
      <OperatorRow
        key={user?.id}
        itemId={user?.id || ''}
        actions={getActionsList(!!user?.archived)}
        user={user}
        authId={auth?.user?._id}
        rowId={`table-row-${index}`}
      />
    )),
    [auth?.user?._id, getActionsList, loadedItems],
  );

  const renderUserCount = useMemo(() => {
    if (isFetchingActiveOperatorCount === undefined) {
      return;
    }
    if (isFetchingActiveOperatorCount === true) {
      return (<Ghosts numItems={1} />);
    }
    return `${activeOperatorCount} User${
      activeOperatorCount !== 1 ? 's' : ''
    }`;
  }, [isFetchingActiveOperatorCount, activeOperatorCount]);

  const renderPanel = useMemo(
    () => (
      <Panel
        className="user-mgmt-page"
        title={(
          <div className="title-actions-wrap">
            <div>Users</div>
            <div className="user-actions">
              {jit && (
                <DisabledBanner
                  includesLink
                  linkRef="https://himarley.zendesk.com/hc/en-us/articles/1500009297302-Auto-provisioning-Capabilities-and-Limitations-"
                  linkText="Just-In-Time (JIT) Provisioning"
                  bannerText="&nbsp;has been setup for your organization."
                />
              )}
              {!jit && (
                <Button
                  leftIcon={<Icon as={AddCircleIcon} />}
                  onClick={() => toggleCreateOperatorModal(true)}
                >
                  Invite User
                </Button>
              )}
              <Menu
                id="user-actions"
                menuButtonProps={{
                  icon: MoreIcon,
                  as: IconButton,
                  variant: 'ghost',
                }}
                sx={{ fontSize: 'unset !important' }}
                options={[
                  {
                    id: 'upload',
                    label: <Box fontSize="md">Import Users</Box>,
                    leftIcon: UploadIcon,
                    onClick: () => toggleImportCSVModal(true, ''),
                  },
                  {
                    id: 'export',
                    label: <Box fontSize="md">Export Users</Box>,
                    leftIcon: DownloadIcon,
                    onClick: () => getUsersToExportProp(),
                  },
                  {
                    id: 'change-password',
                    label: <Box fontSize="md">Change Unique User IDs</Box>,
                    leftIcon: UserIcon,
                    onClick: () => toggleImportCSVModal(true, CHANGE_UNIQUE_ID),
                  },
                ]}
              />
            </div>
          </div>
        )}
        header={(
          <div className="count-search-bar">
            <p className="active-operator-count" data-testid="active-operator-count">
              {renderUserCount}
            </p>
            <div className="searchSortWrap">
              <div className="searchExportWrap">
                <span>Search</span>
                <SearchBar
                  id="users"
                  placeholder="Search users"
                  value={userSearchText}
                  onValueChange={setUserSearchText}
                  onClear={() => setUserSearchText('')}
                  className="users-search-bar"
                />
              </div>
              <Flex direction="column" minWidth="140px" gap="5px">
                <Text
                  color="gray.500"
                  fontSize="0.875rem"
                  fontWeight="400"
                  width="100%"
                >
                  Groups
                </Text>
                <GroupDropdown onChange={setSelectedGroups} />
              </Flex>
            </div>
          </div>
        )}
        titleFooter={undefined}
        titleNotification={undefined}
        right={undefined}
      >
        <ResetPassword />
        <DeleteOperator
          user={selectedUser}
          toggleAlert={toggleDeleteAlert}
          isOpen={isDeleteAlertOpen}
          toast={toast}
        />

        <Table
          testId="user-mgmt-table"
          columns={columns}
          increaseDataSet={increaseDataSet}
          rows={tableRows}
          onSort={handleSortSelection}
          selectedSort={sorting}
          loadedItem={loadedItem}
          shouldLoadMoreItems={
            tableRows?.length >= 32
            && tableRows?.length < activeOperatorCount
            && !userSearchText
          }
          type=""
          label="users"
        />
      </Panel>
    ),
    [
      jit,
      isFetchingActiveOperatorCount,
      activeOperatorCount,
      userSearchText,
      selectedUser,
      toggleDeleteAlert,
      isDeleteAlertOpen,
      toast,
      increaseDataSet,
      tableRows,
      handleSortSelection,
      sorting,
      loadedItem,
      toggleCreateOperatorModal,
      toggleImportCSVModal,
      getUsersToExportProp,
    ],
  );

  return (
    <>
      {isBulkUploadModalShown && (
        <BulkUsersUploadModal
          type={modalType}
          createUsers={createUsers}
          updateOperatorsUniqueIds={updateOperatorsUniqueIds}
          downloadCSVTemplate={downloadCSVTemplate}
          show={isBulkUploadModalShown}
          onClose={() => toggleImportCSVModal(false, '')}
          showSnackbar={showSnackbar}
          modifyQuery={modifyQuery}
        />
      )}
      {renderPanel}
      <Modal
        testId="create-operator"
        title="Invite User"
        toggleModal={toggleCreateOperatorModal}
        isModalOpen={isCreateOperatorModalOpen}
        hasBodyFooterInChildren
      >
        <InviteUserForm toggleModal={toggleCreateOperatorModal} />
      </Modal>
    </>
  );
};

export const mapStateToProps = (state: StateType) => ({
  ...selector(state),
  type: operatorType,
  sort: state?.ui?.sort?.user,
  auth: state?.auth,
});

export const mapDispatchToProps = {
  createUsers,
  updateOperatorsUniqueIds,
  getOrganizationRoles,
  getUserProfile,
  showSnackbar,
  getUsersToExport,
  getListOfTitles,
  setConfigItem,
  getActiveOperatorCount,
  persistUserProperties,
  reactivateOperator,
};

const UserMgmtWithLoading = withLoading(UserMgmt, { type: operatorType });

const LoadedUsers = withLazyLoad(UserMgmtWithLoading, {
  type: operatorType,
  listLocation: 'users',
  disableInitialFetch: true,
  showAllUsers: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadedUsers);
