import { Skeleton, MenuItem, Menu } from '@chakra-ui/react';
import unionBy from 'lodash/unionBy';
import React from 'react';

import UserRow from '@app/components/chakra/user-row';
import { sortOperatorsByLabel } from '@app/helpers/common';
import { capitalizeEachWord } from '@app/helpers/format';
import { type User } from '@app/types/api/user';

interface UserListProps {
  items: Array<User>;
  onToggle: (id: string) => void;
  isLoading: boolean;
  displayEmail?: boolean;
}

const UserList: React.FC<UserListProps> = ({
  items,
  onToggle,
  isLoading,
  displayEmail = false,
}) => (
  <div>
    {isLoading ? (
      <Skeleton data-testId="user-row-skeleton" />
    ) : (
      <Menu isLazy data-testid="assign-operator-action-menu">
        {sortOperatorsByLabel(unionBy(items, 'id')).map((item: User) => (
          <MenuItem
            data-dropdown-item={`item-${item?.id}`}
            key={item?.id}
            onClick={() => item.id && onToggle(item?.id)}
            value={item?.id}
          >
            <UserRow
              size="md"
              showTitle
              option={{ ...item, title: capitalizeEachWord(item.title || item.role) }}
              key={`ADD_OPERATORS_SEARCH_${item?.id}`}
              showEmail={displayEmail}
            />
          </MenuItem>
        ))}
      </Menu>
    )}
  </div>
);

export default UserList;
