import { IconButton, Icon, Flex } from '@chakra-ui/react';
import { Tabs, Tab, CloseIcon } from '@himarley/unity';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  jobPutRequest as jobPutRequestAction,
  createNote as createNoteAction,
  getNotes as getNotesAction,
  deleteNote as deleteNoteAction,
} from '@app/actions/job';
import {
  setRightSidebarTab as setRightSidebarTabAction,
} from '@app/actions/ui';
import {
  updateEndUser as updateUserAction,
} from '@app/actions/users';
import InboxError from '@app/components/ChatsContainer/ChatInbox/inbox-error';
import CreateCaseButton from '@app/components/CreateCase/create-case-button';
import ErrorBoundary from '@app/components/ErrorBoundary/ErrorBoundary';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import {
  DISABLE_CREATE_CASE,
  CREATE_CASE_FROM_CHATS_PAGE,
} from '@app/constants/permissions';
import {
  USER_MANAGE_TAB,
  USER_DETAILS_TAB,
} from '@app/constants/rightBarState';
import { useCheckPermissions } from '@app/helpers/common';
import { StateType } from '@app/types/reducer-state';

import ChatActionsMenu from './chat-actions-menu';
import InCurrentConversation from './InCurrentConversation/InCurrentConversation';
import selector from './selector';
import UserDetails from './UserDetails/UserDetails';

import './RightBar.less';

const mapStateToProps = (state: StateType) => selector(state);

const mapDispatchToProps = {
  updateUser: updateUserAction,
  setRightSidebarTab: setRightSidebarTabAction,
  jobPutRequest: jobPutRequestAction,
  createNote: createNoteAction,
  getNotes: getNotesAction,
  deleteNote: deleteNoteAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type RightBarProps = PropsFromRedux & {
  authId: string;
  rightBar: string;
  activeCase: {
    id: string;
  };
  customer: {
    role: string;
  };
  notes: unknown;
  caseTypes: { id: string }[];
  mediaMessages: { providerId: string; fileUrl: string; }[];
  caseData: {
    entities: Record<string, unknown>;
  };
  isVisible: boolean;
  toggleRightBar: (isVisible: boolean) => void;
};

const RightBar: React.FC<RightBarProps> = ({
  authId,
  rightBar = USER_MANAGE_TAB,
  activeCase = {},
  customer = {},
  createNote,
  getNotes,
  deleteNote,
  notes,
  caseTypes,
  jobPutRequest,
  mediaMessages = [],
  caseData = {},
  setRightSidebarTab,
  updateUser,
  isVisible,
  toggleRightBar,
}) => {
  const tabIndex = rightBar !== USER_MANAGE_TAB && rightBar !== 'SIDEBAR'
    ? USER_DETAILS_TAB
    : rightBar;
  const isCreateCaseEnabled = !useCheckPermissions([DISABLE_CREATE_CASE]);
  const isCreateCaseButtonOnChatsEnabled = useCheckPermissions([
    CREATE_CASE_FROM_CHATS_PAGE,
  ]);

  const handleClose = () => {
    toggleRightBar(false);
  };

  const isSmallScreen = useScreenSize();

  return (
    <div className={`right-bar ${isVisible ? 'visible' : ''}`}>
      <ErrorBoundary errorMessage={(
        <InboxError
          text={(
            <>
              <span>Unable to load case details.</span>
              <span>Please try again later or refresh.</span>
            </>
          )}
          className="rightBarWrap"
        />
      )}
      >
        <Flex justify="space-between" align="center" className="right-bar-filler">
          {isSmallScreen && (
            <IconButton
              variant="ghost"
              icon={<Icon as={CloseIcon} />}
              aria-label="Close"
              onClick={handleClose}
            />
          )}
          {activeCase?.id && <ChatActionsMenu /> }
          {isCreateCaseEnabled && isCreateCaseButtonOnChatsEnabled && (
          <CreateCaseButton size="sm" />
          )}
        </Flex>
        {activeCase.id
          ? (
            <Tabs
              defaultActiveKey={tabIndex}
              justify
              type="content"
              onSelect={setRightSidebarTab}
            >
              <Tab title="Details" eventKey={USER_DETAILS_TAB}>
                <UserDetails
                  authId={authId}
                  createNote={createNote}
                  getNotes={getNotes}
                  deleteNote={deleteNote}
                  notes={notes}
                  jobPutRequest={jobPutRequest}
                  activeCase={activeCase}
                  customer={customer}
                  updateUser={updateUser}
                  caseTypes={caseTypes}
                  mediaMessages={mediaMessages}
                  caseData={caseData.entities}
                />
              </Tab>
              <Tab title="Manage" eventKey={USER_MANAGE_TAB}>
                <InCurrentConversation />
              </Tab>
            </Tabs>
          )
          : null}
      </ErrorBoundary>
    </div>
  );
};

export { RightBar as RightBarDisconnected };

export default connector(RightBar);
