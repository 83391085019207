/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-underscore-dangle */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import Panel from '../../../Panel/Panel';
import LinesOfBusiness from './lines-of-business';
import LoBOptInMessage from './lob-opt-in-message';
import LoadingIcon from '../../../../../images/icons/loading.svg';
import { getSelectedLobFromPath } from './helpers';

import styles from './LinesOfBusiness.module.less';

const LinesOfBusinessContainer = () => {
  const organization = useSelector((state) => state?.organizations);
  const isGettingFullOrganizationData = useSelector(
    (state) => state?.organizations?.isGettingFullOrganizationData,
  );
  const [selectedLoB, setSelectedLob] = useState(null);
  const history = useNavigate();
  const location = useLocation();

  const handleClickOutsideComponent = (e) => {
    if (
      e?.target?.type === 'button'
      && e?.target?.textContent === 'Lines of Business'
    ) {
      setSelectedLob(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideComponent, true);

    return () => document.removeEventListener('click', handleClickOutsideComponent, true);
  }, []);

  // navigate to lob based on url params
  useEffect(() => {
    if (location?.pathname && organization?.linesOfBusiness) {
      const lob = getSelectedLobFromPath(
        location?.pathname,
        organization?.linesOfBusiness,
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      lob && setSelectedLob(lob);
    }
  }, [location, setSelectedLob, organization?.linesOfBusiness]);

  const isWelcomeFlowRefactorEnabled = process.env.WELCOME_FLOW_REFACTOR_ENABLED;

  const handleSelectedLob = (lob) => {
    let path = 'optInMessages';
    if (isWelcomeFlowRefactorEnabled) path = 'welcomeMessages';

    setSelectedLob(lob);
    if (lob?._id) {
      history(`/usermgmt/linesOfBusiness/${lob?._id}/${path}`);
      setSelectedLob(lob);
    } else {
      // nav back if no lob provided
      history('/usermgmt/linesOfBusiness');
    }
  };

  return (
    <Panel
      className={`${selectedLoB ? styles.lobSelected : ''} group-mgmt-page`}
      title={
        selectedLoB || isGettingFullOrganizationData ? '' : 'Lines of Business'
      }
    >
      {selectedLoB ? (
        <LoBOptInMessage lob={selectedLoB} setSelectedLob={handleSelectedLob} />
      ) : isGettingFullOrganizationData ? (
        <div className="loading-notification">
          <div className="loading none-found-icon">
            <LoadingIcon />
          </div>
          <div>One sec, we're loading your Brands...</div>
        </div>
      ) : (
        <LinesOfBusiness
          organization={organization}
          setSelectedLob={handleSelectedLob}
        />
      )}
    </Panel>
  );
};

export default LinesOfBusinessContainer;
