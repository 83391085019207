import { get } from 'lodash';
import { VIRUS_SCAN_STATUS } from '@app/components/Media/constants';
import {
  MODIFY_CHAT_HEIGHT,
  OPEN_EDIT_PROFILE,
  CLOSE_EDIT_PROFILE,
  OPEN_CHANGE_PASSWORD,
  CLEAR_NOTIFICATION,
  MUTE_AUDIO,
  AUDIO_NOTIFICATION,
  CLEAR_QUEUE_NOTIFICATION,
  OPEN_TEMPLATES,
  OPEN_CLAIMS_ENTRY,
  CLOSE_JOB_TAB,
  FILTER_ITEMS,
  OPEN_INACTIVITY_ALERT,
  CLOSE_INACTIVITY_ALERT,
  OPEN_PROFILE_CONFIG,
  CLOSE_PROFILE_CONFIG,
  TOGGLE_CONFIRMATION,
  SET_CONFIG_ITEM,
  SET_TEMPLATE_TRAY_IS_OPEN,
  OPEN_DASHBOARD_VIEW,
  SET_RIGHT_SIDEBAR_TAB,
  SET_LEFT_BAR_BLUE_DOT,
  OPEN_MEDIA_MODAL,
  CLOSE_MEDIA_MODAL,
  UPDATE_MEDIA_MODAL,
  REQUEST_GET_JOBS_ERROR,
} from '../constants/actions';
import { ANALYTICS_TAB_DOWNLOAD } from '../constants/uiState';
import { loadReports } from './analytics';
import {
  saveCookies,
  saveCustomInboxCookie,
  saveAnalyticsCookie,
  saveBranding,
  getCustomInboxCookie,
} from '../helpers/cookies';

export const saveCookie = (params) => {
  saveCookies(params);
};

export const setCustomParamsViaCookie = () => {
  const params = getCustomInboxCookie();
  return {
    type: 'SET_CUSTOM_INBOX_PARAMS',
    payload: params,
  };
};

export const setCustomInboxParams = (params) => {
  saveCustomInboxCookie(params);
  return {
    type: 'SET_CUSTOM_INBOX_PARAMS',
    payload: params,
  };
};

export const setBranding = (params) => {
  saveBranding(params);
  return {
    type: 'SET_BRANDING',
    payload: params,
  };
};

export const setAnalyticsFilters = (params) => {
  saveAnalyticsCookie(params);
  return {
    type: 'SET_ANALYTICS_FILTERS',
    payload: params,
  };
};

export const openDashboardView = (view) => ({
  type: OPEN_DASHBOARD_VIEW,
  payload: view,
});

export const setRightSidebarTab = (view) => ({
  type: SET_RIGHT_SIDEBAR_TAB,
  payload: view,
});

export const setTemplateTrayIsOpen = (boolean, context) => ({
  type: SET_TEMPLATE_TRAY_IS_OPEN,
  payload: {
    boolean,
    ...(context && { context }),
  },
});

export const setAnalyticsTab = (view) => (dispatch, getStore) => {
  if (view === ANALYTICS_TAB_DOWNLOAD) {
    loadReports()(dispatch, getStore);
  }
  dispatch({
    type: 'SET_ANALYTICS_TAB',
    payload: view,
  });
};

export const closeForm = (type) => ({
  type: `CLOSE_${type.reducerCase()}_FORM`,
});

export const clearFilter = (id) => ({ type: 'CLEAR_FILTER', payload: id });
export const increaseOffset = (id, offset) => ({
  type: 'INCREASE_OFFSET',
  payload: { id, offset },
});

export const openProfileConfig = () => ({ type: OPEN_PROFILE_CONFIG });

export const modifyChatHeight = (level) => ({
  type: MODIFY_CHAT_HEIGHT,
  payload: level,
});
export const openEditProfile = () => ({ type: OPEN_EDIT_PROFILE });
export const closeEditProfile = () => ({ type: CLOSE_EDIT_PROFILE });
export const openChangePassword = () => ({ type: OPEN_CHANGE_PASSWORD });
export const closeProfileConfig = () => ({ type: CLOSE_PROFILE_CONFIG });
export const openTemplates = () => ({ type: OPEN_TEMPLATES });
export const openEditClaim = () => ({ type: OPEN_CLAIMS_ENTRY });
export const clearQueueNotification = () => ({
  type: CLEAR_QUEUE_NOTIFICATION,
});
export const closeTab = ({ jobId }) => ({
  type: CLOSE_JOB_TAB,
  payload: { jobId },
});
export const clearNotification = () => ({ type: CLEAR_NOTIFICATION });
export const audioNotification = () => ({ type: AUDIO_NOTIFICATION });
export const openInactivityAlert = () => ({ type: OPEN_INACTIVITY_ALERT });
// eslint-disable-next-line max-len
export const closeInactivityAlert = (shouldLogout) => ({
  type: CLOSE_INACTIVITY_ALERT,
  payload: { shouldLogout },
});
export const setConfigItem = (configId) => ({
  type: SET_CONFIG_ITEM,
  payload: configId,
});

export const muteAudio = (mute) => (dispatch) => {
  localStorage.setItem('muted', mute);
  dispatch({ type: MUTE_AUDIO, payload: mute });
};

export const toggleConfirmation = (payload) => ({
  type: TOGGLE_CONFIRMATION,
  payload,
});

export const sortItems = (type, column, order) => ({
  type: 'SORT_ITEMS',
  payload: { type, column, order },
});

export const filterItems = (type, filter) => ({
  type: FILTER_ITEMS,
  payload: { type, filter },
});

export const setLeftBarBlueDot = (target) => (dispatch, getState) => {
  const dash = get(getState(), 'ui.dashboard');
  if (target === dash) {
    return null;
  }
  dispatch({
    type: SET_LEFT_BAR_BLUE_DOT,
    payload: {
      target,
      set: true,
    },
  });
};

export const openMediaModal = (fileUrl, mediaParam) => {
  const media = (mediaParam?.filter(
    (m) => !m?.isImageRedacted && m?.virusScanStatus === VIRUS_SCAN_STATUS.CLEAN,
  ) || [])?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
  if (!media?.length) {
    return null;
  }
  const mediaIndex = media.findIndex(
    (mediaItem) => mediaItem.fileUrl === fileUrl,
  );
  if (mediaIndex === null) {
    console.warn('cant find file url at', fileUrl);
    return null;
  }
  return {
    type: OPEN_MEDIA_MODAL,
    payload: {
      mediaIndex,
    },
  };
};

export const updateMediaModalIndex = (mediaIndex) => (dispatch) => {
  dispatch({
    type: UPDATE_MEDIA_MODAL,
    payload: {
      mediaIndex,
    },
  });
};

export const closeMediaModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_MEDIA_MODAL,
  });
};

export const unsetLeftBarBlueDot = (target) => ({
  type: SET_LEFT_BAR_BLUE_DOT,
  payload: {
    set: false,
    target,
  },
});

export const setGetJobsApiError = (isError) => (dispatch) => {
  dispatch({
    type: REQUEST_GET_JOBS_ERROR,
    payload: {
      isError,
    },
  });
};
