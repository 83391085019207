import {
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  Box,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { DELETE_WORKFLOW_SETTINGS } from '@app/constants/endpoints';
import { deleteData } from '@app/hooks/react-query-helpers';

interface DeleteWorkflowSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  workflowName: string;
  refetchAllData: () => void;
}

const DeleteWorkflowSettingsModal: React.FC<DeleteWorkflowSettingsModalProps> = ({
  isOpen,
  onClose,
  workflowName,
  refetchAllData,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const resetModalAndClose = () => {
    setInputValue('');
    setError('');
    onClose();
  };

  const handleDelete = async () => {
    if (inputValue !== workflowName) {
      setError('Workflow name does not match');
      return;
    }

    try {
      await deleteData(DELETE_WORKFLOW_SETTINGS(workflowName));
      refetchAllData();
      resetModalAndClose();
    } catch (err) {
      setError('Error deleting workflow settings');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={resetModalAndClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm delete</ModalHeader>

        <ModalBody>
          <Box bg="blue.50" p={4} borderRadius="md" mb={4}>
            <Text color="blue.700">Enter workflow name to delete</Text>
            <Text color="blue.700" fontWeight="bold">
              {workflowName}
            </Text>
          </Box>

          <FormControl isInvalid={!!error}>
            <Input
              placeholder="Enter workflow name to confirm"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                setError('');
              }}
            />
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup spacing={3}>
            <Button variant="ghost" onClick={resetModalAndClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteWorkflowSettingsModal;
