import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from 'react-bootstrap';
import { userType } from '../../../../../../models/marleyTypes';
import { updateItem } from '../../../../../../actions/common';
import EditIcon from '../../../../../icons/EditIcon';
import GroupIcon from '../../../../../icons/GroupIcon';
import UserIcon from '../../../../../icons/UserIcon';
import GarbageIcon from '../../../../../icons/GarbageIcon';
import Button from '../../../../../Form/Button/Button';
import IconButton from '../../../../../elements/buttons/IconButton/IconButton';
import { SortTable } from '../../../../../elements/table/SortTable/SortTable';
import TableHeader from '../../../../../elements/table/SortTable/TableHeader/TableHeader';
import { TableRow } from '../../../../../elements/table/TableRow/TableRow';

import './ListingPanel.less';

const groupColumns = [
  {
    id: 'name',
    label: 'Members',
    location: 'name',
    format: ({ value, isGroup }) => (isGroup ? (
      <div className="membersGroupWrap">
        <GroupIcon />
        {value}
      </div>
    ) : (
      <div className="membersGroupWrap">
        <UserIcon />
        {value}
      </div>
    )),
  },
  {
    id: 'roles',
    label: 'Roles',
    location: 'roles',
    format: ({ value, isGroup }) => (isGroup ? '(Group)' : value && value.length > 0 && value.join(', ')),
  },
  {
    id: 'groups',
    label: 'Other Groups',
    location: 'otherGroups',
    format: ({ value }) => {
      if (value && value.length) {
        return `${value[0]}${
          value.length > 1 ? ` and +${value.length - 1}` : ''
        }`;
      }
    },
  },
];

const headerColumns = groupColumns.map((col) => (
  <TableHeader key={col.id || col.label} {...col} />
));

const ListingPanel = ({
  updateItem: updateItemProp,
  groupId,
  members,
  openEditModal,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openDeleteConfirmation = () => {
    setShowModal(true);
  };

  const confirmDelete = () => {
    updateItemProp('GROUP', { archived: true }, groupId, 'group');
    setShowModal(false);
  };

  return (
    <div className="listing-panel">
      <Modal
        className="confirmation-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete this group?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          All deletes are permanent and you won&apos;t be able to retrieve the
          group
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="black"
            data-test="group-delete-button"
            onClick={confirmDelete}
          >
            Delete Group
          </Button>
        </Modal.Footer>
      </Modal>

      <SortTable
        containerHeight="auto"
        type={userType}
        label="members"
        columns={headerColumns}
        elementHeight={5}
        rows={
          members
          && members.map((c) => (
            <TableRow key={c?.id} columns={groupColumns} item={c} />
          ))
        }
        itemSize={35}
      />
      <div className="modify-group-box">
        <div
          className="modify-group-button"
          onClick={openEditModal}
          onKeyDown={openEditModal}
          role="button"
          tabIndex={0}
        >
          Modify Group
          <IconButton
            data-testid="editModalButton"
            onClick={openEditModal}
            onKeyDown={openEditModal}
          >
            <EditIcon className="edit" />
          </IconButton>
        </div>
        <IconButton
          tooltip="Delete Group"
          onClick={openDeleteConfirmation}
          data-test="delete-group-button"
          className="delete-group-button"
        >
          <GarbageIcon className="delete" />
        </IconButton>
      </div>
    </div>
  );
};

ListingPanel.propTypes = {
  members: PropTypes.arrayOf(Object).isRequired,
  updateItem: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

ListingPanel.defaultProps = {};

export default connect(null, { updateItem })(ListingPanel);
