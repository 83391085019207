import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createCaseWithUser } from '@app/actions/common';
import { jobPutRequest } from '@app/actions/job';
import { cleanPhone } from '@app/helpers/format';
import { CaseTypeEnum, CaseTypeMetadataEnum, IFormInput } from '@app/types/create-case';
import { StateType } from '@app/types/reducer-state';

import { META_DATA_MAPPING } from '../constants';

export const useCaseForm = (handleClose: () => void) => {
  const dispatch = useDispatch();
  const linesOfBusiness = useSelector(
    (state: StateType) => state.organizations?.linesOfBusiness ?? [],
  );

  const buildMetaData = useCallback(<T extends CaseTypeEnum>(
    data: IFormInput,
    caseType: T,
  ) => {
    const metaData: Record<string, string> = {};
    const caseTypeMapping = META_DATA_MAPPING[caseType] as Partial<
    Record<keyof IFormInput, string>
    >;

    if (caseTypeMapping) {
      Object.entries(caseTypeMapping).forEach(([key, metadataKey]) => {
        const value = data[key as keyof IFormInput];
        if (metadataKey && value) {
          metaData[metadataKey] = value;
        }
      });
    }

    return metaData;
  }, []);

  const onSubmit = useCallback(
    (data: IFormInput, isEditMode = false, caseId?: string, caseData?: any) => {
      const metaData = buildMetaData(data, data.caseType);

      if (isEditMode && caseId) {
        dispatch(jobPutRequest({
          id: caseId,
          caseTypeId: CaseTypeMetadataEnum[data.caseType],
          metadataReferenceId: caseData?.metadataReferenceId,
          metaData,
          operatorId: data.assignedTo,
          privacy: data.caseVisibility,
          lineOfBusiness: linesOfBusiness.find((lob) => lob._id === data.lineOfBusiness),
        }));
      } else {
        dispatch(createCaseWithUser({
          type: 'CASE',
          userData: {
            id: data.id,
            phoneNumber: cleanPhone(data.phoneNumber),
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            contactCaseRole: data.contactCaseRole,
            languagePreference: data.contactLanguage,
            zipCode: data.contactZipCode,
          },
          data: {
            caseTypeId: CaseTypeMetadataEnum[data.caseType],
            metaData,
            operatorId: data.assignedTo,
            privacy: data.caseVisibility,
            lineOfBusiness: linesOfBusiness.find((lob) => lob._id === data.lineOfBusiness),
            roleType: data.contactCaseRole,
          },
          userIdName: 'customerId',
          overrideType: data.caseType,
        }));
      }
      handleClose();
    },
    [buildMetaData, dispatch, linesOfBusiness, handleClose],
  );

  return { onSubmit };
};

export default useCaseForm;
