/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import moment from 'moment';
import User from './user';
import { EXPECTED_RESPONSE_TIME } from '../constants/config';
import { formatDate } from '../helpers/datetime';

const stringFormat = (string) => string;
const booleanFormat = (boolean) => boolean === 'true';
const currencyFormat = (currency) => `$${currency}`;

export const formatJobInfo = (info) => {
  const nonNullInfo = info.filter((i) => i.label && i.value);
  const newInfo = nonNullInfo.map((data) => {
    const { label, value, type } = data;
    let format;
    switch (type) {
      case 'boolean':
        format = booleanFormat;
        break;
      case 'string':
        format = stringFormat;
        break;
      case 'currency':
        format = currencyFormat;
        break;
      default:
        format = stringFormat;
        break;
    }
    const returnValue = value.map((v) => format(v));
    return { label, value: returnValue, type };
  });

  return newInfo;
};

export const getLatestActivity = (job) => {
  let raw;
  if (job.lastMessage) {
    raw = job.lastMessage.sentAt;
  } else {
    raw = job.createdAt;
  }
  return {
    raw: moment(new Date(raw)).unix(),
    formatted: raw && formatDate(raw, 'human'),
  };
};

class Job {
  constructor(job, userId = false) {
    _.keys(job).forEach((key) => {
      this[key] = job[key];
    });

    this.raw = job;
    this.id = job._id;

    this.companyName = job.companyName;
    this.reference = job.reference;
    this.type = _.get(job, 'jobType', job.type);

    if (job.customerId) {
      this.customer = new User(job.customerId);
      if (this.customer.role === 'operator') {
        this.type = 'operatorchat';
      }
    }

    this.queueId = _.get(job, 'queue.id');
    this.queueName = _.get(job, 'queue.name');

    this.createdAt = job.createdAt;

    this.operatorIds = job.operatorIds || [];
    if (typeof job.operatorId === 'string') {
      this.assignedOperator = new User({ _id: job.operatorId });
    } else if (job.operatorId) {
      this.assignedOperator = new User(job.operatorId);
    } else {
      this.assignedOperator = {};
    }
    if (job.operatorId) {
      if (typeof job.operatorId === 'string') {
        this.operatorIds.push(job.operatorId);
      } else {
        this.operatorIds.push(_.get(job, 'operatorId._id'));
      }
    }
    this.operatorId = _.get(job, 'operatorId._id', 'unassigned');

    this.secondaryOperatorIds = job.secondaryOperatorIds || [];

    this.usersViewedLastMessage = job.usersViewedLastMessage;

    if (job.creatorId) {
      this.creatorId = new User(job.creatorId);
    }

    this.operator = '';
    if (_.get(job, 'operatorId.profile.firstName')) {
      this.operator = `${_.get(job, 'operatorId.profile.firstName')} ${_.get(job, 'operatorId.profile.lastName')}`;
      this.recipientName = _.get(job, 'operatorId._id') === userId
        ? _.get(this.customer, 'name') : this.operator;
    }

    this.operatorSortValue = '';
    if (_.get(job, 'operatorId.profile.lastName')) {
      this.operatorSortValue = _.get(job, 'operatorId.profile.lastName');
    }

    this.referenceId = job.referenceId;
    this.displayId = this.referenceId || this.reference || this.id;
    this.jobInfo = job.chatDataDisplay && formatJobInfo(JSON.parse(job.chatDataDisplay));
    // TODO: vanely we need to switch this to be just this.metaData rather than this.metadata
    // I didn't do this originally because this.metaData mapped to the chatDataDisplay.
    // during the chat data display rework ticket this can be changed
    this.metadata = job.metaData;
    this.lastMessage = job.lastMessage;
    this.inTranslationMode = job.inTranslationMode;
    this.languagePreference = job.languagePreference;
    this.marleyNumber = job.marleyNumber;
    this.branding = job.branding;
    this._completedAt = job.completedAt || undefined;
    this.isOpen = this._completedAt === undefined || this._completedAt === '';
    this.isClosed = !this.isOpen;

    this.expectedResponseTime = EXPECTED_RESPONSE_TIME;
    this.expectedResponse = false;
    if (job.lastMessage && userId !== undefined) {
      this.expectedResponse = userId !== job.lastMessage.authorId;
    }
    this.calculateTimeUntilDue();
    this.isNewJob = job.isNewJob;
    this.type = job.type;
    this.toMe = false;
    this.isFulfilledByAI = job.isFulfilledByAI || false;
    this.latestActivity = getLatestActivity(this);
    this.type = job.type;
    this.needsAttentionMessageCount = job.needsAttentionMessageCount || 0;
    this.showFailedMessage = job.showFailedMessage;
  }

  calculateTimeUntilDue() {
    if (_.get(this, 'lastMessage.sentAt')) {
      const due = moment(new Date(this.lastMessage.sentAt)).add(this.expectedResponseTime, 'seconds');
      this.timeLeft = moment.duration(due.diff());
    }
  }

  close(timestamp) {
    this._completedAt = timestamp;
    this.isClosed = true;
    this.isOpen = false;
  }

  open() {
    this._completedAt = undefined;
    this.isClosed = false;
    this.isOpen = true;
  }
}

export default Job;
