import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import globalMediaCacheFactory from '@app/hooks/global-file-cache';
import { redactImage, viewRedactedImage } from '@app/actions/job';
import MediaContainer from '@app/components/Media/media-container';
import { REDACTED_IMAGE } from '@app/constants/general';

import RedactionModal from './RedactionModal';

const RedactImageModalContainer = ({
  isRedactImageModalShown,
  isConfirmViewRedactedImageModalShown,
  fileUrl,
  setIsRedactImageModalShown,
  setIsConfirmViewRedactedImageModalShown,
  mediaItem,
  isViewRedactedImageModalShown,
  setIsViewRedactedImageModalShown,
}) => {
  const dispatch = useDispatch();
  const { messageId } = mediaItem || {};
  const { authUser } = useSelector((state) => ({
    authUser: state?.auth?.user,
  }));

  const fileName = useMemo(() => fileUrl?.split('/')?.pop(), [fileUrl]);

  const hideViewRedactionImageModal = useCallback(
    async (viewRedactedFileUrl) => {
      const globalFileCache = await globalMediaCacheFactory();
      await globalFileCache?.deleteItem(viewRedactedFileUrl);
      setIsViewRedactedImageModalShown(false);
    },
    [setIsViewRedactedImageModalShown],
  );

  const handleViewRedactedImage = useCallback(() => {
    setIsConfirmViewRedactedImageModalShown(false);
    dispatch(viewRedactedImage(messageId, authUser));
    setIsViewRedactedImageModalShown(true);
    setIsConfirmViewRedactedImageModalShown(false);
    setIsRedactImageModalShown(false);
  }, [
    authUser,
    dispatch,
    messageId,
    setIsConfirmViewRedactedImageModalShown,
    setIsRedactImageModalShown,
    setIsViewRedactedImageModalShown,
  ]);

  const handleRedactImage = useCallback(async () => {
    const globalFileCache = await globalMediaCacheFactory();
    await globalFileCache?.deleteItem(fileUrl);
    const redactionData = [{
      Type: REDACTED_IMAGE,
      createdBy: authUser._id || authUser.id,
      operatorName: `${authUser.firstName} ${authUser.lastName}`,
      createdAt: new Date(),
    }];
    dispatch(redactImage(messageId, fileName, fileUrl, redactionData));
    setIsRedactImageModalShown(false);
  }, [dispatch, fileName, fileUrl, messageId, setIsRedactImageModalShown, authUser]);

  if (isRedactImageModalShown) {
    return (
      <RedactionModal
        title="Are you sure?"
        description="Redacting this image will hide it for all users. Only users with admin permissions will be able to view it again."
        confirmButtonText="Redact Image"
        onConfirm={handleRedactImage}
        show={isRedactImageModalShown}
        hideModal={() => setIsRedactImageModalShown(false)}
      />
    );
  }
  if (isConfirmViewRedactedImageModalShown) {
    return (
      <RedactionModal
        title="Please Read!"
        description={(
          <>
            By viewing this redacted information, you accept the responsibility
            and understand the sensitivity of this redacted information.
            <div>
              Only view if it is necessary. Your name will be documented.
            </div>
          </>
        )}
        confirmButtonText="I Understand"
        onConfirm={handleViewRedactedImage}
        show={isConfirmViewRedactedImageModalShown}
        hideModal={() => setIsConfirmViewRedactedImageModalShown(false)}
      />
    );
  }
  if (isViewRedactedImageModalShown) {
    const viewRedactedFileUrl = `${fileUrl}?serveRedactedImage=true&messageId=${messageId}`;
    const redactedMediaItem = { ...mediaItem, fileUrl: viewRedactedFileUrl };
    return (
      <RedactionModal
        title="Viewing Redacted Image"
        description={(
          <MediaContainer
            configuration={{
              mode: 'fixed',
              fixedHeight: 250,
              fixedWidth: 250,
            }}
            onExpand={() => {}}
            mediaItem={redactedMediaItem}
            isViewingRedactedImage
          />
        )}
        show={isViewRedactedImageModalShown}
        hideModal={() => hideViewRedactionImageModal(viewRedactedFileUrl)}
        isImage
        cancelBtnText="Close"
      />
    );
  }
  return null;
};

RedactImageModalContainer.propTypes = {
  isRedactImageModalShown: PropTypes.bool.isRequired,
  isConfirmViewRedactedImageModalShown: PropTypes.bool.isRequired,
  fileUrl: PropTypes.string.isRequired,
  setIsRedactImageModalShown: PropTypes.func.isRequired,
  setIsConfirmViewRedactedImageModalShown: PropTypes.func.isRequired,
  mediaItem: PropTypes.shape({}).isRequired,
  isViewRedactedImageModalShown: PropTypes.bool.isRequired,
  setIsViewRedactedImageModalShown: PropTypes.func.isRequired,
};

export default RedactImageModalContainer;
