import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeApiRequest } from '@app/actions/api/api';
import TabRouter from '@app/components/chakra/tab-router';
import {
  ADMIN_NOTIFICATION_CONFIG,
  FEATURE_FLAG_CCC_FIRST_LOOK,
  FEATURE_FLAG_TOTAL_LOSS_ASSIST,
  FEATURE_FLAG_VERISK,
  FNOL_UI,
} from '@app/constants/permissions';
import usePermissionVerify from '@app/hooks/permission-verify';
import type { StateType } from '@app/types/reducer-state';

import AdminNotificationConfigurations from './AdminNotificationConfigurations/AdminNotificationConfigurations';
import CfnolSettings from './CFNOLSettings/cfnol-settings';
import CompanyHierarchy from './CompanyHierarchy/CompanyHierarchy';
import IntegrationSettingsContainer from './IntegrationSettings/integration-settings-container';
import OrgSettings from './OrgSettings/OrgSettings';
import WelcomeFlowSettings from './WelcomeFlowSettings/welcome-flow-settings';
import './Settings.less';

const NAV_EVENT_KEYS: { [key: string]: string } = {
  orgSettings: 'orgSettings',
  optInMessages: 'optInMessages',
  welcomeMessages: 'welcomeMessages',
  companyHierarchy: 'companyHierarchy',
  adminNotificationConfigurations: 'adminNotificationConfigurations',
  integrationSettings: 'integrationSettings',
  cfnol: 'cfnol',
};

const Settings = () => {
  const dispatch = useDispatch();

  const { organization } = useSelector(({ organizations }: StateType) => ({
    organization: organizations,
  }));
  useEffect(() => {
    dispatch(makeApiRequest('GET_ORGANIZATIONS_AUTOREPLIES', [organization?.name?.toUpperCase()]));
  }, [dispatch, organization?.name]);

  const {
    [ADMIN_NOTIFICATION_CONFIG]: hasNotificationSettingsAccess,
    [FEATURE_FLAG_TOTAL_LOSS_ASSIST]: hasTLAAccess,
    [FEATURE_FLAG_VERISK]: hasVeriskAccess,
    [FEATURE_FLAG_CCC_FIRST_LOOK]: hasCCCAccess,
    [FNOL_UI]: hasFNOLUIAccess,
  } = usePermissionVerify(
    [
      ADMIN_NOTIFICATION_CONFIG,
      FEATURE_FLAG_TOTAL_LOSS_ASSIST,
      FEATURE_FLAG_VERISK,
      FEATURE_FLAG_CCC_FIRST_LOOK,
      FNOL_UI,
    ],
  );

  const hasIntegrationsAccess = hasVeriskAccess || hasTLAAccess || hasCCCAccess;

  const tabList = useMemo(() => {
    const list = [
      {
        id: NAV_EVENT_KEYS.orgSettings,
        title: 'Org. Settings',
        component: <OrgSettings organization={organization} />,
      },
      {
        id: NAV_EVENT_KEYS.companyHierarchy,
        title: 'Company Hierarchy',
        component: <CompanyHierarchy organization={organization} />,
      },
      {
        id: NAV_EVENT_KEYS.welcomeMessages,
        title: 'Welcome Messages',
        component: <WelcomeFlowSettings />,
      },
    ];

    if (hasNotificationSettingsAccess) {
      list.push({
        id: NAV_EVENT_KEYS?.adminNotificationConfigurations,
        title: 'Notifications',
        component: <AdminNotificationConfigurations />,
      });
    }

    if (hasIntegrationsAccess) {
      list.push({
        id: NAV_EVENT_KEYS?.integrationSettings,
        title: 'Integration Settings',
        component: <IntegrationSettingsContainer />,
      });
    }

    if (hasFNOLUIAccess) {
      list.push({
        id: NAV_EVENT_KEYS.cfnol,
        title: 'CFNOL',
        component: <CfnolSettings />,
      });
    }

    return list;
  }, [
    hasIntegrationsAccess,
    hasNotificationSettingsAccess,
    hasFNOLUIAccess,
    organization,
  ]);

  return (
    <main data-testid="settingsWrap" className="settingsWrap">
      <TabRouter
        tabList={tabList}
        basePath="settings"
        defaultTabId={NAV_EVENT_KEYS.orgSettings}
        className="settingsWrap"
      />
    </main>
  );
};

export default Settings;
