import { CREATE_RULESET, UPDATE_RULESET } from '../../../constants/endpoints';

export const keyById = (arr) => arr.reduce((acc, curr) => ({
  ...acc,
  [curr.id]: {
    ...curr,
    ruleName: curr.ruleName || curr.messageTemplate.displayName,
  },
}), {});

export const buildRequestOptions = (ruleSettings) => {
  const {
    rules, id, defaultVersion, rulesetType, partnerName,
  } = ruleSettings;
  const destructuredRules = Object.keys(rules).map((key) => rules[key]);
  const structuredBody = {
    id,
    rulesetType,
    ...partnerName && { partnerName },
    rules: destructuredRules,
    defaultVersion,
  };
  let method;
  let url;
  if (id === 'DEFAULT') {
    url = CREATE_RULESET;
    method = 'POST';
  } else {
    method = 'PUT';
    url = UPDATE_RULESET(id);
  }
  const options = {
    body: JSON.stringify(structuredBody),
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return {
    url,
    options,
  };
};

export const deepEqual = (obj1, obj2) => JSON.stringify(obj1) !== JSON.stringify(obj2);
