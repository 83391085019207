import _ from 'lodash';
import User from './user';
import { formatDate } from '../helpers/datetime';
import { s3toPipedUrl } from '../helpers/urls';

class Message {
  constructor(message) {
    this.id = message._id || '';
    this.messageBody = message.body || '';
    this.redactionData = _.get(message, 'redactionData', []);
    this.redactionViewedEvents = _.get(message, 'redactionViewedEvents', []);
    this.type = message.type;
    this.chatId = message.chatId;
    this.createdAt = message.createdAt;
    this.delimitedAt = message.delimitedAt;
    this.messageGroup = message.messageGroup;
    this.additionalInfo = message.additionalInfo;
    this.deliveryStatus = message.deliveryStatus;

    this.setAuthor(message);
    this.formatTimestamp();

    this.isFile = message.isFile;
    this.mediaIndex = message.isFile ? message.mediaIndex : null;
    this.fileUrl = message.isFile ? s3toPipedUrl(message.fileUrl) : null;
    this.isAI = message.isAI;
    this.theLastMessage = message.theLastMessage;
    this.channel = message.channel;
    this.isEvent = message.isEvent;
    this.participants = message.participants || [];
    this.sentimentData = message.sentimentData || {};
    this.classifications = _.get(message, 'classifications', {});
    this.needsAttention = message.needsAttention || false;
    this.providerId = message.providerId;
    this.needsAction = message.needsAction || {};
    this.mediaInfo = message.mediaInfo;
    this.isFirstUnreadMessage = message.isFirstUnreadMessage || false;
    this.isInbound = message.isInbound;
    this.bodyPrefix = message?.bodyPrefix;
  }

  setAuthor(message) {
    const author = message.author || message.authorId;
    if (!author) return;

    if (typeof author === 'string') {
      this.authorId = author;
      return;
    }
    this.author = new User(author);
  }

  setTranslation(translatedMessage) {
    this.additionalInfo = {
      ...this.additionalInfo,
      translatedMessage,
    };
  }

  formatTimestamp() {
    if (this.createdAt) {
      this.createdAtHuman = formatDate(this.createdAt, 'human');
    }
  }
}

export default Message;
