import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Stack,
  HStack,
  Icon,
  IconButton,
  Button,
} from '@chakra-ui/react';
import {
  AutoIcon,
  BuildingIcon,
  ImportantIcon,
  LockIcon,
  TrashIcon,
} from '@himarley/unity';
import React from 'react';

import type { WorkflowSettings } from '../../../types/workflow-settings';

interface WorkflowSettingsCardProps extends WorkflowSettings {
  type?: string;
  workflowName: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

const WorkflowSettingsCard: React.FC<WorkflowSettingsCardProps> = ({
  workflowName,
  carrierDisplayName,
  timeZone,
  carrierPhone,
  redirectPhone,
  carrierWebsite,
  policyLookupEnabled,
  claimSubmissionEnabled,
  singleOptInEnabled,
  escalation,
  businessHours,
  type,
  onEdit,
  onDelete,
}) => {
  const isDefaultSettings = workflowName === 'DEFAULT';

  const getIcon = () => {
    switch (type) {
      case 'AUTO':
        return AutoIcon;
      case 'PROPERTY':
        return BuildingIcon;
      case 'DEFAULT':
        return LockIcon;
      default:
        return ImportantIcon;
    }
  };

  return (
    <Card maxW="xl" minW="sm" borderRadius="lg" variant="outline">
      <CardHeader>
        <HStack justify="space-between" align="center">
          <HStack>
            <Icon as={getIcon()} boxSize={6} />
            <Heading size="md">{workflowName}</Heading>
          </HStack>
          <HStack verticalAlign="center">
            {
              !isDefaultSettings && (
                <IconButton
                  aria-label="Delete workflow"
                  icon={<Icon as={TrashIcon} />}
                  variant="ghost"
                  onClick={onDelete}
                />
              )
            }
            <Button
              aria-label="Edit"
              variant="outline"
              onClick={onEdit}
            >
              Edit
            </Button>
          </HStack>
        </HStack>
      </CardHeader>

      <CardBody>
        <Stack spacing={4}>
          <Box>
            <Text fontWeight="bold">Workflow name</Text>
            <Text>{workflowName}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Carrier name</Text>
            <Text>{carrierDisplayName}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Phone number</Text>
            <Text>{carrierPhone}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Redirect landline number</Text>
            <Text>{redirectPhone}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Website</Text>
            <Text>{carrierWebsite}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Single opt-in</Text>
            <Text>{singleOptInEnabled ? 'Enabled' : 'Disabled'}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Policy lookup</Text>
            <Text>{policyLookupEnabled ? 'Enabled' : 'Disabled'}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Claim submission</Text>
            <Text>{claimSubmissionEnabled ? 'Enabled' : 'Disabled'}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Time zone</Text>
            <Text>{timeZone}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Business hours</Text>
            {Object.entries(businessHours || {}).map(([day, hours]) => (
              <Text key={day}>
                {day}
                :
                {' '}
                {hours.start}
                {' '}
                -
                {hours.end}
              </Text>
            ))}
          </Box>
          <Box>
            <Text fontWeight="bold">Escalation email (within business hours)</Text>
            {escalation?.withinBusinessHours?.emailTo?.map((email) => (
              <Text key={email.value}>{email.value}</Text>
            ))}
          </Box>
          <Box>
            <Text fontWeight="bold">Escalation phone (outside business hours)</Text>
            <Text>{escalation?.outsideBusinessHours?.carrierEscalationNumber}</Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default WorkflowSettingsCard;
