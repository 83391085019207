/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import {
  Route, useNavigate, Routes as RoutesDom,
} from 'react-router';
import CreateCaseModal from '@app/components/CreateCase/create-case-modal';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import { SHOW_MY_INSIGHTS } from '@app/constants/permissions';
import { useCheckPermissions } from '@app/helpers/common';
import App from '../App';
import ProfileConfig from '../ProfileConfig/profile-config';
import Dashboard from '../Dashboard';
import CustomCallback from '../CustomCallback/CustomCallback';
import { oktaAuthClient, oktaSignInConfig, CALLBACK_PATH } from '../LoginWidget/config';
import Login from '../LoginWidget/Login';
import FeatureFlags from '../FeatureFlags/FeatureFlags';
import UserMgmtRoot from '../panels/UserMgmt/user-mgmt-root';
import { RequiredAuth } from './secure-route';

const Application = (props) => (<App panel={props?.panel}><Dashboard {...props} /></App>);

export const Routes = () => {
  const history = useNavigate();
  const isSmallScreen = useScreenSize();
  const isMyInsightsEnabled = useCheckPermissions([SHOW_MY_INSIGHTS]);

  const restoreOriginalUri = async () => {
    history(toRelativeUrl(isSmallScreen || !isMyInsightsEnabled ? '/chats' : 'home', window.location.origin), { replace: true });
  };

  return (
    <Security
      className="security"
      oktaAuth={oktaAuthClient}
      restoreOriginalUri={restoreOriginalUri}
    >
      <RoutesDom>
        <Route path={CALLBACK_PATH} element={<CustomCallback />} />
        <Route path="/login" element={<Login config={oktaSignInConfig} />} />
        <Route path="/" element={<RequiredAuth />}>
          <Route path="/" element={<Application />}>
            <Route path="/:panelId/create" element={<CreateCaseModal />} />
            <Route path="/:panelId/edit/*" element={<CreateCaseModal />} />
            <Route path="/:panelId/:id/create" element={<CreateCaseModal />} />
            <Route exact path="/admin/feature-flags" element={<FeatureFlags />} />
            <Route path="/:panelId/ref/:refId" element={<Application />} />
            <Route path="/:panelId/filter/:filterId" element={<Application />} />
            <Route path="/:panelId/:subNavId?" element={<Application />} />
            <Route path="/case/:jobId?" element={<Application />} />
            <Route path="/:panelId/linesOfBusiness/*" element={<UserMgmtRoot />} />
            <Route path="*/profile" element={<ProfileConfig />} />
          </Route>
        </Route>
      </RoutesDom>
    </Security>
  );
};

Application.propTypes = {
  panel: PropTypes.string.isRequired,
};
