import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ActionMenu, IconButton } from '@himarley/unity';
import usePermissionVerify from '@app/hooks/permission-verify';
import isLoadedFromMobile from '@app/helpers/platform';
import ForwardIcon from '../../../images/icons/forward.svg';
import DownloadIcon from '../../../images/icons/download2.svg';
import ExpandIcon from '../../../images/icons/expand.svg';
import { mediaItemPropTypes, determineRotation } from './media-helpers';
import { updateMediaItem, sendMediaToVerisk } from '../../actions/media';
import RedactImageModalContainer from '../Chats/Chat/ChatRedaction/RedactionPopup/RedactImageModalContainer';
import { FEATURE_FLAG_IMAGE_REDACTION, FEATURE_FLAG_VERISK, FEATURE_FLAG_VERISK_MEDIA_SYNC } from '../../constants/permissions';
import getImageControls from './image-controls';

import './MediaToolbar.less';

const updateMediaRotation = (dispatch, direction, mediaItem) => {
  const currentRotation = mediaItem.rotation || 0;
  const updatedRotation = determineRotation(direction, currentRotation);
  dispatch(
    updateMediaItem({
      ...mediaItem,
      rotation: updatedRotation,
    }),
  );
};

const testIdPrefix = 'media-toolbar';
const MediaToolbar = ({
  mediaItem,
  localFileUrl,
  onForward,
  onExpand,
  isInlineControls,
  isActionMenuShown,
}) => {
  const { _id: jobId, veriskData: jobVeriskData } = useSelector(({ jobs }) => jobs.activeJob);

  const {
    FEATURE_FLAG_VERISK: isVeriskEnabled,
    FEATURE_FLAG_IMAGE_REDACTION: isImageRedactionEnabled,
    FEATURE_FLAG_VERISK_MEDIA_SYNC: isVeriskMediaSyncEnabled,
  } = usePermissionVerify([
    FEATURE_FLAG_VERISK,
    FEATURE_FLAG_IMAGE_REDACTION,
    FEATURE_FLAG_VERISK_MEDIA_SYNC,
  ]);

  const isImage = mediaItem?.mediaInfo?.contentType?.includes('image');
  const dispatch = useDispatch();

  const handleSyncVeriskMedia = useCallback(({ messageId, fileUrl }) => {
    const [fileName] = fileUrl.split('/').slice(-1);
    dispatch(sendMediaToVerisk({ jobId, mediaMessageData: [{ fileName, messageId }] }));
  }, [dispatch, jobId]);

  const handleRotateLeft = useCallback(
    () => updateMediaRotation(dispatch, 'left', mediaItem),
    [mediaItem, dispatch],
  );
  const handleRotateRight = useCallback(
    () => updateMediaRotation(dispatch, 'right', mediaItem),
    [mediaItem, dispatch],
  );
  const [isRedactImageModalShown, setIsRedactImageModalShown] = useState(false);
  const [
    isConfirmViewRedactedImageModalShown,
    setIsConfirmViewRedactedImageModalShown,
  ] = useState(false);
  const [isViewRedactedImageModalShown, setIsViewRedactedImageModalShown] = useState(false);

  useEffect(() => {
    const toolbarShortcuts = (event) => {
      switch (event.key) {
        case 'a':
          handleRotateLeft();
          break;
        case 'd':
          handleRotateRight();
          break;
        default:
      }
    };
    if (onExpand) return () => {};
    window.addEventListener('keydown', toolbarShortcuts);
    return () => window.removeEventListener('keydown', toolbarShortcuts);
  }, [handleRotateLeft, handleRotateRight, onExpand]);
  const { providerId, isImageRedacted, fileUrl } = mediaItem || {};

  const canRedactImage = isImageRedactionEnabled && !isImageRedacted;
  const canViewRedactedImage = isImageRedactionEnabled && isImageRedacted;

  const moreImageActions = useMemo(() => {
    const imageControls = getImageControls({
      localFileUrl,
      testIdPrefix,
      handleRotateRight,
      handleRotateLeft,
      setIsRedactImageModalShown,
      setIsConfirmViewRedactedImageModalShown,
      setIsViewRedactedImageModalShown,
      mediaItem,
      jobId,
      postVeriskMedia: handleSyncVeriskMedia,
    });
    return [
      ...(!isImageRedacted
        ? [
          !isLoadedFromMobile() && (
            imageControls.print
          ),
          imageControls.rotateRight,
          imageControls.rotateLeft,
        ]
        : []),
      ...(canRedactImage ? [imageControls.redactImage] : []),
      ...(canViewRedactedImage ? [imageControls.viewRedactedImage] : []),
      ...(isVeriskEnabled
        && jobVeriskData?.originalAssignmentId
        && !mediaItem.veriskUploadStatus
        && !isImageRedacted
        && isVeriskMediaSyncEnabled
        ? [imageControls.veriskMediaSync] : []),
    ];
  }, [
    canRedactImage,
    canViewRedactedImage,
    handleRotateLeft,
    handleRotateRight,
    isImageRedacted,
    localFileUrl,
    isVeriskEnabled,
    mediaItem,
    handleSyncVeriskMedia,
    jobId,
    jobVeriskData,
    isVeriskMediaSyncEnabled,
  ]);

  const inlineImageActions = useMemo(() => {
    const imageControls = getImageControls({
      localFileUrl,
      testIdPrefix,
      mediaItem,
      handleRotateRight,
      handleRotateLeft,
      setIsRedactImageModalShown,
      setIsConfirmViewRedactedImageModalShown,
      setIsViewRedactedImageModalShown,
    });
    return [
      ...(!isImageRedacted
        ? [
          !isLoadedFromMobile() && (
            <IconButton
              title={imageControls.print.label}
              id={imageControls.print.testId}
              onClick={imageControls.print.onClick}
            >
              {imageControls.print.subIcon}
            </IconButton>
          ),
          <IconButton
            title={imageControls.rotateLeft.label}
            id={imageControls.rotateLeft.testId}
            onClick={imageControls.rotateLeft.onClick}
          >
            {imageControls.rotateLeft.subIcon}
          </IconButton>,
          <IconButton
            title={imageControls.rotateRight.label}
            id={imageControls.rotateRight.testId}
            onClick={imageControls.rotateRight.onClick}
          >
            {imageControls.rotateRight.subIcon}
          </IconButton>,
        ]
        : []),
      ...(canRedactImage
        ? [
          <IconButton
            title={imageControls.redactImage.label}
            id={imageControls.redactImage.testId}
            onClick={imageControls.redactImage.onClick}
          >
            {imageControls.redactImage.subIcon}
          </IconButton>,
        ]
        : []),
      ...(canViewRedactedImage
        ? [
          <IconButton
            title={imageControls.viewRedactedImage.label}
            id={imageControls.viewRedactedImage.testId}
            onClick={imageControls.viewRedactedImage.onClick}
          >
            {imageControls.viewRedactedImage.subIcon}
          </IconButton>,
        ]
        : []),
    ];
  }, [
    canRedactImage,
    canViewRedactedImage,
    handleRotateLeft,
    handleRotateRight,
    isImageRedacted,
    localFileUrl,
    mediaItem,
  ]);

  const renderToolbarActions = useMemo(() => {
    const inlineActions = [];
    const moreActions = [];
    if (onExpand && !isImageRedacted) {
      inlineActions.push(
        <IconButton
          title="Expand"
          id={`${testIdPrefix}-button-expand-${providerId}`}
          onClick={onExpand}
        >
          <ExpandIcon />
        </IconButton>,
      );
    }

    if (!isImageRedacted && !isLoadedFromMobile()) {
      inlineActions.push(
        <a
          data-testid={`${testIdPrefix}-item-link-${providerId}`}
          aria-label="Download"
          href={localFileUrl}
          target="_blank"
          download
          rel="noreferrer"
        >
          <IconButton title="Download">
            <DownloadIcon />
          </IconButton>
        </a>,
      );
    }

    if (isImage) {
      if (isInlineControls) {
        inlineActions.push(...inlineImageActions);
      } else {
        moreActions.push(...moreImageActions);
      }
    }

    if (onForward) {
      inlineActions.push(
        <IconButton
          title="Forward"
          id={`${testIdPrefix}-button-forward-${providerId}`}
          onClick={onForward}
        >
          <ForwardIcon />
        </IconButton>,
      );
    }

    const isModalShown = isViewRedactedImageModalShown
      || isRedactImageModalShown
      || isConfirmViewRedactedImageModalShown;

    return !isActionMenuShown
      || isModalShown
      || (inlineActions?.length === 0 && moreActions?.length === 0) ? null : (
        <ActionMenu
          testId="toolbar-action-menu-testid"
          className="toolbar-action-menu"
          actions={inlineActions}
          moreActions={moreActions}
        />
      );
  }, [
    onExpand,
    isImageRedacted,
    isImage,
    onForward,
    isViewRedactedImageModalShown,
    isRedactImageModalShown,
    isConfirmViewRedactedImageModalShown,
    isActionMenuShown,
    providerId,
    localFileUrl,
    isInlineControls,
    inlineImageActions,
    moreImageActions,
  ]);

  return (
    <>
      <RedactImageModalContainer
        fileUrl={fileUrl}
        isConfirmViewRedactedImageModalShown={
          isConfirmViewRedactedImageModalShown
        }
        isRedactImageModalShown={isRedactImageModalShown}
        mediaItem={mediaItem}
        setIsRedactImageModalShown={setIsRedactImageModalShown}
        setIsConfirmViewRedactedImageModalShown={
          setIsConfirmViewRedactedImageModalShown
        }
        isViewRedactedImageModalShown={isViewRedactedImageModalShown}
        setIsViewRedactedImageModalShown={setIsViewRedactedImageModalShown}
      />
      {renderToolbarActions}
    </>
  );
};

MediaToolbar.propTypes = {
  mediaItem: mediaItemPropTypes.isRequired,
  localFileUrl: PropTypes.string.isRequired,
  onExpand: PropTypes.func,
  onForward: PropTypes.func,
  isInlineControls: PropTypes.bool,
  isActionMenuShown: PropTypes.bool,
};

MediaToolbar.defaultProps = {
  onForward: null,
  onExpand: null,
  isInlineControls: false,
  isActionMenuShown: true,
};

export default MediaToolbar;
