import React from 'react';
import OpenWindow from '../../../images/icons/openWindow-gray-icon.svg';
import styles from './DataTray.module.less';
import buildTrayData from './helper';
import { trayDataPropTypes, typeOfTrayPropType } from '../../helpers/network-partners-utils';

const DataTray = ({ data = {}, type }) => {
  const trayDataSet = buildTrayData(data, type);
  const renderLink = (link) => {
    const { url, text } = link;
    return (
      <a className={styles.trayItemLink} data-testid={`trayItemLink-${type}`} href={url} target="_blank" rel="noreferrer">
        {text}
        {' '}
        <OpenWindow />
      </a>
    );
  };

  return (
    <div className={styles.trayContainer}>
      {
        trayDataSet
          .map((item) => (
            <div key={item.key} className={styles.trayItem}>
              {item.header && (
                <p className={styles.trayItemHeader}>{item.header}</p>
              )}
              {item.title && (
                <p className={styles.trayItemTitle}>{item.title}</p>
              )}
              {item.data && Object.entries(item.data).map(([key, value]) => (
                key === 'link'
                  ? (renderLink(value))
                  : <p key={key} className={styles.trayItemList}>{value}</p>
              ))}
            </div>
          ))
      }
    </div>
  );
};

DataTray.propTypes = {
  data: trayDataPropTypes.isRequired,
  type: typeOfTrayPropType.isRequired,
};

export default DataTray;
