/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import usePermissionCheck from '../HigherOrderComponents/usePermissionCheck';
import './PermissionVerifier.less';

const PermissionVerifier = ({
  hasAccess = false,
  children,
  redirect = false,
}) => (
  <div className="authBox">
    {hasAccess ? children : redirect ? <Redirect to="/" /> : ''}
  </div>
);
PermissionVerifier.propTypes = {
  hasAccess: PropTypes.bool,
  children: PropTypes.node.isRequired,
  redirect: PropTypes.bool,
};

PermissionVerifier.defaultProps = {
  hasAccess: false,
  redirect: false,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
export default usePermissionCheck(PermissionVerifier);
