/* eslint-disable react/jsx-filename-extension */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SearchBar,
  ConversationIcon,
  FilesBoxIcon,
  UserIcon,
  DocumentIcon,
  LockIcon,
  CheckmarksIcon,
  FolderOpenIcon,
  CheckmarkFilledIcon,
} from '@himarley/unity';
import useSearchBar from '@app/hooks/search-bar';
import {
  Checkbox, Tooltip,
} from '@chakra-ui/react';
import Menu from '@app/components/chakra/menu';
import CreateCaseButton from '@app/components/CreateCase/create-case-button';

import _ from 'lodash';
import { useNavigate } from 'react-router';
import { clearAddFormErrors, lazyLoad } from '@app/actions/common';
import {
  setActiveJob,
  jobPutRequest,
  getFilteredOperatorIds,
} from '@app/actions/job';
import Panel from '../Panel/Panel';
import DisabledBanner from '../elements/Banner/DisabledBanner/DisabledBanner';
// eslint-disable-next-line import/no-named-as-default
import SortTable from '../elements/table/SortTable/SortTable';

import { localToRemoteUrl } from '../../helpers/urls';
import CheckBoxInput from '../elements/form/CheckBoxInput/CheckBoxInput';
import usePersistentProperties from '../elements/hooks/usePersistentProperties';

import { DISABLE_CREATE_CASE, FNOL_UI } from '../../constants/permissions';

import { formatPhoneNumber } from '../../helpers/format';
import { formatDate } from '../../helpers/datetime';
import { useCheckPermissions } from '../../helpers/common';
import { usePrevious } from '../Hooks/usePrevious';
import { showSnackbar, closeSnackbar } from '../../actions/notification';
import {
  openDashboardView,
  sortItems,
} from '../../actions/ui';
import withLazyLoad from '../withLazyLoad/withLazyLoad';
import {
  getAllOperatorsTopics,
  getOperatorTopics,
  manageOperatorSubscriptions,
  filteredUnsubTopics,
} from '../../helpers/liveUpdates';
import {
  // eslint-disable-next-line import/named
  subscribeToTopics,
  // eslint-disable-next-line import/named
  unsubscribeFromTopics,
} from '../../actions/socket';

import withLoading from '../HigherOrderComponents/withLoading';
import TableHeader from '../elements/table/SortTable/TableHeader/TableHeader';
import CaseRow from './case-row';
import selector from '../../selectors/case';
// eslint-disable-next-line import/no-named-as-default
import SendEmailConfirmation from '../confirmationModals/SendEmailConfirmation/SendEmailConfirmation';
import SendSurveyConfirmation from '../confirmationModals/SendSurveyConfirmation/SendSurveyConfirmation';
import { caseType } from '../../models/marleyTypes';
import './Cases.less';
import CloseCaseConfirmation from '../confirmationModals/CloseCaseConfirmation/CloseCaseConfirmation';
import ReassignCaseConfirmation from '../confirmationModals/ReassignCaseConfirmation/ReassignCaseConfirmation';
import useTopicHandler from '../elements/hooks/useTopicHandler';
import AssignedFilter from './assigned-filter';

const columns = [
  {
    id: 'claimant-name',
    label: 'Customer',
    location: ['customer.nameTableFormat'],
    sortLocation: ['customerId.profile.lastName', 'companyName'],
  },
  {
    id: 'phone-number',
    label: 'Phone Number',
    location: 'customer.displayPhoneNumber',
    format: ({ value }) => <div>{formatPhoneNumber(value)}</div>, // eslint-disable-line
  },
  {
    id: 'date-created',
    label: 'Date Created',
    sort: true,
    numericSort: true,
    location: 'createdAt',
    format: ({ value }) => <div>{formatDate(value, 'just-date')}</div>, // eslint-disable-line
  },
  {
    id: 'assigned',
    label: 'Assigned',
  },
  { id: 'actions', label: 'Actions' },
];

const SELECTION_LIMIT = 100;

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const chatPdfLink = (jobId, redaction = false) => (redaction
  ? `/api/chat/${jobId}/pdf?tzone=${currentTimezone}&endpoint=${process.env.WEBSOCKET_ENDPOINT.endpoint}&redacted=true`
  : `/api/chat/${jobId}/pdf?tzone=${currentTimezone}&endpoint=${process.env.WEBSOCKET_ENDPOINT.endpoint}&redacted=false`);
const customerPdfLink = (recipientId, redaction = false) => (redaction
  ? `/api/chat/${recipientId}/transcript-splash-screen?tzone=${currentTimezone}&endpoint=${process.env.WEBSOCKET_ENDPOINT.endpoint}&getCustomerTranscript=true&redacted=true`
  : `/api/chat/${recipientId}/transcript-splash-screen?tzone=${currentTimezone}&endpoint=${process.env.WEBSOCKET_ENDPOINT.endpoint}&getCustomerTranscript=true&redacted=false`);

const Cases = ({
  jobs = [],
  authId,
  loadedItems = [],
  organization = '',
  getFilteredOperatorIds = () => { },
  filteredOperatorIds = [],
  subscribeToTopics,
  unsubscribeFromTopics,
  isLoading = false,
  permissions = [],
  increaseDataSet = () => { },
  jobPutRequest = () => { },
  ...props
}) => {
  const statusDropdownList = useMemo(
    () => [
      {
        id: 'open',
        label: 'Open',
        placeholderLabel: 'open',
        query: true,
        filter: (j) => j.isOpen,
      },
      {
        id: 'closed',
        label: 'Closed',
        placeholderLabel: 'closed',
        query: false,
        filter: (j) => !j.isOpen,
      },
      {
        id: 'all',
        label: 'All',
        placeholderLabel: 'all',
        query: null,
        filter: (j) => j,
      },
    ],
    [],
  );

  const ownedByOperator = (j) => j.operatorIds.includes(authId)
    || j.operatorId === authId
    || j.secondaryOperatorIds.includes(authId);

  const assignedDropdownList = [
    {
      id: authId,
      label: 'Me',
      placeholderLabel: 'my',
      query: [authId],
      filter: (j) => ownedByOperator(j),
    },
    {
      id: 'all',
      label: 'All',
      placeholderLabel: 'all',
      query: null,
      filter: () => true,
    },
    {
      id: 'unassigned',
      label: 'Unassigned',
      placeholderLabel: 'unassigned',
      query: 'unassigned',
      filter: (j) => j.operatorIds.length === 0,
    },
  ];
  const history = useNavigate();

  const [assignedItem, setAssignedItem] = useState(
    _.first(assignedDropdownList),
  );
  const [statusItem, setStatusItem] = useState(_.first(statusDropdownList));
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [assignedOperatorsPerJob, setAssignedOperatorsPerJob] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [reassignCaseOpen, setReassignCaseOpen] = useState(false);
  const [closeCaseOpen, setCloseCaseOpen] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [searchFilter, setSearchFilter] = useSearchBar('case');

  const fnolUIEnabled = useCheckPermissions([FNOL_UI]);

  // Managing prev state of Array<Object> parameters being passed to useEffects

  const loadCases = useCallback(
    ({
      operators,
      groups,
      status,
      fnolInProgress,
    }) => {
      const newStatus = statusDropdownList.find((a) => a.id === status)
        || _.first(statusDropdownList);

      const operatorIds = (operators || []).length > 0
        ? (operators || []).map((o) => o.id)
        : null;
      const groupIds = (groups || []).length > 0 ? (groups || []).map((o) => o.id) : null;
      const query = {
        isOpen: newStatus?.query,
        operatorIds,
        groupIds,
      };

      if (fnolInProgress) {
        query.workflowStatus = 'FNOL_IN_PROGRESS';
      } else {
        query.workflowStatus = null;
      }

      props.modifyQuery(query);

      let filter = () => true;

      if (filteredOperatorIds.length) {
        filter = (j) => (j.operatorIds.length === 0)
          || _.intersection(j.operatorIds, filteredOperatorIds).length > 0
          || _.intersection(j.secondaryOperatorIds, filteredOperatorIds).length
          > 0;
      }

      setAssignedItem({ filter }); // assigned operator filter
      setStatusItem(newStatus); // open/closed/all
    },
    [props, statusDropdownList, filteredOperatorIds],
  );

  const { properties, initialSet, setProperties } = usePersistentProperties({
    location: 'filters.casePanel',
    defaults: {
      status: _.first(statusDropdownList).id,
      operatorIds: _.first(assignedDropdownList).id,
      fnolInProgress: false,
    },
    onPropertyChange: loadCases,
    sortOrder: {
      type: props.type.lowercaseSingular,
      order: props.sort,
    },
  });

  // Managing prev state of Array<Object> parameters being passed to useEffects
  const prevJobs = usePrevious(jobs);
  const prevProperties = usePrevious(properties);
  const prevFilteredOperatorsIds = usePrevious(filteredOperatorIds);
  const memoizedTopicHandler = useTopicHandler();

  useEffect(() => {
    if (initialSet && !hasLoaded) {
      loadCases(properties || {});
      setHasLoaded(true);
      subscribeToTopics(
        getAllOperatorsTopics(organization?._id),
        memoizedTopicHandler,
      );
    }
  }, [hasLoaded, initialSet, loadCases, memoizedTopicHandler, organization?._id, properties, props, subscribeToTopics]);

  useEffect(() => {
    if (!_.isEqual(prevFilteredOperatorsIds, filteredOperatorIds)) {
      loadCases(properties || {});
    }
  }, [properties, filteredOperatorIds, prevFilteredOperatorsIds]);

  useEffect(() => {
    if (properties) {
      const { operators, groups } = properties;
      const selectedOperatorIds = (operators || []).length > 0
        ? (operators || []).map((o) => o.id)
        : null;
      const selectedGroupIds = (groups || []).length > 0 ? (groups || []).map((o) => o.id) : null;

      getFilteredOperatorIds({ selectedOperatorIds, selectedGroupIds });
    }
  }, [properties]);

  useEffect(() => {
    if (prevJobs && !_.isEqual(prevJobs, jobs)) {
      const operators = jobs.map((job) => ({
        id: job.assignedOperator.id,
        label: job.assignedOperator.name,
      })) || [];
      setAssignedOperatorsPerJob(operators);
    }
  }, [jobs, prevJobs]);
  // unsubscribe from operators topic on component dismount
  useEffect(
    () => () => {
      const filteredOperators = properties?.operators
        ? getOperatorTopics(organization?._id, properties?.operators)
        : [];
      const unsubTopics = filteredUnsubTopics(
        [...filteredOperators, ...getAllOperatorsTopics(organization?._id)],
        authId,
      );
      unsubscribeFromTopics(unsubTopics);
    },
    [
      memoizedTopicHandler,
      organization?._id,
      unsubscribeFromTopics,
      properties?.operators,
    ],
  );

  useEffect(() => {
    const orgId = organization?._id;
    const { subscribeList, unsubscribeList } = manageOperatorSubscriptions(
      orgId,
      prevProperties,
      properties,
    );
    const unsubTopics = filteredUnsubTopics(unsubscribeList, authId);
    unsubscribeFromTopics(unsubTopics);
    subscribeToTopics(subscribeList, memoizedTopicHandler);
  }, [
    organization?._id,
    prevProperties,
    properties,
    subscribeToTopics,
    unsubscribeFromTopics,
    memoizedTopicHandler,
  ]);

  useEffect(() => {
    // Following logic ensures that cases conform to query.
    // When pollbox brings in a "NEW_CASE" and is added to jobs.list,
    // this determines whether to display

    const assignedFilter = _.get(assignedItem, 'filter', () => { });
    const statusFilter = _.get(statusItem, 'filter', () => { });
    if (prevJobs && !_.isEqual(prevJobs, jobs)) {
      setFilteredJobs(
        [...loadedItems].filter((j) => assignedFilter(j) && statusFilter(j)),
      );
    }
  }, [jobs, assignedItem, statusItem, prevJobs, loadedItems]);

  useEffect(() => {
    setSelectedJobIds([]);
    setSelectAll(false);
  }, [assignedItem, statusItem]);

  const selectAllCases = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setSelectedJobIds([]);
    } else {
      const allSelectedJobs = filteredJobs.map((c) => c.id);
      const limitedSelectedJobs = allSelectedJobs.slice(0, SELECTION_LIMIT);
      setSelectedJobIds(limitedSelectedJobs);
    }
  };

  const caseColumns = [
    {
      id: 'case-number',
      label: (
        <div className="case-columns">
          <div className="case-checkbox">
            {!_.isEmpty(filteredJobs) && (
              <Tooltip hasArrow label="Select all visible" shouldWrapChildren>
                <CheckBoxInput
                  onChange={selectAllCases}
                  value="SelectAll"
                  isChecked={selectAll}
                  display
                />
              </Tooltip>
            )}
          </div>
          Reference
        </div>
      ),
    },
    ...columns,
  ].map((col) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableHeader key={col.id || col.label} {...col} tableId="case" />
  ));

  const getActions = useCallback(
    ({ isVerified, isOpen, job }) => {
      const actionsOpenAgnostic = [
        {
          id: 'open-conversation',
          label: 'View Conversation',
          action: (id) => {
            history(`/chats/${id}`);
          },
          leftIcon: ConversationIcon,
        },
        {
          id: 'survey',
          label: 'Send Survey',
          confirmation: true,
          leftIcon: CheckmarksIcon,
          isDisabled: !isVerified,
        },
      ];
      const unredactedTranscript = [
        {
          id: 'case-transcript',
          label: 'Case Transcript',
          action: (id) => {
            window.open(localToRemoteUrl(chatPdfLink(id, false)));
          },
          leftIcon: DocumentIcon,
          isDisabled: !isVerified,
        },
        {
          id: 'customer-transcript',
          label: 'Customer Transcript',
          action: () => {
            window.open(
              localToRemoteUrl(
                customerPdfLink(_.get(job, 'customer.id'), false),
              ),
            );
          },
          leftIcon: UserIcon,
          isDisabled: !isVerified,
        },
      ];
      const redactedTranscripts = [
        {
          id: 'redacted-case-transcript',
          label: 'Redacted Case Transcript',
          action: (id) => {
            window.open(localToRemoteUrl(chatPdfLink(id, true)));
          },
          leftIcon: LockIcon,
          isDisabled: !isVerified,
        },
        {
          id: 'redacted-customer-transcript',
          label: 'Redacted Customer Transcript',
          action: () => {
            window.open(
              localToRemoteUrl(customerPdfLink(_.get(job, 'customer.id'), true)),
            );
          },
          leftIcon: LockIcon,
          isDisabled: !isVerified,
        },
      ];

      const authPermissions = _.get(props, 'auth.permissions', []);
      const isRedactionOrgEnabled = authPermissions.includes(
        'FEATURE_FLAG_UI_REDACTION',
      );
      if (isRedactionOrgEnabled) {
        const operatorHasUnredactedAccess = permissions.find((v) => v === 'REDACTION_VIEW_ACCESS') || false;
        if (operatorHasUnredactedAccess) {
          actionsOpenAgnostic.push(...unredactedTranscript);
        }
        actionsOpenAgnostic.push(...redactedTranscripts);
      } else {
        actionsOpenAgnostic.push(...unredactedTranscript);
      }

      const openActions = [
        {
          id: 'resendwelcome',
          label: 'Resend Opt-In Text',
          confirmation: true,
          leftIcon: ConversationIcon,
          isDisabled: isVerified,
        },
        {
          id: 'close',
          label: 'Close Case',
          action: (id) => {
            let job = jobs.find((j) => j.id === id) || {};
            job = { ...job, isOpen: false };
            jobPutRequest(job, false);
          },
          leftIcon: FilesBoxIcon,
        },
      ];

      const closedActions = [
        {
          id: 'reopen',
          label: 'Re-open Case',
          action: (id) => {
            let job = jobs.find((j) => j.id === id) || {};
            job = { ...job, isOpen: true };
            jobPutRequest(job, true).then(() => {
              history(`/chats/${job?.id || job?._id}`);
            });
          },
          leftIcon: FolderOpenIcon,
        },
      ];

      const displayActions = _.union(
        actionsOpenAgnostic,
        isOpen ? openActions : closedActions,
      );

      return displayActions;
    },
    [jobs, props],
  );

  const statusToggle = (id) => {
    const item = statusDropdownList.find((i) => i.id === id) || {};
    setProperties({
      ...properties,
      status: id,
    });
    setStatusItem(item);
  };

  const handleFnolToggle = (event) => {
    const { checked } = event.target;
    setProperties({
      ...properties,
      fnolInProgress: checked,
    });
  };

  const setCaseSelected = useCallback(
    (event) => {
      if (
        selectedJobIds.length < SELECTION_LIMIT
        && event.target.checked
        && !selectedJobIds.includes(event.target.value)
      ) {
        setSelectedJobIds((prevCaseId) => [...prevCaseId, event.target.value]);
      } else {
        setSelectedJobIds((prevCaseId) => prevCaseId.filter((id) => id !== event.target.value));
      }
    },
    [selectedJobIds],
  );

  const bulkActions = [
    {
      id: 'close',
      label: 'Close Selected Cases',
      leftIcon: FilesBoxIcon,
      select: () => setCloseCaseOpen(!closeCaseOpen),
    },
    {
      id: 'reassign',
      label: 'Reassign Selected Cases',
      leftIcon: UserIcon,
      select: () => setReassignCaseOpen(!reassignCaseOpen),
    },
  ];

  const handleBulkActionRowSelect = (id) => {
    // eslint-disable-next-line no-underscore-dangle
    setSelectedJobs(
      filteredJobs.filter((job) => selectedJobIds.includes(job._id)),
    );
    const action = bulkActions.find((ba) => ba.id === id);
    if (action.select) action.select();
  };

  const handleSelect = (setModalOpen) => (modalOpen) => {
    setModalOpen(modalOpen);
    setSelectAll(false);
    setSelectedJobIds([]);
    setSelectedJobs([]);
  };
  const toggleCloseModal = handleSelect(setCloseCaseOpen);
  const toggleReassignModal = handleSelect(setReassignCaseOpen);

  const renderBulkActionsMenu = () => (
    <div className="select-all-case">
      {!_.isEmpty(selectedJobIds) && (
        <Menu
          defaultLabel="Bulk Actions"
          options={bulkActions}
          handleSelect={handleBulkActionRowSelect}
        />
      )}
    </div>
  );

  const memoizedFilteredJobs = useMemo(
    () => filteredJobs.map((c) => (
      <CaseRow
        key={c.id}
        setCaseSelected={setCaseSelected}
        isChecked={_.includes(selectedJobIds, c.id)}
        columns={columns.slice(0, columns.length - 2)}
        c={c}
        operators={assignedOperatorsPerJob}
        assignedOperator={c.assignedOperator}
        actions={getActions({
          isVerified: _.get(c, 'raw.customerId.verified'),
          isOpen: c.isOpen,
          job: c,
        })}
        {...c}
      />
    )),
    [
      assignedOperatorsPerJob,
      filteredJobs,
      getActions,
      selectedJobIds,
      setCaseSelected,
    ],
  );

  return (
    <div className="cases-page-container">
      <Panel
        className="cases-page"
        header={(
          <div className="button-row">
            {useCheckPermissions([DISABLE_CREATE_CASE]) ? (
              <div>
                <DisabledBanner bannerText="Creating a case is unavailable for your organization. Please go to your claims management system to create a new case." />
              </div>
            ) : <CreateCaseButton />}
            <div className="active-queue-buttons">
              {fnolUIEnabled && (
                <Checkbox
                  data-testid="fnol-in-progress-checkbox"
                  variant="reversed"
                  isChecked={properties?.fnolInProgress}
                  onChange={handleFnolToggle}
                >
                  FNOL In Progress
                </Checkbox>
              )}
              <AssignedFilter
                setProperties={setProperties}
                properties={properties}
              />
              <Menu
                sx={{
                  button: {
                    marginLeft: '0',
                  },
                }}
                defaultLabel={`Status: ${_.get(statusItem, 'label')}`}
                options={statusDropdownList.map((s) => ({
                  ...s,
                  rightIcon: s.id === _.get(statusItem, 'id') ? CheckmarkFilledIcon : undefined,
                }))}
                handleSelect={statusToggle}
                menuButtonProps={{ variant: 'ghost' }}
                matchWidth
              />
            </div>
          </div>
        )}
        title="Cases"
        right={(
          <SearchBar
            id="case"
            className="search-bar"
            placeholder={`Search ${_.get(statusItem, 'placeholderLabel')} cases`}
            value={searchFilter}
            onValueChange={setSearchFilter}
            onClear={() => setSearchFilter('')}
          />
        )}
      >
        {renderBulkActionsMenu()}
        <CloseCaseConfirmation
          show={closeCaseOpen}
          toggleModal={toggleCloseModal}
          selectedJobs={selectedJobs}
        />
        <ReassignCaseConfirmation
          show={reassignCaseOpen}
          toggleModal={toggleReassignModal}
          selectedJobs={selectedJobs}
        />
        <SendSurveyConfirmation id="case" />
        <SendEmailConfirmation id="case" />
        <SortTable
          id="case"
          label="cases"
          columns={caseColumns}
          increaseDataSet={increaseDataSet}
          isLoading={isLoading}
          rows={memoizedFilteredJobs}
        />
      </Panel>
    </div>
  );
};

Cases.propTypes = {
  authId: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  jobs: PropTypes.arrayOf(Object).isRequired,
  openDashboardView: PropTypes.func.isRequired,
  setActiveJob: PropTypes.func.isRequired,
  loadedItems: PropTypes.arrayOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  increaseDataSet: PropTypes.func.isRequired,
  clearAddFormErrors: PropTypes.func.isRequired,
  modifyQuery: PropTypes.func.isRequired,
  showErrorModal: PropTypes.bool.isRequired,
  jobPutRequest: PropTypes.func.isRequired,
  getFilteredOperatorIds: PropTypes.func.isRequired,
  filteredOperatorIds: PropTypes.instanceOf(Array).isRequired,
  permissions: PropTypes.shape([]).isRequired,
  sort: PropTypes.string.isRequired,
  type: PropTypes.shape({ lowercaseSingular: PropTypes.func }).isRequired,
  unsubscribeFromTopics: PropTypes.func.isRequired,
  subscribeToTopics: PropTypes.func.isRequired,
};

export { Cases };

export const mapDispatchToProps = {
  clearAddFormErrors,
  lazyLoad,
  openDashboardView,
  setActiveJob,
  sortItems,
  jobPutRequest,
  showSnackbar,
  closeSnackbar,
  subscribeToTopics,
  unsubscribeFromTopics,
  getFilteredOperatorIds,
};

export const mapStateToProps = (state) => ({
  ...selector(state, columns),
  type: caseType,
  sort: _.get(state, 'ui.sort.case'),
  filteredOperatorIds: _.get(state, 'jobs.filteredOperatorIds', []),
});

const CasesWithLoading = withLoading(Cases, { type: caseType });
const LoadedCases = withLazyLoad(CasesWithLoading, {
  type: caseType,
  disableInitialFetch: true,
  listLocation: 'jobs',
});

const ConnectedLoadedCases = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoadedCases);

export default ConnectedLoadedCases;
