import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { StateType } from '@app/types/reducer-state';

import { setExpandInboxSearchBar, updateSearch } from '../../actions/job';
import { updateUserProperties } from '../../actions/profile';
import { UPDATE_CHECKED_QUERY_PARAMETERS } from '../../constants/actions';
import { SUCCESS } from '../../constants/general';
import {
  generateFiltersFromQueryParams,
  queryParameters,
} from '../../helpers/urls';

const DEFAULT_FILTERS = {
  operatorIds: {},
  groups: {},
  items: {
    myChats: '',
    secondaryOperatorIds: 'My Secondary Assignments',
  },
};

export const useQueryParameters = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    retrievedProfileProperties,
    checkedQueryParameters,
    inboxFilters,
    auth,
  } = useSelector((state: StateType) => ({
    retrievedProfileProperties: state?.profile?.retrievedProfileProperties,
    checkedQueryParameters: state?.profile?.checkedQueryParameters,
    inboxFilters: state?.profile?.properties?.inboxFilters || {},
    auth: state?.auth,
  }));

  /**
   * Update filters from query parameters only on first
   * load of the chats page when the new inbox is enabled
   */
  useEffect(() => {
    const onChatsPage = location?.pathname.split('/').includes('chats');
    if (!onChatsPage
       || checkedQueryParameters === SUCCESS
       || retrievedProfileProperties !== SUCCESS) return;

    const queryParams = new URLSearchParams(location.search);

    // Persistent
    const operatorIdsQueryParam = queryParams.getAll(
      queryParameters.operatorIds,
    );
    const groupIdsQueryParam = queryParams.getAll(queryParameters.groupIds);
    const tagsQueryParam = queryParams.getAll(queryParameters.tags);
    const myChatsQueryParam = queryParams.get(queryParameters.myChats);
    const unassignedQueryParam = queryParams.get(queryParameters.unassigned);
    const secondaryChatsQueryParam = queryParams.get('secondaryOperatorIds');
    const refineByQueryParam = queryParams.get(queryParameters.refineBy);
    const sortQueryParam = queryParams.get(queryParameters.sort);
    const orderQueryParam = queryParams.get(queryParameters.order);

    // Not persistent
    const searchQueryParam = queryParams.get(queryParameters.search);

    const updateFilters = async () => {
      const filters = await generateFiltersFromQueryParams(
        {
          operatorIds: operatorIdsQueryParam,
          groupIds: groupIdsQueryParam,
          tags: tagsQueryParam,
          myChats: myChatsQueryParam,
          unassigned: unassignedQueryParam,
          secondaryOperatorIds: secondaryChatsQueryParam,
          refineBy: refineByQueryParam,
          sort: sortQueryParam,
          order: orderQueryParam,
        } as Parameters<typeof generateFiltersFromQueryParams>[0],
        inboxFilters,
      );
      dispatch(updateUserProperties({ inboxFilters: filters }));
    };

    // There is a query parameter that affects the persistent filter
    if (
      operatorIdsQueryParam?.length
      || groupIdsQueryParam?.length
      || tagsQueryParam?.length
      || unassignedQueryParam
      || myChatsQueryParam
    ) {
      updateFilters();
    } else if (Object.keys(inboxFilters).length === 0) {
      if (auth?.user?.firstName && auth?.user?.lastName) {
        DEFAULT_FILTERS.items.myChats = `${auth?.user?.firstName} ${auth?.user?.lastName}`;
        dispatch(updateUserProperties({ inboxFilters: DEFAULT_FILTERS }));
      } else {
        // If auth user is not set, don't update the inbox filters
        return;
      }
    }

    dispatch({ type: UPDATE_CHECKED_QUERY_PARAMETERS });

    // If a search query param is present, load it regardless of persistent filters
    if (searchQueryParam) {
      dispatch(
        updateSearch({
          search: searchQueryParam,
          searchDisplayValue: searchQueryParam,
        }),
      );
      dispatch(setExpandInboxSearchBar(true));
    }
  }, [
    dispatch,
    location?.pathname,
    location.search,
    retrievedProfileProperties,
    checkedQueryParameters,
    inboxFilters,
    auth?.user?.firstName,
    auth?.user?.lastName,
  ]);
};

export default useQueryParameters;
