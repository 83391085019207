declare global {
  interface Window {
    isLoadedInWKWebView?: boolean;
    loadedFromMobile?: boolean;
  }
}

const isLoadedFromMobile = (): boolean => window?.isLoadedInWKWebView
  || window?.loadedFromMobile
  || false;

const isLoadedFromHiMarleyConnect = (): boolean => window.self !== window.top
  && window.location.ancestorOrigins?.[0]?.startsWith('chrome-extension://')
  && window.location.ancestorOrigins[0].includes(process.env.CONNECT_EXTENSION_ID || 'cfmbeleemgicjlggjbddlmckdfllmfch');

export default isLoadedFromMobile;
export { isLoadedFromHiMarleyConnect };
