// eslint-disable-next-line import/prefer-default-export
export const getUnpopulatedTemplateKeys = (
  inputStr = '',
  templateKeys = new Set(),
) => {
  if (inputStr.trim().length === 0) {
    return [];
  }
  const unpopulatedTemplateKeys = new Set();
  let isToken = false;
  let token = '';
  for (let i = 0; i < inputStr.length; i += 1) {
    const char = inputStr[i];
    // start of token check
    if (char === '$') {
      isToken = true;
      continue;
    }
    if (isToken) {
      if (char.match(/[a-z]/i)) {
        token += char;
      } else {
        isToken = false;
        token = '';
        continue;
      }
      if (templateKeys.has(`$${token}`)) {
        unpopulatedTemplateKeys.add(`$${token}`);
      }
      if (inputStr[i + 1] === '$') {
        isToken = false;
        token = '';
      }
    }
  }
  return [...unpopulatedTemplateKeys];
};
