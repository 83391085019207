import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Button,
  Box,
  type MenuButtonProps,
} from '@chakra-ui/react';
import { CaretDownIcon, CaretUpIcon, SearchBar } from '@himarley/unity';
import debounce from 'lodash/debounce';
import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { lazyLoad } from '@app/actions/common';
import UserRow from '@app/components/chakra/user-row';
import { sortByAlphabetical } from '@app/helpers/sorting';
import { operatorType } from '@app/models/marleyTypes';
import { type User } from '@app/types/api/user';
import { type StateType } from '@app/types/reducer-state';

const getDropdownLabelItems = (auth: {
  user: {
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: string;
    title?: string;
    imageUrl?: string;
    _id?: string;
  };
}) => {
  const {
    firstName,
    lastName,
    email: authEmail,
    role: authRole,
    title: authTitle,
    imageUrl,
    _id: authId,
  } = auth.user;

  const me = firstName && lastName ? `${firstName} ${lastName} (me)` : 'Me';

  return [
    {
      id: authId,
      label: `Assign to ${me}`,
      name: me,
      email: authEmail,
      role: authRole,
      title: authTitle,
      imageUrl,
    },
    { id: 'unassigned', label: 'Mark as Unassigned' },
  ];
};

interface AssignUserProps {
  id: string;
  defaultLabel: string;
  rowId: string;
  selectedId: string;
  handleSelect: (id: string, rowId: string) => void;
  options: User[];
  size?: string;
  sx?: MenuButtonProps['sx'];
  width?: string;
  shouldPrefetch?: boolean;
  menuListSx?: Record<string, unknown>;
}

const debouncedLoadUsers = debounce((loadUsers: (
  params: { searchText: string }) => void, searchText: string) => {
  loadUsers({ searchText });
}, 300);

const AssignUser: React.FC<AssignUserProps> = ({
  id,
  defaultLabel,
  rowId,
  selectedId,
  handleSelect,
  options,
  size = 'sm',
  sx,
  width = '162px',
  shouldPrefetch = false,
  menuListSx = {},
}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldPrefetch) {
      dispatch(lazyLoad(operatorType, { offset: 0, limit: 20, active: true }, 'users'));
    }
  }, [dispatch, shouldPrefetch]);

  const {
    auth,
    users,
    recentlyAssignedOperators,
  } = useSelector(
    (state: StateType) => ({
      auth: state.auth,
      users: (state.operators?.list || []).sort((a, b) => sortByAlphabetical(a?.name, b?.name)),
      recentlyAssignedOperators: [
        ...(state.auth?.user ? [state.auth.user] : []),
        ...(state.profile?.properties?.recentlyAssignedOperators?.[id] || []),
      ]
        .map((operator: User) => ({
          id: operator.id || operator._id,
          label: operator.name || `${operator.firstName} ${operator.lastName}`,
          email: operator.email,
          title: operator.title,
          role: operator.role,
          imageUrl: operator.imageUrl,
        }))
        .filter(
          (operator, index, self) => index === self.findIndex((o) => o.id === operator.id),
        ),
    }),
  );

  const loadUsers = useCallback(
    (params?: { offset?: number; limit?: number; searchText?: string }) => dispatch(lazyLoad(operatorType, {
      ...params, offset: 0, limit: 20, active: true,
    }, 'users')),
    [dispatch],
  );

  const handleSearchChange = useCallback((value: string) => {
    setSearchFilter(value);
    if (value.trim()) {
      debouncedLoadUsers(loadUsers, value);
    } else {
      loadUsers({ offset: 0, limit: 20 });
    }
  }, [loadUsers]);

  const assignedOperatorsList = useMemo(() => options.map((item) => (item.id === auth.user._id
    ? { ...item, label: `${auth.user.firstName} ${auth.user.lastName} (me)` }
    : item)), [options, auth.user._id, auth.user.firstName, auth.user.lastName]);

  const operatorList = useMemo(() => users.map((user) => (user.id === auth.user._id
    ? {
      ...user,
      label: `Assign to ${auth.user.firstName} ${auth.user.lastName} (me)`,
      name: `${auth.user.firstName} ${auth.user.lastName} (me)`,
    }
    : user)), [users, auth.user._id, auth.user.firstName, auth.user.lastName]);

  const dropdownLabelItems = useMemo(() => getDropdownLabelItems(auth), [auth]);

  const defaultSelectedOperator = useMemo(
    () => [...assignedOperatorsList, ...dropdownLabelItems]
      .find((option) => option.id === selectedId),
    [assignedOperatorsList, dropdownLabelItems, selectedId],
  );

  const renderMenuItems = useCallback((list: User[]) => list
    .filter((option) => option.id !== selectedId && option.id !== auth.user._id)
    .map((option) => (
      <MenuItem
        data-dropdown-item={`item-${option.id}`}
        key={option.id}
        onClick={() => handleSelect(option.id || '', rowId)}
        value={option.id}
      >
        <UserRow option={option} />
      </MenuItem>
    )), [auth.user._id, handleSelect, rowId, selectedId]);

  return (
    <Menu isLazy data-testid="assign-user-action-menu">
      {({ isOpen }) => (
        <>
          <MenuButton
            id={id}
            data-testid="btn-assign-user-action-menu"
            rightIcon={<Icon as={isOpen ? CaretUpIcon : CaretDownIcon} />}
            as={Button}
            size={size}
            variant="outline"
            mr={2}
            width={width}
            textAlign="left"
            sx={sx}
          >
            <Box isTruncated maxW="60vw">
              {defaultSelectedOperator
                ? defaultSelectedOperator?.label || defaultSelectedOperator?.name
                : defaultLabel}
            </Box>
          </MenuButton>
          <MenuList
            w="400px"
            sx={{
              'max-height': '50vh',
              overflowY: 'auto',
              zIndex: 1500,
              ...menuListSx,
            }}
            data-testid="assign-user-action-menu-list"
          >
            <Box
              pb={1}
              px={3}
              w="100%"
              bg="white"
              pos="fixed"
              zIndex={1}
              paddingTop="5px"
              border="1px solid"
              borderColor="gray.200"
              top={0}
              sx={{
                div: {
                  width: 'unset',
                },
              }}
            >
              <SearchBar
                id="operators"
                placeholder="Search Operators"
                value={searchFilter}
                onValueChange={handleSearchChange}
                onClear={() => handleSearchChange('')}
              />
            </Box>
            <Box marginTop="30px">
              {dropdownLabelItems.map((option) => (
                <MenuItem
                  data-dropdown-item={`item-${option.id}`}
                  key={option.id}
                  isDisabled={option.id === selectedId}
                  onClick={() => handleSelect(option.id || '', rowId)}
                  value={option.id}
                >
                  <UserRow option={option} />
                </MenuItem>
              ))}
            </Box>
            {searchFilter === '' && renderMenuItems(recentlyAssignedOperators)}
            {renderMenuItems(operatorList)}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default AssignUser;
