import _ from 'lodash';

import { getCreationSource } from '@app/helpers/creation-source';
import { apiCall, cancelableRequest } from './api';
import { closeForm } from './ui';
import { createEndUser } from './users';
import { createCase } from './job';

import {
  GET_ITEMS, CREATE_ITEM, UPDATE_ITEM,
} from '../constants/endpoints';
import { userType, claimType } from '../models/marleyTypes';
import Claim from '../models/claim';
import Policy from '../models/policy';
import Template from '../models/template';
import User from '../models/user';
import Job from '../models/job';
import Group from '../models/group';

const modelMap = {
  CLAIM: Claim,
  POLICY: Policy,
  TEMPLATE: Template,
  USER: User,
  OPERATOR: User,
  GROUP: Group,
  JOB: Job,
  CASE: Job,
  SUBGROUP: Group,
};

const getParsedItems = (listLocation, itemsParam) => {
  let items = itemsParam;
  if (items?.groups && listLocation === 'subgroups') {
    items = { subgroups: items?.groups || [] };
  }
  return listLocation ? _.get(items, listLocation, []) : items;
};

export const parseItems = (items, type, listLocation = '') => {
  const Model = modelMap[type];
  const pItems = getParsedItems(listLocation, items);
  return pItems.map((i) => new Model(i));
};

export const filterItems = (payload) => ({ type: 'FILTER_ITEMS', payload });

export function getItems(type, listLocation, callback) {
  const args = {
    endpoint: GET_ITEMS(type),
    method: 'GET',
    action: `LOAD_${type}S`,
    process: ({ body }) => ({ items: parseItems(body, type, listLocation), refresh: true }),
    callback,
    initPayload: { refresh: true },
  };
  return apiCall({ args });
}
export function lazyLoad(type, query, listLocation, callback) {
  return (dispatch) => {
    const { offset } = query;
    const refresh = offset === 0;
    const args = {
      endpoint: GET_ITEMS(type.allUpperCase(), query),
      method: 'GET',
      action: `LOAD_${type.allUpperCase()}S`,
      process: ({ body }) => {
        if (callback) callback();
        return {
          items: parseItems(body, type.allUpperCase(), listLocation),
          refresh,
        };
      },
      initPayload: { refresh },
    };

    if (query.searchMessages || query.searchText || query.filter) {
      dispatch(
        cancelableRequest({
          ...args,
          cancelToken: () => {
          },
        }),
      );

      /* .then(() => {
        searchJobsRequestCanceler = null
      }).catch(() => {
        searchJobsRequestCanceler = null
      }) */
    } else {
      dispatch(apiCall({ args }));
    }
  };
}

export const addItem = (type, location) => (template) => {
  const Model = modelMap[type];
  return { type: `NEW_${type}`, payload: new Model(location ? _.get(template, location, {}) : template) };
};

export function createItem(type, data, location) {
  const addSource = ['POLICY', 'CLAIM', 'CASE'];
  if (addSource.indexOf(type) > -1) {
    data.creationSource = getCreationSource();
  }
  const args = {
    endpoint: CREATE_ITEM(type),
    method: 'POST',
    body: data,
    action: `ADD_${type}`,
    callback: ({ body, dispatch }) => {
      dispatch(closeForm(userType));
      if (type === 'CLAIM') dispatch(closeForm(claimType));
      return addItem(type, location)(body);
    },
  };
  return apiCall({ args });
}

export function createCaseWithUser({
  type, userData, data, userIdName, overrideType,
}) {
  if (userData.id) {
    const createItemData = { ...data };
    const languagePreference = _.get(userData, 'languagePreference', 'en');
    _.set(createItemData, userIdName || 'insuredId', userData.id);
    _.set(createItemData, 'languagePreference', languagePreference);
    _.set(createItemData, 'endUserNumber', userData.phone);
    _.set(createItemData, 'type', type);
    if (overrideType) _.set(createItemData, 'type', overrideType);
    return createCase(type, { ...createItemData });
  }
  return (dispatch) => dispatch(createEndUser(
    userData,
    ({ body }) => {
      const createItemData = { ...data };
      const languagePreference = _.get(userData, 'languagePreference', 'en');
      _.set(createItemData, userIdName || 'insuredId', body._id || body.id);
      _.set(createItemData, 'languagePreference', languagePreference);
      _.set(createItemData, 'endUserNumber', body.phoneNumber);
      _.set(createItemData, 'type', type);
      if (overrideType) _.set(createItemData, 'type', overrideType);
      return createCase(type, { ...createItemData });
    },
  ));
}

export const modifyItem = (type) => (template) => {
  const Model = modelMap[type];
  return { type: `MODIFIED_${type}`, payload: new Model(template) };
};

export const updateItem = (type, data, id, itemLocation) => {
  let endpoint = UPDATE_ITEM(type);
  if (id) { endpoint += `/${id}`; }
  const args = {
    endpoint,
    method: 'PUT',
    process: (t) => t,
    body: data,
    action: `EDIT_${type}`,
    callback: ({ body }) => modifyItem(type)(itemLocation ? _.get(body, itemLocation) : body),
  };
  return apiCall({ args });
};

export const handleFormInputChange = ({
  value, inputId, tableId, type,
}) => {
  const reducerCase = type ? type.reducerCase() : tableId.toUpperCase();
  const trimmedValue = ((typeof value) === 'string') ? value.trim() : value;
  return {
    type: `MODIFY_${reducerCase}_EDIT_OBJECT`,
    payload: { value: trimmedValue, id: inputId },
  };
};

export const addFormError = (id, error) => ({
  type: `ADD_${id}_ERROR`,
  payload: error,
});

export const clearAddFormErrors = (id) => ({ type: `CLEAR_${id}_ERRORS` });

export function setEditItem(type, id) {
  return { type: `SET_EDIT_${type.allUpperCase()}`, payload: id };
}

export function setIsEdit(type, value) {
  return { type: `SET_${type.allUpperCase()}_IS_EDIT`, payload: value };
}

export function setViewItem(type, id) {
  return { type: `SET_VIEW_${type.allUpperCase()}`, payload: id };
}

export const clearEditObject = ({ id, except }) => ({ type: `CLEAR_${id.toUpperCase()}_EDIT_OBJECT`, payload: except });
