import {
  WeeklyBusinessHours,
  EmailRecipient,
  TimezoneState,
} from '@app/types/workflow-settings';

import { defaultBusinessHours, DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES } from './constants';
import { WeeklyBusinessHoursForm, IWorkflowSettingsFormInput, WorkflowSettingsWithMetadata } from './types';

export const formatPhoneNumber = (phone: string): string => {
  // Remove all non-numeric characters
  const cleaned = phone.replace(/\D/g, '');

  // Check if it's a valid 10 or 11 digit number
  if (cleaned.length === 10) {
    return `1-${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  } if (cleaned.length === 11 && cleaned.startsWith('1')) {
    return `${cleaned[0]}-${cleaned.slice(1, 4)}-${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  }
  return phone; // Return original if not valid
};

export const validatePhoneNumber = (value: string) => {
  // Regex matches common US phone formats with or without leading 1
  const phoneRegex = /^(1[-\s.]?)?\(?[0-9]{3}\)?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;
  if (phoneRegex.test(value)) {
    return true;
  }
  return 'Please enter a valid phone number (e.g., (123) 456-7890 or 123-456-7890)';
};

export const mapEmailsToForm = (emails: EmailRecipient[]) => {
  const emailList = emails.reduce<string[]>((acc, email) => {
    if (email?.value && email?.enabled) {
      acc.push(email.value);
    }
    return acc;
  }, []);
  return emailList.length > 0 ? emailList : [''];
};

export const mapBusinessHoursToForm = (businessHours: WeeklyBusinessHours | null)
: WeeklyBusinessHoursForm => (
  Object.entries(defaultBusinessHours).reduce((acc, [key, value]) => {
    const businessDay = businessHours?.[key as keyof WeeklyBusinessHours];
    if (businessDay && businessDay.start && businessDay.end) {
      acc[key as keyof WeeklyBusinessHours] = {
        enabled: businessDay.start !== '00:00' && businessDay.end !== '00:00',
        startTime: businessDay.start,
        endTime: businessDay.end,
      };
    } else {
      acc[key as keyof WeeklyBusinessHours] = value;
    }
    return acc;
  }, defaultBusinessHours)
);

export const mapFormToEmails = (emails: string[]): EmailRecipient[] => (
  emails.filter(Boolean).map((email) => ({
    name: `Email - ${email}`,
    role: 'Email Inbox',
    value: email,
    enabled: true,
  }))
);

export const mapFormToBusinessHours = (
  businessHours: WeeklyBusinessHoursForm | null,
): WeeklyBusinessHours | null => (
  businessHours
    ? Object.entries(businessHours).reduce((acc, [key, value]) => {
      acc[key as keyof WeeklyBusinessHours] = {
        start: value.enabled ? value.startTime : '00:00',
        end: value.enabled ? value.endTime : '00:00',
      };
      return acc;
    }, {} as WeeklyBusinessHours)
    : null
);

export const mapFormToWorkflowSettingsAndTrigger = (
  formData: IWorkflowSettingsFormInput,
  organizationDisplayName: string,
) => ({
  keywordTrigger: formData.keywordTrigger,
  workflowSettings: {
    workflowName: formData.workflowName,
    redirectPhone: formatPhoneNumber(formData.redirectPhoneNumber),
    carrierDisplayName: formData.organizationNameType === 'default' ? organizationDisplayName : formData.organizationName,
    carrierPhone: formatPhoneNumber(formData.phoneNumber),
    carrierWebsite: formData.website,
    emailEnabled: formData.completionEmail,
    emailTo: mapFormToEmails(formData.completionEmailAddresses),
    singleOptInEnabled: formData.enableSingleOptIn,
    policyLookupEnabled: formData.enablePolicyLookup,
    claimSubmissionEnabled: formData.enableClaimSubmission,
    ...(formData.escalation && {
      escalation: {
        ...(formData.escalationEmailAddresses.length > 0 && {
          withinBusinessHours: {
            emailTo: mapFormToEmails(formData.escalationEmailAddresses),
          },
        }),
        ...(formData.escalationPhoneNumber && {
          outsideBusinessHours: {
            carrierEscalationNumber: (
              formData?.escalationPhoneNumber
                ? formatPhoneNumber(formData.escalationPhoneNumber)
                : formData.escalationPhoneNumber
            ),
          },
        }),
      },
    }),
    timeZone: formData.timezone,
    ...(formData.escalation && {
      businessHours: mapFormToBusinessHours(formData.escalationSchedule),
    }),
  },
});

export const getFormDefaultValues = (
  initialData: WorkflowSettingsWithMetadata | undefined,
  defaultOrganizationName: string,
): IWorkflowSettingsFormInput => {
  if (!initialData) {
    return {
      ...DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES,
      organizationName: defaultOrganizationName,
      organizationNameType: 'default',
    };
  }

  return {
    workflowName: initialData.workflowName ?? DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES.workflowName,
    keywordTrigger: (
      initialData.keywordTrigger ?? DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES.keywordTrigger
    ),
    redirectPhoneNumber: (
      initialData.redirectPhone ?? DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES.redirectPhoneNumber),
    organizationName: initialData.carrierDisplayName ?? defaultOrganizationName,
    phoneNumber: initialData.carrierPhone ?? DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES.phoneNumber,
    organizationNameType: initialData.carrierDisplayName !== defaultOrganizationName ? 'custom' : 'default',
    website: initialData.carrierWebsite ?? DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES.website,
    completionEmail: (
      initialData.emailEnabled ?? DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES.completionEmail
    ),
    completionEmailAddresses: mapEmailsToForm(initialData.emailTo || []),
    enableSingleOptIn: initialData.singleOptInEnabled ?? true,
    enablePolicyLookup: (
      initialData.policyLookupEnabled ?? DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES.enablePolicyLookup
    ),
    enableClaimSubmission: (
      initialData.claimSubmissionEnabled
      ?? DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES.enableClaimSubmission
    ),
    escalation: Boolean(initialData.escalation),
    timezone: initialData.timeZone ?? TimezoneState.AMERICA_NEW_YORK,
    escalationSchedule: mapBusinessHoursToForm(initialData.businessHours || null),
    escalationEmailAddresses: mapEmailsToForm(
      initialData.escalation?.withinBusinessHours?.emailTo || [],
    ),
    escalationPhoneNumber: (
      initialData.escalation?.outsideBusinessHours?.carrierEscalationNumber
      ?? DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES.escalationPhoneNumber
    ),
  };
};

export const generate24HoursInHoursAndMinutes = () => Array.from({ length: 96 }).map((_, i) => {
  const hour = Math.floor(i / 4);
  const minute = (i % 4) * 15;
  const period = hour >= 12 ? 'PM' : 'AM';
  const adjustedHours = hour > 12 ? hour - 12 : hour;
  const displayHour = hour === 0 ? 12 : adjustedHours;
  const timeValue = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
  const displayTime = `${displayHour}:${minute.toString().padStart(2, '0')} ${period}`;
  return {
    timeValue,
    displayTime,
  };
});
