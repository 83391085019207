import {
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useFormContext, type RegisterOptions } from 'react-hook-form';

import DateInput from '@app/components/chakra/date-input';
import { type IFormInput } from '@app/types/create-case';

interface DateFieldProps {
  name: keyof IFormInput;
  label: string;
  dateLimit?: 'past' | 'future';
  registerOptions?: RegisterOptions<IFormInput>;
  includeTime?: boolean;
}

const DateField = ({
  registerOptions,
  name,
  label,
  dateLimit = 'past',
  includeTime = false,
}: DateFieldProps) => {
  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<IFormInput>();

  useEffect(() => {
    register(name, registerOptions);
  }, [register, name, registerOptions]);

  const isRequired = registerOptions?.required;
  const value = getValues(name);
  const selectedDate = value ? new Date(value) : null;

  const handleDateChange = (date: Date | null) => {
    setValue(name, date ? date.toISOString() : '', { shouldValidate: true });
    trigger(name);
  };

  const handleClear = () => {
    setValue(name, '', { shouldValidate: true });
    trigger(name);
  };

  return (
    <FormControl id={name} isInvalid={!!errors[name]}>
      <FormLabel size="sm" cursor="default">
        {`${label}${isRequired ? ' (Required)' : ''}`}
      </FormLabel>
      <DateInput
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
        includeTime={includeTime}
        dateLimit={dateLimit}
        handleClear={handleClear}
      />
    </FormControl>
  );
};

export default DateField;
