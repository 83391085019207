import { Flex, Text } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import './EventMessage.less';

import { setTemplateTrayIsOpen as setTemplateTrayIsOpenAction } from '@app/actions/ui';
import { TagItem } from '@app/components/elements/TagItem/TagItem';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import TagItemTypes from '@app/constants/tag-item-types';
import { formatDate } from '@app/helpers/datetime';
import { capitalizeFirstLetter } from '@app/helpers/format';
import { StateType } from '@app/types/reducer-state';
import AlertIcon from '@images/icons/alert.svg';
import PartnerAlertIcon from '@images/icons/partner-chat-alert.svg';

const createEvent = (text: string, className: string) => (
  <div className={className} data-test="gray-event">
    {text}
  </div>
);

const setTemplateTray = (
  templateTrayIsOpen: boolean,
  setTemplateTrayIsOpen: (templateTrayIsOpen: boolean, chatInput: string) => void,
) => {
  if (!templateTrayIsOpen) {
    setTemplateTrayIsOpen(true, 'CHAT_INPUT');
  }
};

const createCopartReleaseIssue = ({
  releaseIssueText,
  releaseIssueDescription,
  timestamp,
  resolved,
  templateTrayIsOpen,
  showTagItem,
  setTemplateTrayIsOpen,
  isSmallScreen,
}: {
  releaseIssueText: string;
  releaseIssueDescription: string;
  timestamp: string;
  resolved: boolean;
  templateTrayIsOpen: boolean;
  showTagItem: boolean;
  setTemplateTrayIsOpen: (templateTrayIsOpen: boolean, chatInput: string) => void;
  isSmallScreen: boolean;
}) => (
  <div className="copart-event-container" data-testid="copart-event-container">
    <div className="left-side">
      <div className="partner-alert-icon">
        <PartnerAlertIcon />
      </div>
      <div className={!releaseIssueDescription ? 'copart-single-line' : undefined}>
        <div className="copart-release-issue-text">
          <div className="text" data-test="release-issue">
            <b>{releaseIssueText}</b>
          </div>
          {!isSmallScreen && (
            <div className="timestamp" data-test="timestamp">
              {timestamp}
            </div>
          )}
        </div>
        {releaseIssueDescription && (
        <div className="copart-release-issue-description" data-test={`release-description-${resolved ? 'resolved' : 'open'}`}>
          {releaseIssueDescription}
        </div>
        )}
        {isSmallScreen && (
        <div className="timestamp" data-test="timestamp">
          {timestamp}
        </div>
        )}
      </div>
    </div>
    <div className="right-side">
      {
        showTagItem && (
          <TagItem
            text={isSmallScreen ? null : TagItemTypes.Case.ReleaseIssue.text}
            textColor="#000"
            backgroundColor={
              resolved
                ? TagItemTypes.Case.ReleaseIssue.resolvedColor
                : TagItemTypes.Case.ReleaseIssue.unresolvedColor
            }
            icon={<AlertIcon />}
          />
        )
      }
      {!resolved && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className="template-link" data-test="template-link" onClick={() => setTemplateTray(templateTrayIsOpen, setTemplateTrayIsOpen)}>
          View Suggested Responses
        </div>
      )}
    </div>
  </div>
);

const partnerEvent = (text: string, timestamp: string) => (
  <div className="partner-event-message-container">
    <div className="partner-alert-icon">
      <PartnerAlertIcon />
    </div>
    <div className="partner-event-message">
      <div className="text" data-test="display-text">
        <b>{text}</b>
      </div>
      <div className="timestamp" data-test="timestamp">
        {timestamp}
      </div>
    </div>
  </div>
);

const surveyEvent = (text: string, timestamp: string) => (
  <Flex justify="center" align="center" p="8px">
    <Text color="blue.60">
      {`${text} on ${formatDate(timestamp, 'standard-lowercase')}`}
    </Text>
  </Flex>
);

interface EventMessageProps {
  text: string;
  message: {
    additionalInfo: {
      eventType: string;
    };
    createdAt: string;
  };
  templateTrayIsOpen: boolean;
  setTemplateTrayIsOpen: (templateTrayIsOpen: boolean, chatInput: string) => void;
}

const EventMessage: React.FC<EventMessageProps> = ({
  text,
  message,
  templateTrayIsOpen,
  setTemplateTrayIsOpen,
}) => {
  const displayText = text;
  const isSmallScreen = useScreenSize();
  const eventType = _.get(message, 'additionalInfo.eventType', 'default');
  let className = 'has-event-message';
  if (eventType === 'mass-notification-initial-event') {
    className = 'has-event-message-mass-note';
  }
  const timestamp = formatDate(message.createdAt, 'date-time');
  if (eventType === 'copart-gray-event') {
    const [releaseIssueText, releaseIssueDescription] = text.split(': ');
    const resolved = releaseIssueText.includes('Resolved');
    const showTagItem = !!releaseIssueDescription;
    return createCopartReleaseIssue({
      releaseIssueText,
      releaseIssueDescription,
      timestamp,
      resolved,
      showTagItem,
      templateTrayIsOpen,
      setTemplateTrayIsOpen,
      isSmallScreen,
    });
  }
  if (eventType === 'xact-analysis-gray-event' || eventType === 'partner-gray-event') {
    return partnerEvent(displayText, timestamp);
  }
  if (eventType.startsWith('survey')) {
    const formattedDisplayText = capitalizeFirstLetter(displayText);
    return surveyEvent(formattedDisplayText, message.createdAt);
  }
  return createEvent(displayText, className);
};

const mapDispatchToProps = {
  setTemplateTrayIsOpen: setTemplateTrayIsOpenAction,
};

const mapStateToProps = (state: StateType) => ({
  templateTrayIsOpen: state.ui.templateTrayIsOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(EventMessage);
