import { Avatar } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { groupType } from '@app/models/marleyTypes';
import { type StateType } from '@app/types/reducer-state';

import SearchableDropdown from '../ChatsContainer/ChatInbox/InboxHeader/QuickFilter/searchable-dropdown';
import withLazyLoad from '../withLazyLoad/withLazyLoad';

interface GroupDropdownProps {
  onChange: (groups: Map<string, string>) => void;
  modifyQuery: (params: ParamsType) => void;
  increaseDataSet: () => void;
}

interface ParamsType {
  searchText?: string;
}

const GroupDropdown: React.FC<GroupDropdownProps> = ({
  onChange,
  modifyQuery,
  increaseDataSet,
}) => {
  const [selectedGroups, setSelectedGroups] = useState(new Map());

  const groups = useSelector(
    (state: StateType) => state.groups?.list || [],
  ).map((group) => ({
    id: group.id || '',
    label: group.name,
    avatar: (
      <Avatar
        size="xs"
        name={group.name}
        borderRadius="full"
        bg="marleyRed.500"
        color="white"
      />
    ),
  }));

  const handleSearch = useCallback(
    (search: string) => {
      const searchText = search?.length > 0 ? search : undefined;
      modifyQuery({ searchText });
    },
    [modifyQuery],
  );

  const handleGroupSelect: React.Dispatch<
  React.SetStateAction<Map<string, string>>
  > = (value) => {
    const newSelectedGroups = typeof value === 'function' ? value(selectedGroups) : value;
    setSelectedGroups(newSelectedGroups);
    onChange(newSelectedGroups);
  };
  return (
    <SearchableDropdown
      items={groups}
      selectedItemsMap={selectedGroups}
      setSelectedItems={handleGroupSelect}
      handleSearch={handleSearch}
      defaultLabel="All"
      entityLabel="Groups"
      increaseDataSet={increaseDataSet}
    />
  );
};

const GroupDropdownWithLazyLoad = withLazyLoad(GroupDropdown, {
  type: groupType,
  listLocation: 'groups',
});

export default GroupDropdownWithLazyLoad;
