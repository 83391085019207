/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import PropTypes from 'prop-types'

import { Translate } from 'react-redux-i18n'
import { Link } from 'react-router'
import PermissionVerifier from '../PermissionVerifier/PermissionVerifier'
import WithDot from '../WithDot/WithDot'

const NavItem = ({
  id, Icon, label, neededPermissions, isActive, onClick, showDot,
}) => (
  <PermissionVerifier key={id} neededPermissions={neededPermissions}>
    <Link
      key={id}
      to={`/${id}`}
      onClick={onClick}
      className="menu-item"
    >
      <WithDot show={showDot} glow>
        <div className={`menu-icon ${isActive ? 'selected' : ''}`}>
          <Icon className="icon" fill={isActive ? '#FFFFFF' : '#231F20'} />
        </div>
        <div className="menu-label"><Translate value={label} /></div>
      </WithDot>
    </Link>
  </PermissionVerifier>
)
NavItem.propTypes = {
  id: PropTypes.string.isRequired,
  Icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  neededPermissions: PropTypes.arrayOf(PropTypes.string),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  showDot: PropTypes.bool,
}

NavItem.defaultProps = {
  neededPermissions: [],
  isActive: false,
  onClick: () => {},
  showDot: false,
}

export default NavItem
