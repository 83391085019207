import { Tab, Tabs } from '@himarley/unity';
import React, {
  SetStateAction, Dispatch, useEffect, useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router';

import LoBIcon from '@app/components/Settings/CompanyHierarchy/lob-icon';
import WelcomeFlowSettings from '@app/components/Settings/WelcomeFlowSettings/welcome-flow-settings';
import { capitalizeEachWord } from '@app/helpers/format';
import Caret from '@images/icons/large-caret.svg';

import { LOB_TAB_NAMES } from './helpers';
import styles from './LinesOfBusiness.module.less';
import MessageTemplates from '../MessageTemplates/MessageTemplates';

interface LobOptInMessageProps {
  lob: {
    _id: string;
    type: string;
    displayName: string;
    subType: string;
    branding: string;
  };
  setSelectedLob: Dispatch<SetStateAction<string | null>>
}

const LoBOptInMessage: React.FC<LobOptInMessageProps> = ({ lob, setSelectedLob }) => {
  const history = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<string | null>(LOB_TAB_NAMES.welcomeMessages);

  useEffect(() => {
    const urlLocation = location?.pathname?.split('/')?.pop();
    setSelectedTab(urlLocation ? LOB_TAB_NAMES[urlLocation] : LOB_TAB_NAMES.welcomeMessages);
  }, [location]);

  const handleTabSelect = (tab: string) => {
    if (tab && lob?._id) {
      history(`/usermgmt/linesOfBusiness/${lob?._id}/${tab}`);
    }
  };

  const lineOfBusinessName = `${capitalizeEachWord(lob?.type)} ${capitalizeEachWord(
    lob?.displayName,
  )}`;

  const instructionText = `Configure text messages automatically sent to a customer when a new claim is opened for your ${lineOfBusinessName} line of business.`;

  return (
    <section data-testid="lobPage" className={styles.lobPage}>
      <header className={styles.optInHeader}>
        <div className={styles.nameType}>
          <div className={styles.lobIcon}>
            <LoBIcon name={lob?.subType} />
          </div>
          <div>
            <h3>
              {lob?.type}
              {' '}
              {lob?.displayName}
            </h3>
            <span>{lob?.branding}</span>
          </div>
        </div>
        <div>
          <button className={styles.back} onClick={() => setSelectedLob(null)} type="button">
            <span>
              <Caret />
              {' '}
              Back to Lines
            </span>
          </button>
        </div>
      </header>
      <Tabs
        defaultActiveKey={selectedTab}
        onSelect={handleTabSelect}
      >
        <Tab eventKey={LOB_TAB_NAMES.welcomeMessages} title="Welcome Messages">
          <WelcomeFlowSettings
            tokenOverrideMap={{ $companyName: lob?.branding }}
            lineOfBusiness={lob}
            instructionText={instructionText}
          />
        </Tab>
        <Tab eventKey={LOB_TAB_NAMES.templates} title="Message Templates">
          <MessageTemplates
            lineOfBusiness={lob}
          />
        </Tab>
      </Tabs>
    </section>
  );
};

export default LoBOptInMessage;
