/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import {
  TrayContainer, SearchBar, Tabs, Tab, Ghosts,
} from '@himarley/unity';
import styles from './MessageTemplateModal.module.less';
import {
  TEMPLATE_TAB_LOB,
  TEMPLATE_TAB_ORG,
  messageTemplateDataPropTypes,
} from './template-helpers';
import MessageTemplateList from './MessageTemplateList';
import EmptyPlaceholder from './EmptyPlaceholder';
import { capitalizeEachWord } from '../../../helpers/format';
import isLoadedFromMobile from '@app/helpers/platform';

const MessageTemplateModal = ({
  activeJob,
  modal,
  messageTemplates,
  requestState,
  onSearchTextChange,
  onPinButtonClick,
  searchTextValue,
  onModalClose,
  onTemplateSelect,
  activeTab,
  setActiveTab,
}) => {
  const renderTemplateLists = () => {
    const { suggested, pinned, templates } = messageTemplates;
    const templateCount = pinned.count + templates.count + suggested.count;
    switch (requestState) {
      case 'loading':
        return (
          <div className={styles.ghostRows} data-testid="ghost-rows-container">
            <Ghosts type="card" />
          </div>
        );
      case 'failed':
        return (
          <div data-testid="message-templates-failed">
            Could not fetch templates
          </div>
        );
      case 'success':
        if (templateCount === 0) {
          if (searchTextValue?.trim()) {
            return (
              <EmptyPlaceholder
                text="We're looking, but no templates found."
              />
            );
          }
          if (activeJob.lineOfBusiness) {
            return (
              <EmptyPlaceholder
                text={`There aren’t any message templates for the ${capitalizeEachWord(
                  activeJob.lineOfBusiness?.displayName,
                )} line of business.`}
                buttonText="View Org. Templates"
                onClick={() => {
                  setActiveTab(TEMPLATE_TAB_ORG);
                }}
              />
            );
          }
          return (
            <EmptyPlaceholder text="There aren't any message templates for your organization." />
          );
        }
        return (
          <>
            <MessageTemplateList
              label="suggested"
              messageTemplates={suggested}
              onTemplateSelect={onTemplateSelect}
              onPinButtonClick={null}
            />
            <>
              <MessageTemplateList
                label="pinned"
                messageTemplates={pinned}
                onTemplateSelect={onTemplateSelect}
                onPinButtonClick={onPinButtonClick}
                isPinned
              />
              <MessageTemplateList
                label="all"
                messageTemplates={templates}
                onTemplateSelect={onTemplateSelect}
                onPinButtonClick={onPinButtonClick}
              />
            </>
          </>
        );
      default:
        return null;
    }
  };
  const renderMessageListContainer = (templateType = '') => {
    return (
      <>
        <SearchBar
          className={styles.searchbarMessageTemplates}
          onValueChange={onSearchTextChange}
          value={searchTextValue}
          onClear={() => onSearchTextChange('')}
          placeholder={`Search ${
            templateType ? `${templateType} ` : ''
          }Templates...`}
        />
        <div
          className={styles.templateList}
          data-testid="message-template-list"
        >
          {renderTemplateLists()}
        </div>
      </>
    );
  };

  return (
    (modal.hasTransitionedIn || modal.isMounted) && (
      <TrayContainer
        show
        title="Message Templates"
        size="auto"
        onCancel={onModalClose}
        className={`${isLoadedFromMobile() && styles.mobileMessageTray} ${styles.messagesTray} ${
          !modal.isMounted && 'animation-slide-dismiss'
        } ${modal.isMounted && 'animation-slide-left'} ${styles.messageTray}`}
        showButtonRow={false}
      >
        {activeJob.lineOfBusiness ? (
          <Tabs
            defaultActiveKey={activeTab}
            onSelect={(tabName) => {
              setActiveTab(tabName);
            }}
            type="content"
            justify
            showTabBar
          >
            <Tab
              title={`${activeJob.lineOfBusiness?.displayName} Templates`}
              eventKey={TEMPLATE_TAB_LOB}
            >
              {renderMessageListContainer(
                activeJob.lineOfBusiness?.displayName,
              )}
            </Tab>
            <Tab title="Org Templates" eventKey={TEMPLATE_TAB_ORG}>
              {renderMessageListContainer()}
            </Tab>
          </Tabs>
        ) : (
          <div className={styles.messageList}>
            {renderMessageListContainer()}
          </div>
        )}
      </TrayContainer>
    )
  );
};

MessageTemplateModal.propTypes = {
  activeJob: PropTypes.shape({
    id: PropTypes.string,
    lineOfBusiness: {
      displayName: PropTypes.string,
    },
  }).isRequired,
  modal: PropTypes.shape({
    isMounted: PropTypes.bool.isRequired,
    hasTransitionedIn: PropTypes.bool.isRequired,
  }).isRequired,
  messageTemplates: messageTemplateDataPropTypes.isRequired,
  onSearchTextChange: PropTypes.func.isRequired,
  onPinButtonClick: PropTypes.func,
  searchTextValue: PropTypes.string.isRequired,
  requestState: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onTemplateSelect: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired,
};

MessageTemplateModal.defaultProps = {
  onPinButtonClick: () => {},
  activeTab: '',
};

export default MessageTemplateModal;
