import React from 'react';

import MarleyLogo from '@images/icons/hi-marley_logo_photo.svg';
// Full logo imports
import ageroLogo from '@images/icons/partners/logo/agero-logo.svg';
import alacrityLogo from '@images/icons/partners/logo/alacrity-logo.svg';
import aleLogo from '@images/icons/partners/logo/ale-logo.svg';
import cambridgemobileLogo from '@images/icons/partners/logo/cambridgemobiletelematics-logo.svg';
import cccLogo from '@images/icons/partners/logo/ccc-logo.svg';
import copartLogo from '@images/icons/partners/logo/copart-logo.svg';
import crawfordLogo from '@images/icons/partners/logo/crawford-logo.svg';
import duckcreekLogo from '@images/icons/partners/logo/duckcreek-logo.svg';
import eagleviewLogo from '@images/icons/partners/logo/eagleview-logo.svg';
import enterpriseLogo from '@images/icons/partners/logo/enterprise-logo.svg';
import frissLogo from '@images/icons/partners/logo/friss-logo.svg';
import gerberLogo from '@images/icons/partners/logo/gerber-logo.svg';
import guidewireLogo from '@images/icons/partners/logo/guidewire-logo.svg';
import honkLogo from '@images/icons/partners/logo/honk-logo.svg';
import hoverLogo from '@images/icons/partners/logo/hover-logo.svg';
import iaaLogo from '@images/icons/partners/logo/iaa-logo.svg';
import lexisnexisLogo from '@images/icons/partners/logo/lexisnexis-logo.svg';
import medalliaLogo from '@images/icons/partners/logo/medallia-logo.svg';
import oneincLogo from '@images/icons/partners/logo/oneinc-logo.svg';
import opentextLogo from '@images/icons/partners/logo/opentext-logo.svg';
import opterrixLogo from '@images/icons/partners/logo/opterrix-logo.svg';
import pauldavisLogo from '@images/icons/partners/logo/pauldavis-logo.svg';
import qualtricsLogo from '@images/icons/partners/logo/qualtrics-logo.svg';
import safeliteLogo from '@images/icons/partners/logo/safelite-logo.svg';
import salesforceLogo from '@images/icons/partners/logo/salesforce-logo.svg';
import sapiensLogo from '@images/icons/partners/logo/sapiens-logo.svg';
import seeknowLogo from '@images/icons/partners/logo/seeknow-logo.svg';
import servproLogo from '@images/icons/partners/logo/servpro-logo.svg';
import shiftLogo from '@images/icons/partners/logo/shift-logo.svg';
import smartcommunicationsLogo from '@images/icons/partners/logo/smartcommunications-logo.svg';
import veriskLogo from '@images/icons/partners/logo/verisk-logo.svg';
// Mark/thumbnail imports
import ageroMark from '@images/icons/partners/mark/agero-mark.svg';
import alacrityMark from '@images/icons/partners/mark/alacrity-mark.svg';
import aleMark from '@images/icons/partners/mark/ale-mark.svg';
import cambridgemobileMark from '@images/icons/partners/mark/cambridgemobiletelematics-mark.svg';
import cccMark from '@images/icons/partners/mark/ccc-mark.svg';
import copartMark from '@images/icons/partners/mark/copart-mark.svg';
import crawfordMark from '@images/icons/partners/mark/crawford-mark.svg';
import duckcreekMark from '@images/icons/partners/mark/duckcreek-mark.svg';
import eagleviewMark from '@images/icons/partners/mark/eagleview-mark.svg';
import enterpriseMark from '@images/icons/partners/mark/enterprise-mark.svg';
import frissMark from '@images/icons/partners/mark/friss-mark.svg';
import gerberMark from '@images/icons/partners/mark/gerber-mark.svg';
import guidewireMark from '@images/icons/partners/mark/guidewire-mark.svg';
import honkMark from '@images/icons/partners/mark/honk-mark.svg';
import hoverMark from '@images/icons/partners/mark/hover-mark.svg';
import iaaMark from '@images/icons/partners/mark/iaa-mark.svg';
import lexisnexisMark from '@images/icons/partners/mark/lexisnexis-mark.svg';
import medalliaMark from '@images/icons/partners/mark/medallia-mark.svg';
import oneincMark from '@images/icons/partners/mark/oneinc-mark.svg';
import opentextMark from '@images/icons/partners/mark/opentext-mark.svg';
import opterrixMark from '@images/icons/partners/mark/opterrix-mark.svg';
import pauldavisMark from '@images/icons/partners/mark/pauldavis-mark.svg';
import qualtricsMark from '@images/icons/partners/mark/qualtrics-mark.svg';
import safeliteMark from '@images/icons/partners/mark/safelite-mark.svg';
import salesforceMark from '@images/icons/partners/mark/salesforce-mark.svg';
import sapiensMark from '@images/icons/partners/mark/sapiens-mark.svg';
import seeknowMark from '@images/icons/partners/mark/seeknow-mark.svg';
import servproMark from '@images/icons/partners/mark/servpro-mark.svg';
import shiftMark from '@images/icons/partners/mark/shift-mark.svg';
import smartcommunicationsMark from '@images/icons/partners/mark/smartcommunications-mark.svg';
import veriskMark from '@images/icons/partners/mark/verisk-mark.svg';

const logoMap = {
  agero: { logo: ageroLogo, mark: ageroMark },
  alacrity: { logo: alacrityLogo, mark: alacrityMark },
  ale: { logo: aleLogo, mark: aleMark },
  cambridgemobiletelematics: { logo: cambridgemobileLogo, mark: cambridgemobileMark },
  ccc: { logo: cccLogo, mark: cccMark },
  copart: { logo: copartLogo, mark: copartMark },
  crawford: { logo: crawfordLogo, mark: crawfordMark },
  duckcreek: { logo: duckcreekLogo, mark: duckcreekMark },
  eagleview: { logo: eagleviewLogo, mark: eagleviewMark },
  enterprise: { logo: enterpriseLogo, mark: enterpriseMark },
  friss: { logo: frissLogo, mark: frissMark },
  gerber: { logo: gerberLogo, mark: gerberMark },
  guidewire: { logo: guidewireLogo, mark: guidewireMark },
  honk: { logo: honkLogo, mark: honkMark },
  hover: { logo: hoverLogo, mark: hoverMark },
  iaa: { logo: iaaLogo, mark: iaaMark },
  lexisnexis: { logo: lexisnexisLogo, mark: lexisnexisMark },
  medallia: { logo: medalliaLogo, mark: medalliaMark },
  oneinc: { logo: oneincLogo, mark: oneincMark },
  opentext: { logo: opentextLogo, mark: opentextMark },
  opterrix: { logo: opterrixLogo, mark: opterrixMark },
  pauldavis: { logo: pauldavisLogo, mark: pauldavisMark },
  qualtrics: { logo: qualtricsLogo, mark: qualtricsMark },
  safelite: { logo: safeliteLogo, mark: safeliteMark },
  salesforce: { logo: salesforceLogo, mark: salesforceMark },
  sapiens: { logo: sapiensLogo, mark: sapiensMark },
  seeknow: { logo: seeknowLogo, mark: seeknowMark },
  servpro: { logo: servproLogo, mark: servproMark },
  shift: { logo: shiftLogo, mark: shiftMark },
  smartcommunications: { logo: smartcommunicationsLogo, mark: smartcommunicationsMark },
  verisk: { logo: veriskLogo, mark: veriskMark },
};

export type PartnerLogoType = keyof typeof logoMap;

interface LoadPartnerIconProps {
  partner: string;
  isThumbnail?: boolean;
}
const LoadPartnerIcon: React.FC<LoadPartnerIconProps> = ({ partner, isThumbnail = true }) => {
  const partnerName = partner?.toLowerCase();
  const partnerLogo = logoMap[partnerName as PartnerLogoType];
  let PartnerLogo = MarleyLogo;
  let testId = 'marley-logo';

  if (partnerLogo) {
    PartnerLogo = isThumbnail ? partnerLogo.mark : partnerLogo.logo;
    testId = `${partnerName}-${isThumbnail ? 'mark' : 'logo'}`;
  }

  return (
    <div data-testid={testId}>
      <PartnerLogo />
    </div>
  );
};

export default LoadPartnerIcon;
