import React, { useCallback, useState } from 'react';
import { SearchBar } from '@himarley/unity';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { flushSync } from 'react-dom';
import SearchIcon from '../../../../../../images/icons/search2.svg';
import styles from './CollapsibleSearchBar.module.less';
import { FILTER_DEBOUNCE } from '../../../../../constants/config';
import { setExpandInboxSearchBar, updateSearch } from '../../../../../actions/job';

const CollapsibleSearchBar = () => {
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const {
    searchDisplayValue,
    expandInboxSearchBar,
  } = useSelector((state) => ({
    search: state?.jobs?.search,
    searchDisplayValue: state?.jobs?.searchDisplayValue,
    expandInboxSearchBar: state?.jobs?.expandInboxSearchBar,
  }));

  const dispatch = useDispatch();
  const history = useNavigate();

  const handleSearchChange = useCallback((text) => {
    // Update the value displayed in the search bar
    dispatch(updateSearch({
      searchDisplayValue: text,
    }));

    // Update the value that will cause the query to re-run, after debounce
    clearTimeout(debounceTimeout);
    setDebounceTimeout(
      setTimeout(
        () => {
          dispatch(
            updateSearch({
              search: text,
            }),
          );
        },
        FILTER_DEBOUNCE,
      ),
    );
  }, [
    dispatch,
    debounceTimeout,
  ]);

  const handleSearchBarExpansion = (isExpanded) => {
    if (document?.startViewTransition) {
      flushSync(() => {
        document.startViewTransition(() => {
          dispatch(setExpandInboxSearchBar(isExpanded));
        });
      });
    } else {
      dispatch(setExpandInboxSearchBar(isExpanded));
    }
  };

  if (expandInboxSearchBar) {
    return (
      <div
        className={styles.expandedSearchContainer}
        data-testid="chat-inbox-expanded-search-bar-container"
      >
        <SearchBar
          className={styles.searchBar}
          placeholder="Search Inbox..."
          dataTestId="expanded-search-bar"
          value={searchDisplayValue}
          onValueChange={handleSearchChange}
          onClear={() => {
            dispatch(updateSearch({ search: null, searchDisplayValue: '' }));
            history({ search: '' });
          }}
          shouldAutoFocus
        />
        <button
          type="button"
          className={styles.cancelButton}
          onClick={() => {
            dispatch(updateSearch({ search: null, searchDisplayValue: '' }));
            handleSearchBarExpansion(false);
            history({ search: '' });
          }}
          data-testid="chat-inbox-cancel-button-text"
        >
          Cancel
        </button>
      </div>
    );
  }
  return (
    <div
      className={styles.collapsedSearchContainer}
      data-testid="chat-inbox-collapsed-search-bar-container"
    >
      <button
        type="button"
        className={styles.searchText}
        onClick={() => handleSearchBarExpansion(true)}
        data-testid="chat-inbox-search-button-text"
      >
        Search
      </button>
      <SearchIcon
        className={styles.searchIcon}
        onClick={() => handleSearchBarExpansion(true)}
        data-testid="chat-inbox-search-button-icon"
      />
    </div>
  );
};

export default CollapsibleSearchBar;
