import {
  Flex, Box, Heading, VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect, useMemo, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { showSnackbar as showSnackbarAction, closeSnackbar as closeSnackbarAction } from '@app/actions/notification';
import {
  getUserProfile as getUserProfileAction,
  getListOfTitles as getListOfTitlesAction,
  getAutoReplies as getAutoRepliesAction,
} from '@app/actions/profile';
import { setConfigItem as setConfigItemAction } from '@app/actions/ui';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import { StateType } from '@app/types/reducer-state';

import AutoReplies from './AutoReplies/AutoReplies';
import ChangePassword from './ChangePassword/change-password';
import CustomerFeedback from './CustomerFeedback/customer-feedback';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import EmailConfig from './EmailConfig/EmailConfig';
import GeneralInfo from './GeneralInfo/general-info';
import UserNotificationsSettings from './UserNotifications/UserNotificationsSettings';
import './ProfileConfig.less';
import {
  GENERAL_INFO,
  CUSTOMER_FEEDBACK,
  CHANGE_PASSWORD,
  EMAIL_CONFIGURATION,
  AUTO_REPLIES,
  NOTIFICATIONS,
} from '../../constants/configState';

const menuItems: {
  [key: string]: {
    id: string;
    title: string;
    view: React.FC;
    newView?: boolean;
  };
} = {};
menuItems[GENERAL_INFO] = {
  id: GENERAL_INFO,
  title: 'Profile Information',
  view: GeneralInfo,
};
menuItems[NOTIFICATIONS] = {
  id: NOTIFICATIONS,
  title: 'Notifications',
  view: UserNotificationsSettings,
};
menuItems[AUTO_REPLIES] = {
  id: AUTO_REPLIES,
  title: 'Auto Replies',
  view: AutoReplies,
};
menuItems[CUSTOMER_FEEDBACK] = {
  id: CUSTOMER_FEEDBACK,
  title: 'Customer Feedback',
  view: CustomerFeedback,
};
menuItems[CHANGE_PASSWORD] = {
  id: CHANGE_PASSWORD,
  title: 'Change Password',
  view: ChangePassword,
};

menuItems[EMAIL_CONFIGURATION] = {
  id: EMAIL_CONFIGURATION,
  title: 'E-mail Configuration',
  // Email config has no default export, this will probably be undefined
  view: EmailConfig,
  newView: true,
};

const mapDispatchToProps = {
  setConfigItem: setConfigItemAction,
  getUserProfile: getUserProfileAction,
  showSnackbar: showSnackbarAction,
  closeSnackbar: closeSnackbarAction,
  getListOfTitles: getListOfTitlesAction,
  getAutoReplies: getAutoRepliesAction,
};

const mapStateToProps = (state: StateType) => ({
  configId: _.get(state, 'ui.configId'),
  authUserId: _.get(state, 'auth.user._id'),
  profileUserId: _.get(state, 'profile._id', ''),
  snackBar: _.get(state, 'notification.snackBar'),
  identityProvider: _.get(state, 'auth.user.organization.identityProvider', 'MARLEY'),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ProfileConfig: React.FC<PropsFromRedux> = ({
  configId,
  authUserId,
  profileUserId,
  snackBar,
  showSnackbar,
  getUserProfile,
  closeSnackbar,
  setConfigItem,
  identityProvider,
  getListOfTitles,
  getAutoReplies,
}) => {
  const snackBarText = 'You are editing another user,';
  const isSmallScreen = useScreenSize();

  const RightView = menuItems[configId || GENERAL_INFO].view;

  const menus = useMemo(() => [
    { id: GENERAL_INFO, title: 'General Info', item: menuItems[GENERAL_INFO] },
    {
      id: CUSTOMER_FEEDBACK,
      title: 'Customer Feedback',
      item: menuItems[CUSTOMER_FEEDBACK],
    },
    { id: NOTIFICATIONS, title: 'Notifications', item: menuItems[NOTIFICATIONS] },
    { id: AUTO_REPLIES, title: 'Auto Replies', item: menuItems[AUTO_REPLIES] },
    {
      id: CHANGE_PASSWORD,
      title: 'Change Password',
      item: menuItems[CHANGE_PASSWORD],
    },
  ], []);

  const menusFiltered = useMemo(
    () => (identityProvider === 'FEDERATED'
      ? menus.filter(
        (menu) => menu.id !== 'security' && menu.id !== CHANGE_PASSWORD,
      )
      : menus),
    [identityProvider, menus],
  );

  const onSnackbarLinkClick = useCallback(() => {
    getUserProfile(authUserId);
    closeSnackbar();
  }, [getUserProfile, authUserId, closeSnackbar]);

  useEffect(
    () => () => {
      closeSnackbar();
      getAutoReplies(authUserId);
    },
    [],
  );

  useEffect(() => {
    if (
      authUserId === profileUserId
      && snackBar
      && (snackBar?.text !== 'Away messaging enabled' && snackBar?.text !== 'Settings updated successfully')
      && !snackBar?.isError
    ) {
      closeSnackbar();
    }
  }, [
    authUserId,
    profileUserId,
    closeSnackbar,
    snackBar?.text,
    getAutoReplies,
    snackBar,
  ]);

  useEffect(() => {
    if (profileUserId && authUserId !== profileUserId) {
      showSnackbar({
        text: snackBarText,
        isError: false,
        linkText: 'Edit My Profile',
        persist: true,
        onToggle: () => onSnackbarLinkClick(),
      });
    }
  }, [
    authUserId,
    getUserProfile,
    onSnackbarLinkClick,
    profileUserId,
    showSnackbar,
  ]);

  useEffect(() => {
    getListOfTitles();
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/profile/notifications') {
      setConfigItem('NOTIFICATIONS');
    }
  }, [setConfigItem]);

  return (
    <Flex className="config-page" w="100%">
      {!isSmallScreen && (
      <div className="left">
        <Box py="8" pl="8">
          <Heading size="md">Profile</Heading>

          <VStack align="stretch" gap="0">
            {menusFiltered.map((menu) => (
              <div className="menu-title" key={menu.id}>
                {menu.item
                  ? (
                    <button
                      type="button"
                      data-testId={`menu-item-${menu.title.toLowerCase()}`}
                      className={menu.id === configId ? 'active' : ''}
                      onClick={() => setConfigItem(menu.item.id)}
                    >
                      {menu.title}
                    </button>
                  )
                  : (
                    <div className="menu-label">{menu.title}</div>
                  )}
              </div>
            ))}
          </VStack>
        </Box>
      </div>
      )}
      <div className="right">
        <div className="right-inner">
          <RightView />
        </div>
      </div>
    </Flex>
  );
};

export default connector(ProfileConfig);
