import jwt_decode from 'jwt-decode';
import { getUserId } from './cookies';
import { oktaAuthClient } from '../components/LoginWidget/config';

const LAST_ACTIVE_CHAT = 'LAST_ACTIVE_CHAT';
const PREVIOUS_PERMISSIONS = 'PREVIOUS_PERMISSIONS';
const CHAT_BOX_INPUT_DRAFTS = 'CHAT_BOX_INPUT_DRAFTS';

export function saveLastActiveChat(lastActiveChat) {
  const data = {
    lastActiveChat,
    userId: getUserId(),
  };
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(LAST_ACTIVE_CHAT, JSON.stringify(data));
  }
}

export function getLastActiveChat(userId) {
  const str = typeof localStorage !== 'undefined' ? (localStorage.getItem(LAST_ACTIVE_CHAT) || '') : '';
  try {
    const data = JSON.parse(str);
    if (data.userId === userId) {
      return data.lastActiveChat;
    }
    return '';
  } catch (e) {
    return '';
  }
}

export function savePreviousPermissions(userId, permissions) {
  const data = {
    permissions,
    userId,
  };
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(PREVIOUS_PERMISSIONS, JSON.stringify(data));
  }
}

export function getPreviousPermissions(userId) {
  const str = typeof localStorage !== 'undefined' ? (localStorage.getItem(PREVIOUS_PERMISSIONS) || '') : '';
  try {
    const data = JSON.parse(str);
    if (data.userId === userId) {
      return data.permissions;
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function saveChatBoxInputDraft(userId, chatId, text) {
  if (!userId || !chatId || typeof localStorage === 'undefined') {
    return;
  }
  let usersMap = new Map();
  try {
    usersMap = new Map(JSON?.parse(localStorage?.getItem(CHAT_BOX_INPUT_DRAFTS))) || new Map();
  } catch (e) {
    console.error('Error getting users map chat drafts from local storage');
  }
  let userDrafts;
  if (!usersMap.has(userId)) {
    usersMap.set(userId, new Map());
    userDrafts = usersMap.get(userId);
  } else {
    try {
      userDrafts = new Map(JSON?.parse(usersMap.get(userId)));
    } catch (e) {
      console.error('Error getting chat map drafts from local storage');
    }
  }
  if (text?.trim() === '' && userDrafts?.has(chatId)) {
    userDrafts?.delete(chatId);
  } else if (text?.trim() !== '') {
    userDrafts?.set(chatId, text);
  }
  usersMap?.set(userId, JSON.stringify([...userDrafts]));

  try {
    localStorage.setItem(CHAT_BOX_INPUT_DRAFTS, JSON.stringify([...usersMap]));
  } catch (e) {
    console.error('Error saving chat drafts to local storage');
  }
}

export function getChatBoxInputDraft(userId, chatId) {
  if (!userId || !chatId) {
    return '';
  }
  let usersMap = new Map();
  try {
    usersMap = new Map(JSON?.parse(localStorage?.getItem(CHAT_BOX_INPUT_DRAFTS))) || new Map();
  } catch (e) {
    console.error('Error getting users drafts from local storage');
  }
  let thisUserDrafts = usersMap.get(userId);
  try {
    thisUserDrafts = new Map(JSON?.parse(thisUserDrafts));
  } catch (e) {
    console.error('Error getting chat drafts from local storage');
  }
  return thisUserDrafts?.get(chatId) || '';
}

export function saveToLocalStorage(key, value) {
  if (typeof key !== 'string') {
    console.log('Cannot save to local storage');
  } else {
    if (typeof localStorage !== 'undefined') {
      const valueType = typeof value;
      localStorage.setItem(key, valueType === 'object' || valueType === 'array' ? JSON.stringify(value) : value);
      return true;
    }
    console.log('Cannot save to local storage');
  }
  return false;
}

export function getLocalStorageValue(key) {
  if (typeof key !== 'string') {
    console.log('Cannot get item from local storage');
  } else {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }
    console.log('Cannot get item from local storage');
  }
  return '';
}

export function cleanLocalStorage() {
  if (typeof localStorage !== 'undefined') {
    localStorage.clear();
  }
}

const requirePopulatedJWT = () => window.location.hostname.endsWith('.marley.ai');

export const getAccessToken = async () => {
  const token = await oktaAuthClient.getOrRenewAccessToken();

  if (requirePopulatedJWT()) {
    if (!token) {
      throw new Error('No access token found');
    }

    const decodedToken = jwt_decode(token);

    const { organizationId, userId, perms } = decodedToken;
    const missingFields = [];

    if (!organizationId) {
      missingFields.push('Organization ID');
    }
    if (!userId) {
      missingFields.push('User ID');
    }
    if (!perms) {
      missingFields.push('User Permissions');
    }

    if (missingFields.length > 0) {
      console.error(`Token is missing required fields or contains empty fields: ${missingFields.join(', ')}`);
      throw new Error('Token validation failed');
    }
  }

  return token;
};
