declare global {
  interface Window {
    loadedFromMobile?: boolean;
  }
}

export const CREATION_SOURCES = {
  WEB_APP: 'WebApp',
  CONNECT: 'Connect',
  MOBILE: 'MobileV2',
};

export const getCreationSource = () => {
  if (window?.self !== window?.top) {
    return CREATION_SOURCES.CONNECT;
  }
  if (window?.isLoadedInWKWebView || window?.loadedFromMobile) {
    return CREATION_SOURCES.MOBILE;
  }

  return CREATION_SOURCES.WEB_APP;
};
