import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
} from '@chakra-ui/react';
import React from 'react';

import useScreenSize from '@app/components/Hooks/use-screen-size';

import ViewParticipantList from './view-participant-list';

interface Participant {
  id: string;
  imageUrl: string;
  name: string;
  role: string;
  relationship: string;
}

interface ViewParticipantsModalProps {
  activeParticipants: Participant[];
  pendingParticipants: Participant[];
  inactiveParticipants: Participant[];
  loggedInOperatorId: string;
  primaryEndUserId: string;
  onClose: () => void;
  onClickRemoveParticipant: (participantId: string) => void;
  onClickAddParticipant: () => void;
  onClickEditParticipant: (participantId: string) => void;
  onClickReinviteParticipant: (participantId: string) => void;
  enableAddParticipants: boolean;
}

const ViewParticipantsModal: React.FC<ViewParticipantsModalProps> = ({
  activeParticipants,
  pendingParticipants,
  inactiveParticipants,
  loggedInOperatorId,
  primaryEndUserId,
  onClickRemoveParticipant,
  onClose,
  onClickAddParticipant,
  onClickEditParticipant,
  onClickReinviteParticipant,
  enableAddParticipants,
}) => {
  const isSmallScreen = useScreenSize();

  return (
    <Modal
      size={isSmallScreen ? 'full' : 'xl'}
      isOpen
      onClose={onClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent minWidth={isSmallScreen ? 'auto' : '90%'}>
        <ModalHeader>Participants</ModalHeader>
        <ModalCloseButton />
        <ViewParticipantList
          activeParticipants={activeParticipants}
          pendingParticipants={pendingParticipants}
          inactiveParticipants={inactiveParticipants}
          loggedInOperatorId={loggedInOperatorId}
          primaryEndUserId={primaryEndUserId}
          onClickRemoveParticipant={onClickRemoveParticipant}
          onClose={onClose}
          onClickAddParticipant={onClickAddParticipant}
          onClickEditParticipant={onClickEditParticipant}
          onClickReinviteParticipant={onClickReinviteParticipant}
          enableAddParticipants={enableAddParticipants}
        />
      </ModalContent>
    </Modal>
  );
};

export default ViewParticipantsModal;
