import {
  GridItem,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';

import SelectMenu from '@app/components/chakra/fields/select-menu';
import TextField from '@app/components/chakra/fields/text-field';
import CaseMatchAlert from '@app/components/elements/CaseMatchAlert/case-match-alert';
import { GET_JOBLIST } from '@app/constants/endpoints';
import { cleanPhone } from '@app/helpers/format';
import { languageList } from '@app/helpers/languages';
import { fetchData } from '@app/hooks/react-query-helpers';
import { IFormInput, type Contact, ContactCaseRoleEnum } from '@app/types/create-case';

import ContactCard from './contact-card';
import ContactSearchMenu from './fields/contact-search-menu';
import PhoneNumberTextField from './fields/phone-number-text-field';

interface ContactDetailsFormProps {
  selectedContact: Contact | null;
  setSelectedContact: Dispatch<SetStateAction<Contact | null>>;
  setIsPhoneNumberEntered: Dispatch<SetStateAction<boolean>>;
  isEditMode?: boolean;
}

const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
  selectedContact,
  setSelectedContact,
  setIsPhoneNumberEntered,
  isEditMode,
}) => {
  const [showCaseMatchAlert, setShowCaseMatchAlert] = useState(false);
  const { trigger, getValues } = useFormContext<IFormInput>();
  const [isNewContact, setIsNewContact] = useState(false);

  const phoneNumber = getValues('phoneNumber');

  const cleanedPhoneNumber = useMemo(
    () => cleanPhone(phoneNumber),
    [phoneNumber],
  );

  const queryOptions = useMemo(() => ({
    queryKey: ['existingCases', cleanedPhoneNumber],
    queryFn: () => fetchData(`${GET_JOBLIST}?offset=0&limit=32&order=DESC&sort=createdAt&filter=${cleanedPhoneNumber}`),
    enabled: cleanedPhoneNumber.length === 10,
  }), [cleanedPhoneNumber]);

  const existingCasesQuery = useQuery(queryOptions);

  useEffect(() => {
    if (existingCasesQuery.data?.jobs.length) {
      setShowCaseMatchAlert(true);
    }

    return () => {
      setShowCaseMatchAlert(false);
    };
  }, [existingCasesQuery.data?.jobs]);

  const renderContactSection = () => {
    if (selectedContact) {
      return <ContactCard contact={selectedContact} />;
    }
    return (
      <ContactSearchMenu
        setSelectedContact={setSelectedContact}
        setIsNewContact={setIsNewContact}
      />
    );
  };

  useEffect(() => {
    trigger('phoneNumber');
  }, [isNewContact, trigger]);

  useEffect(() => {
    setIsPhoneNumberEntered(!!selectedContact || isNewContact);
  }, [isNewContact, selectedContact, setIsPhoneNumberEntered]);

  const contactCaseRoleOptions = useMemo(() => Object.entries(ContactCaseRoleEnum).map(
    ([key, value]) => ({
      id: key,
      label: value,
    }),
  ), []);

  return (
    <>
      {!isNewContact
        ? <GridItem colSpan={2}>{renderContactSection()}</GridItem>
        : (
          <>
            <GridItem colSpan={2}>
              <PhoneNumberTextField />
              <TextField<IFormInput>
                label="ID"
                name="id"
                hidden
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <TextField<IFormInput>
                label="First Name"
                name="firstName"
                registerOptions={{ required: { value: true, message: 'First Name is Required' } }}
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <TextField<IFormInput>
                label="Last Name"
                name="lastName"
                registerOptions={{ required: { value: true, message: 'Last Name is Required' } }}
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <TextField<IFormInput>
                label="Zip Code"
                name="contactZipCode"
                registerOptions={
                  {
                    pattern: {
                      value: /^\d{5}(-\d{4})?$/,
                      message: 'Zip Code must be 5 digits or 5+4 format (e.g. 12345 or 12345-6789)',
                    },
                  }
                }
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <SelectMenu<IFormInput>
                id="language-menu"
                label="Language"
                name="contactLanguage"
                options={languageList}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <TextField<IFormInput>
                label="Email"
                name="email"
                registerOptions={
                  {
                    pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email' },
                  }
                }
              />
            </GridItem>
          </>
        )}
      {(isNewContact || selectedContact) && (
        <GridItem colSpan={2}>
          <SelectMenu<IFormInput>
            id="contact-case-role"
            label="Contact Case Role"
            name="contactCaseRole"
            defaultValue="Choose Contact Case Role"
            options={contactCaseRoleOptions}
            isDisabled={isEditMode}
          />
        </GridItem>
      )}
      <GridItem colSpan={2}>
        <CaseMatchAlert
          cases={existingCasesQuery?.data?.jobs || []}
          defaultShowAlert={showCaseMatchAlert}
          isSummary={false}
          onClose={() => setShowCaseMatchAlert(false)}
        />
      </GridItem>
    </>
  );
};

export default ContactDetailsForm;
