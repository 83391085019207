import { useMemo } from 'react';

import {
  FEATURE_FLAG_TOTAL_LOSS_ASSIST,
  FEATURE_FLAG_VERISK,
  NOTES_ACCESS,
} from '@app/constants/permissions';
import usePermissionVerify from '@app/hooks/permission-verify';
import { Job, PartnerData } from '@app/types/api/job';

export const buildPartnerDataByType = (
  partnerData: PartnerData[],
  partnerType: string,
  inputData: Record<string, any>,
) => {
  const filteredPartnerData = partnerData.reduce<{
    partnerName: string;
    properties: Record<string, { label: string; value: string }> }[]>(
    (acc, {
      partnerType: type,
      partnerName,
      properties,
    }) => {
      if (type === partnerType) {
        acc.push({ partnerName, properties: properties || {} });
      }
      return acc;
    },
    [],
  );

  return {
    ...inputData,
    partnerData: filteredPartnerData,
  };
};

/**
 * Builds objects of data for the auto and property claim data trays
 * @param activeJob - The active job
 * @returns The auto and property claim data trays
 */
export const useDataTray = (activeJob: Partial<Job>) => useMemo(() => {
  const {
    totalLossData = {},
    partnerData = [],
    veriskData = {},
  } = activeJob;
  const autoClaimData = buildPartnerDataByType(partnerData, 'AUTO', totalLossData);
  const propertyClaimData = buildPartnerDataByType(partnerData, 'PROPERTY', veriskData);
  return { autoClaimData, propertyClaimData };
}, [activeJob]);

/**
 * Determines if the data trays should be shown
 * @param activeJob - The active job
 * @returns An object of booleans for shown data trays
 */
export const useShowDataTray = (activeJob: Partial<Job>) => {
  const {
    FEATURE_FLAG_TOTAL_LOSS_ASSIST: isTLAEnabled,
    FEATURE_FLAG_VERISK: isVeriskEnabled,
    NOTES_ACCESS: showNotes,
  } = usePermissionVerify(
    [FEATURE_FLAG_TOTAL_LOSS_ASSIST, FEATURE_FLAG_VERISK, NOTES_ACCESS],
  );

  return useMemo(() => {
    const hasAutoPartnerData = activeJob?.partnerData?.find(
      (partner) => partner.partnerType === 'AUTO',
    );

    const hasPropertyPartnerData = activeJob?.partnerData?.find(
      (partner) => partner.partnerType === 'PROPERTY',
    );

    const hasTotalLossData = activeJob?.totalLossData
      && Object.keys(activeJob.totalLossData).length !== 0;

    const showAutoClaimData = hasAutoPartnerData
      || (isTLAEnabled && hasTotalLossData);

    const showPropertyClaimData = hasPropertyPartnerData
      || (isVeriskEnabled && activeJob?.veriskData?.originalAssignmentId);

    const showPizzaTracker = isTLAEnabled && activeJob?.isTotalLoss;

    return {
      showAutoClaimData,
      showPropertyClaimData,
      showPizzaTracker,
      showNotes,
    };
  }, [
    activeJob?.isTotalLoss,
    activeJob?.partnerData,
    activeJob.totalLossData,
    activeJob?.veriskData?.originalAssignmentId,
    isTLAEnabled,
    isVeriskEnabled,
    showNotes,
  ]);
};
