import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Divider } from '@himarley/unity';
import { formatDate } from '../../helpers/datetime';
import SeenIcon from '../../../images/icons/verified-icon.svg';
import SelectUnchecked from '../../../images/icons/select-unchecked-icon.svg';
import SelectChecked from '../../../images/icons/select-checked-icon.svg';
import ErrorIcon from '../../../images/icons/info-error.svg';
import UserPhoto2 from '../UserPhoto2/UserPhoto2';
import style from './CaseProgress.module.less';
import { EVENT_TYPES, PROGRESS_STEP_STATUS_COMPLETE } from '../../constants/integration-constants';
import {
  isReleaseIssueOpen,
  isReleaseIssueResolved,
  isReleaseIssueCompleted,
  isReleaseIssueRejected,
  areAllReleaseIssuesResolved,
} from '../../helpers/network-partners-utils';

const getIssueStatus = (issue) => {
  let badge;
  switch (true) {
    case isReleaseIssueOpen(issue) && !isReleaseIssueRejected(issue):
      badge = <SelectUnchecked data-testid="active-badge" />;
      break;
    case isReleaseIssueCompleted(issue):
      badge = <SelectChecked data-testid="completed-badge" />;
      break;
    case isReleaseIssueResolved(issue):
      badge = <SeenIcon data-testid="resolved-badge" />;
      break;
    case isReleaseIssueRejected(issue):
      badge = <ErrorIcon data-testid="rejected-badge" />;
      break;
    default:
      break;
  }
  return badge;
};

const ReleaseIssuesStep = ({
  showDetails, releaseIssues, status, onMarkAsCompleteButtonClick,
}) => {
  const setEventLogStatus = (type) => {
    const eventStatusClassName = style.eventStatus;
    switch (type) {
      case EVENT_TYPES.RELEASE_COMPLETED:
        return (
          <div className={`${eventStatusClassName} ${style.eventComplete}`}>
            <SelectChecked />
            <span>Marked Complete</span>
          </div>
        );
      case EVENT_TYPES.RELEASE_RESOLVED:
        return (
          <div className={`${eventStatusClassName} ${style.eventResolved}`}>
            <SeenIcon />
            <span>Resolved By Yard</span>
          </div>
        );
      case EVENT_TYPES.RELEASE_UPDATED:
        return (
          <div className={`${eventStatusClassName} ${style.eventUpdate}`}>
            <ErrorIcon />
            <span>Update From Yard</span>
          </div>
        );
      default:
        return null;
    }
  };

  const renderReleaseList = (issues) => {
    if (status === PROGRESS_STEP_STATUS_COMPLETE && areAllReleaseIssuesResolved(issues)) {
      return (
        <div className={style.issuesResolved} data-testid="release-all-resolved">
          <SeenIcon data-testid="resolved-badge" />
          <p className={style.releaseListText}>All Issues Resolved</p>
        </div>
      );
    }
    return (
      <ul className={style.releaseIssues} data-testid="release-ul-list">
        {issues.map((issue) => (
          <li>
            {getIssueStatus(issue)}
            <p
              className={`${style.releaseListText} ${issue.rejected && style.eventUpdate} ${isReleaseIssueResolved(issue) && style.issueResolved} ${(isReleaseIssueOpen(issue) && !isReleaseIssueRejected(issue)) && style.issueOpen}`}
            >
              {issue.description}
            </p>
          </li>
        ))}
      </ul>
    );
  };

  const renderEventLog = (events) => events.map((event) => {
    const isCopartCoordinator = event.type === EVENT_TYPES.RELEASE_COMPLETED;
    const eventAuthor = isCopartCoordinator ? 'Copart Coordinator' : 'Copart(vendor)';
    return (
      <div className={style.eventlog}>
        <Divider
          className={style.eventDivider}
        />
        <div className={style.eventBody} data-testid="release-eventlog">
          <div className={style.releaseHeader}>
            <div className={style.releaseAuthor}>
              <UserPhoto2
                id="copart"
                isAI
                type="thumb"
                isCustomer={false}
                name={eventAuthor}
                className={style.releaseAuthorPhoto}
              />
              <span>{eventAuthor}</span>
            </div>
            <p className={style.releaseTimestamp}>{formatDate(event.eventTime, 'date-time')}</p>
          </div>
          {setEventLogStatus(event.type)}
          <p>{event.comment}</p>
        </div>
      </div>
    );
  });

  return (
    <div>
      {
        !showDetails && renderReleaseList(releaseIssues)
      }
      {showDetails && (
        releaseIssues.map((issue) => (
          <Card
            className={style.releaseCard}
            type="shallow"
          >
            <div className={style.releaseHeader}>
              <div className={style.releaseAuthor}>
                <UserPhoto2
                  id="copart"
                  isAI
                  type="thumb"
                  isCustomer={false}
                  className={style.releaseAuthorPhoto}
                />
                <span>Copart(vendor)</span>
              </div>
              <p className={style.releaseTimestamp}>{ formatDate(issue.timestamp, 'date-time')}</p>
            </div>
            <p className={style.releaseTextHeader}>{`RELEASE ISSUE: ${issue.description}`}</p>
            <p>{issue.comment}</p>
            {issue?.events?.length > 0 && renderEventLog(issue.events)}
            {(process.env.COPART_TWO_WAY_ENABLED && isReleaseIssueOpen(issue)) && (
              <Button
                type="outline"
                className={style.markAsCompleteButton}
                onClick={() => onMarkAsCompleteButtonClick(issue.id)}
                testId="mark-as-complete"
              >
                Mark as Complete
              </Button>
            )}
          </Card>
        ))
      )}
    </div>
  );
};

ReleaseIssuesStep.propTypes = {
  releaseIssues: PropTypes.arrayOf(PropTypes.shape({
    resolved: PropTypes.bool,
    description: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  showDetails: PropTypes.bool.isRequired,
  onMarkAsCompleteButtonClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default ReleaseIssuesStep;
