/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Menu,
  MenuButton,
  MenuList,
  Box,
  VStack,
  Text,
  MenuItem,
  Button,
  Flex,
  useDisclosure,
  Icon,
} from '@chakra-ui/react';
import {
  CheckmarkFilledIcon,
  CaretUpIcon,
  CaretDownIcon,
  SearchBar,
} from '@himarley/unity';
import React, {
  useState, useRef, useCallback, useEffect,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

interface SearchableDropdownProps {
  items?: Array<{ id: string; label?: string; avatar?: React.ReactNode }>;
  selectedItemsMap: Map<string, string>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  handleSearch: (searchText: string) => void;
  disabled?: boolean;
  defaultLabel: string;
  entityLabel: string;
  increaseDataSet?: () => void;
}

interface RowProps {
  data: {
    items: Array<{ id: string; label?: string; avatar?: React.ReactNode }>,
    setItemSize: (index: string | number, size: string | number) => void,
    handleSelectItem: (item: { id: string; label?: string }) => void,
    selectedItemsMap: Map<string, string>,
  },
  index: number;
  style: React.CSSProperties;
}

const Row: React.FC<RowProps> = ({
  index,
  style,
  data,
}) => {
  const itemRef = useRef<HTMLButtonElement>(null);
  const {
    items,
    setItemSize,
    handleSelectItem,
    selectedItemsMap,
  } = data;
  const item = items[index];
  useEffect(() => {
    if (itemRef?.current) {
      setItemSize(
        index,
        itemRef.current.getBoundingClientRect().height,
      );
    }
  }, [setItemSize, index, itemRef]);
  return (
    <div style={style}>
      <MenuItem
        ref={itemRef}
        data-testid={`searchable-dropdown-item-${item?.id}`}
        height="40px"
        key={item.id}
        onClick={() => handleSelectItem(item)}
        icon={React.isValidElement(item.avatar) ? item.avatar : undefined}
        backgroundColor={selectedItemsMap.has(item.id) ? 'gray.50' : 'white'}
      >
        <Flex justify="space-between" align="center" width="100%" gap={3}>
          {item.label}
          {selectedItemsMap.has(item.id) && <Icon as={CheckmarkFilledIcon} />}
        </Flex>
      </MenuItem>
    </div>
  );
};

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  items,
  selectedItemsMap,
  setSelectedItems,
  handleSearch,
  disabled,
  defaultLabel,
  entityLabel,
  increaseDataSet,
}) => {
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();
  const listRef = useRef<List>(null);

  const handleSearchChange = (text: string) => {
    setSearchText(text);
    handleSearch(text);
  };

  const handleSelectItem = (item: { id: string; label?: string }) => {
    const newSelectedItems = new Map(selectedItemsMap);
    newSelectedItems.has(item.id)
      ? newSelectedItems.delete(item.id)
      : newSelectedItems.set(item.id, item.label ?? '');
    setSelectedItems(newSelectedItems);
  };

  const getDisplayLabel = () => {
    const selectedCount = selectedItemsMap.size;

    if (selectedCount === 0) {
      return defaultLabel;
    }
    if (selectedCount === 1) {
      return Array.from(selectedItemsMap.values())[0];
    }
    return `${selectedCount} ${entityLabel}`;
  };

  const handleClearSelection = () => {
    setSelectedItems(new Map());
  };

  const sizeMap = useRef<{ [key: number]: number }>({});
  const setItemSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef?.current?.resetAfterIndex(index);
  }, []);
  const getSize = (index: number): number => sizeMap.current[index] || 110;

  const isItemLoaded = (index: number) => (items ? index < items.length : false);
  const loadMoreData = () => {
    if (increaseDataSet) {
      increaseDataSet();
    }
  };

  return (
    <Menu
      closeOnSelect={false}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
    >
      <Flex width="100%">
        <MenuButton
          data-testid="searchable-dropdown-button"
          as={Button}
          variant="outline"
          flex="1"
          isDisabled={disabled}
          maxWidth="140px"
          maxHeight="32px"
          rightIcon={
            <Icon as={isOpen ? CaretUpIcon : CaretDownIcon} color="gray.500" />
          }
        >
          <Text
            as="span"
            noOfLines={1}
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="left"
            style={{
              display: 'block',
            }}
          >
            {getDisplayLabel()}
          </Text>
        </MenuButton>
      </Flex>
      <MenuList width="340px" padding={0}>
        <Box p={2}>
          <Flex align="center" justify="space-between">
            <Box w="198px" sx={{ div: { width: 'unset' } }}>
              <SearchBar
                id="group-filter-search"
                placeholder="Search groups..."
                value={searchText}
                onValueChange={handleSearchChange}
                onClear={() => handleSearchChange('')}
                ref={searchInputRef}
              />
            </Box>
            {selectedItemsMap?.size > 0 ? (
              <Button
                onClick={handleClearSelection}
                disabled={disabled}
                variant="plain"
                height="auto"
                padding="0"
                ml="12px"
                mr="8px"
              >
                <Text
                  color="blue.60"
                  fontSize="0.9rem"
                  fontWeight="400"
                  width="100%"
                  minWidth="88px"
                  whiteSpace="nowrap"
                  textAlign="right"
                  alignSelf="center"
                  justifyContent="center"
                  margin="0"
                >
                  Clear Selected
                </Text>
              </Button>
            ) : (
              <Text
                ml="12px"
                mr="8px"
                mb="0"
                color="blue.60"
                fontSize="0.9rem"
                fontWeight="400"
                width="auto"
                minWidth="88px"
                whiteSpace="nowrap"
                textAlign="right"
                alignSelf="right"
                justifyContent="center"
              >
                {`${selectedItemsMap.size} Selected`}
              </Text>
            )}
          </Flex>
        </Box>
        <Box
          w="100%"
          borderTop="1px solid"
          borderColor="gray.200"
          paddingBottom="2px"
        />
        <Box maxH="50vh" overflowY="auto" height={`${((items?.length || 1) * 40)}px`}>
          <VStack align="start" spacing={0} paddingBottom="4px" height="100%">
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={(items ? items.length : 0) + 1}
                  loadMoreItems={loadMoreData}
                >
                  {({ onItemsRendered }) => (
                    <List
                      ref={listRef}
                      onItemsRendered={onItemsRendered}
                      itemCount={items?.length || 0}
                      height={height}
                      itemSize={getSize}
                      width={width}
                      itemData={{
                        items,
                        handleSelectItem,
                        setItemSize,
                        selectedItemsMap,
                      }}
                    >
                      {Row}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </VStack>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default SearchableDropdown;
