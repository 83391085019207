import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Avatar,
  Flex,
  Text,
} from '@chakra-ui/react';
import { CheckmarkFilledIcon } from '@himarley/unity';
import debounce from 'lodash/debounce';
import React, { useEffect, useState, useMemo } from 'react';
import {
  useFormContext,
  type RegisterOptions,
  type FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

import { useCustomStyles } from '@app/chakra-theme/hooks/use-custom-styles';
import Menu from '@app/components/chakra/menu';
import { useGroupsQuery } from '@app/services/group';
import { Group } from '@app/types/api/group';

interface GroupLabelProps {
  groupName: string;
}

const GroupLabel: React.FC<GroupLabelProps> = ({ groupName }) => (
  <Flex align="center" gap={2}>
    <Avatar
      name={groupName}
      size="xs"
      bg="marleyRed.500"
      color="white"
    />
    <Text>{groupName}</Text>
  </Flex>
);

interface GroupSelectMenuProps<T extends FieldValues> {
  id?: string;
  label?: string;
  name: Path<T>;
  registerOptions?: RegisterOptions<T>;
}

const GroupSelectMenu = <T extends FieldValues>({
  id = 'groups',
  label = 'Groups',
  name,
  registerOptions,
}: GroupSelectMenuProps<T>) => {
  const form = useFormContext<T>();
  const { formControlStyles, menuButtonStyles } = useCustomStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const debouncedSetSearch = useMemo(
    () => debounce((value: string) => {
      setDebouncedSearch(value);
    }, 300),
    [],
  );

  useEffect(() => {
    if (searchQuery.length > 2 || searchQuery.length === 0) {
      debouncedSetSearch(searchQuery);
    }
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [searchQuery, debouncedSetSearch]);

  useEffect(() => {
    form.register(name, registerOptions);
  }, [form, name, registerOptions]);

  const selectedGroups = useMemo<string[]>(
    () => (form.getValues(name) as string[]) || [],
    [form, name],
  );

  const groupsQuery = useGroupsQuery({
    searchText: debouncedSearch || '',
  });

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = groupsQuery;

  const groups = useMemo(
    () => data?.pages.reduce(
      (accumulator, item) => [...accumulator, ...item.groups],
      [],
    ) || [],
    [data?.pages],
  );

  const options = useMemo(() => groups.map((group: Group) => ({
    id: group._id,
    label: <GroupLabel groupName={group.name} />,
    onClick: () => {
      const updatedGroups = selectedGroups.includes(group._id || '')
        ? selectedGroups.filter((groupId: string) => groupId !== group._id)
        : [...selectedGroups, group._id];

      form.setValue(name, updatedGroups as PathValue<T, Path<T>>, { shouldValidate: true });
    },
    rightIcon: selectedGroups.includes(group._id || '') ? CheckmarkFilledIcon : undefined,
    closeOnSelect: false,
  })), [selectedGroups, groups, form, name]);

  const defaultValue = 'Select Groups';
  const selectedCount = selectedGroups.length;
  const getButtonLabel = () => {
    if (selectedCount > 1) {
      return `${selectedCount} Groups`;
    }
    if (selectedCount === 1) {
      return groups.find((group: Group) => group._id === selectedGroups[0])?.name ?? defaultValue;
    }
    return defaultValue;
  };

  const buttonLabel = getButtonLabel();

  const labelId = `${id}-label`;

  const dynamicMenuButtonStyles = {
    ...menuButtonStyles,
    color: buttonLabel === defaultValue ? 'gray.500' : 'black',
    fontSize: buttonLabel === defaultValue ? 'sm' : 'md',
  };

  return (
    <FormControl
      isInvalid={!!form.formState.errors[name]}
      sx={formControlStyles}
    >
      <FormLabel>{label}</FormLabel>
      <Menu
        id={id}
        options={options}
        defaultLabel={buttonLabel as string}
        placement="top"
        isLoading={isLoading}
        menuButtonProps={{
          size: 'md',
          w: '100%',
          'aria-labelledby': labelId,
          sx: dynamicMenuButtonStyles,
        }}
        matchWidth
        searchable
        searchValue={searchQuery}
        onSearchChange={setSearchQuery}
        clearSelected={() => {
          form.setValue(name, [] as PathValue<T, Path<T>>, { shouldValidate: true });
        }}
        increaseDataSet={fetchNextPage}
        shouldLoadMoreItems={!searchQuery?.trim() && hasNextPage}
        isLazy
      />
      {form.formState.errors[name] && (
        <FormErrorMessage>{form.formState.errors[name]?.message as string}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default GroupSelectMenu;
