import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import TabRouter from '@app/components/chakra/tab-router';

import DataDownload from './DataDownload/data-download';
import LookerDashboard from './LookerDashboard/looker-dashboard';
import { loadReports } from '../../actions/analytics';
import { EVENT_BASED_MY_TEAMS_DASHBOARD } from '../../constants/permissions';

import './AnalyticsRoot.less';

interface AnalyticsRootProps {
  loadDataDownload: () => void,
  currentPermissions: string[],
}

const getMyTeamDashboardName = (currentPermissions: string[]) => {
  const hasDisabledMarleyOptIn = currentPermissions.includes('DISABLE_MARLEY_OPT_IN');
  const hasEventBasedMyTeams = currentPermissions.includes(EVENT_BASED_MY_TEAMS_DASHBOARD);

  if (hasDisabledMarleyOptIn) {
    if (hasEventBasedMyTeams) {
      return 'my_team_opted_in_event_based';
    }
    return 'my_team_opted_in';
  }
  if (hasEventBasedMyTeams) {
    return 'my_team_event_based';
  }
  return 'my_team';
};

const AnalyticsRoot: React.FC<AnalyticsRootProps> = ({ loadDataDownload, currentPermissions }) => {
  const hasDisabledMarleyOptIn = currentPermissions.includes('DISABLE_MARLEY_OPT_IN');
  const myOrgDashboardName = hasDisabledMarleyOptIn
    ? 'my_org_opted_in'
    : 'my_org';
  const myTeamDashboardName = getMyTeamDashboardName(currentPermissions);

  const tabList: {
    id: string;
    title: string;
    component: React.ReactNode;
  }[] = [{
    id: 'myorg',
    title: 'My Org',
    component: <LookerDashboard dashboardName={myOrgDashboardName} />,
  }];

  const permissionToTabConfig = {
    MY_TEAM_DASHBOARD: {
      id: 'myteam',
      title: 'My Team',
      component: <LookerDashboard dashboardName={myTeamDashboardName} />,
    },
    SERVICE_CENTER_DASHBOARD: {
      id: 'inbound',
      title: 'Inbound Dashboard',
      component: <LookerDashboard dashboardName="service_center" />,
    },
    DATA_DOWNLOAD: {
      id: 'download',
      title: 'Data Download',
      component: <DataDownload />,
    },
    FEATURE_FLAG_TOTAL_LOSS_ASSIST: {
      id: 'totalLossAssist',
      title: 'Total Loss Assist',
      component: <LookerDashboard dashboardName="total_loss_assist" />,
    },
  };

  Object.entries(permissionToTabConfig).forEach(([permission, tabConfig]) => {
    if (currentPermissions.includes(permission)) {
      tabList.push(tabConfig);
    }
  });

  return (
    <TabRouter
      tabList={tabList}
      basePath="analytics"
      defaultTabId="myorg"
      onTabChange={(tabId) => {
        if (tabId === 'download') {
          loadDataDownload();
        }
      }}
    />
  );
};

export default connect(
  (state) => ({
    currentPermissions: _.get(state, 'auth.permissions', []),
  }),
  { loadDataDownload: loadReports },
)(AnalyticsRoot);
