import { TimezoneState } from '@app/types/workflow-settings';

import { WeeklyBusinessHoursForm, IWorkflowSettingsFormInput } from './types';

export const defaultBusinessHours: WeeklyBusinessHoursForm = {
  Monday: { enabled: false, startTime: '09:00', endTime: '17:00' },
  Tuesday: { enabled: false, startTime: '09:00', endTime: '17:00' },
  Wednesday: { enabled: false, startTime: '09:00', endTime: '17:00' },
  Thursday: { enabled: false, startTime: '09:00', endTime: '17:00' },
  Friday: { enabled: false, startTime: '09:00', endTime: '17:00' },
  Saturday: { enabled: false, startTime: '09:00', endTime: '17:00' },
  Sunday: { enabled: false, startTime: '09:00', endTime: '17:00' },
};

export const DEFAULT_WORKFLOW_SETTINGS_FORM_VALUES: IWorkflowSettingsFormInput = {
  workflowName: '',
  keywordTrigger: '',
  redirectPhoneNumber: '',
  organizationName: '',
  phoneNumber: '',
  organizationNameType: 'default' as const,
  website: '',
  completionEmail: false,
  completionEmailAddresses: [''],
  enableSingleOptIn: true,
  enablePolicyLookup: false,
  enableClaimSubmission: false,
  escalation: false,
  timezone: TimezoneState.AMERICA_NEW_YORK,
  escalationSchedule: defaultBusinessHours,
  escalationEmailAddresses: [''],
  escalationPhoneNumber: '',
};
