/* eslint-disable react/no-array-index-key */
import {
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  Text,
  Input,
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Switch,
  Flex,
  IconButton,
  Checkbox,
  Box,
  Select,
  Icon,
} from '@chakra-ui/react';
import { AddCircleIcon, TrashIcon } from '@himarley/unity';
import React, { useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { CREATE_WORKFLOW_SETTINGS, UPDATE_WORKFLOW_SETTINGS, CREATE_WORKFLOW_KEYWORD } from '@app/constants/endpoints';
import { postData, putData } from '@app/hooks/react-query-helpers';
import { useOrganizationDisplayName } from '@app/selectors/organization';
import {
  TimezoneState,
} from '@app/types/workflow-settings';

import { defaultBusinessHours } from './constants';
import { IWorkflowSettingsFormInput, WorkflowSettingsWithMetadata } from './types';
import {
  mapFormToWorkflowSettingsAndTrigger,
  getFormDefaultValues,
  generate24HoursInHoursAndMinutes,
  validatePhoneNumber,
} from './utils';

interface WorkflowSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialData?: WorkflowSettingsWithMetadata;
  type: 'edit' | 'create';
  refetchAllData: () => void;
}

const WorkflowSettingsModal: React.FC<WorkflowSettingsModalProps> = ({
  isOpen,
  onClose,
  initialData,
  type,
  refetchAllData,
}) => {
  const organizationDisplayName = useOrganizationDisplayName();

  const isEdit = type === 'edit';

  const getUpdatedDefaultValues = useCallback(
    () => getFormDefaultValues(initialData, organizationDisplayName),
    [initialData, organizationDisplayName],
  );

  const defaultValues = getUpdatedDefaultValues() as IWorkflowSettingsFormInput;

  const methods = useForm<IWorkflowSettingsFormInput>({
    defaultValues: defaultValues as IWorkflowSettingsFormInput,
    mode: 'onBlur',
  });

  // Add effect to reset form when initialData changes

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    reset,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    const updatedDefaultValues = getUpdatedDefaultValues();
    reset(updatedDefaultValues);
  }, [initialData, getUpdatedDefaultValues, reset]);

  const organizationNameTypeValue = watch('organizationNameType');

  useEffect(() => {
    if (organizationNameTypeValue === 'default') {
      setValue('organizationName', organizationDisplayName);
    }
  }, [organizationNameTypeValue, organizationDisplayName, setValue]);

  const closeModalAndReset = () => {
    reset(defaultValues);
    onClose();
  };

  const completionEmailAddresses = watch('completionEmailAddresses');
  const completionEmailEnabled = watch('completionEmail');
  const escalationEmailAddresses = watch('escalationEmailAddresses');

  const onSubmit = async (data: IWorkflowSettingsFormInput) => {
    const { workflowSettings, keywordTrigger } = mapFormToWorkflowSettingsAndTrigger(
      data,
      organizationDisplayName,
    );
    const {
      workflowName,
      ...workflowSettingsWithoutName
    } = workflowSettings;

    // Handle form submission
    try {
      if (isEdit) {
        await putData(
          UPDATE_WORKFLOW_SETTINGS(workflowName),
          { ...workflowSettingsWithoutName },
        );
      } else {
        await postData(
          CREATE_WORKFLOW_SETTINGS(),
          { [workflowName]: workflowSettingsWithoutName },
        );
        await postData(
          CREATE_WORKFLOW_KEYWORD(),
          { [keywordTrigger]: workflowName },
        );
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
    refetchAllData();
    closeModalAndReset();
  };

  const handleAddEmail = (field: 'completionEmailAddresses' | 'escalationEmailAddresses') => {
    const currentEmails = getValues(field);
    setValue(field, [...currentEmails, '']);
  };

  const handleRemoveEmail = (index: number, field: 'completionEmailAddresses' | 'escalationEmailAddresses') => {
    const currentEmails = getValues(field);
    const newEmails = currentEmails.filter((_, i) => i !== index);
    setValue(field, newEmails);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader borderBottom="1px solid" borderColor="gray.200">
            Workflow settings
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Grid gap={6}>
              <GridItem>
                <Text fontSize="lg" fontWeight="medium">Name and keyword settings</Text>
                <Text color="gray.600">Used to find and trigger the workflow</Text>
              </GridItem>

              <GridItem>
                <FormControl isInvalid={!!errors.workflowName}>
                  <FormLabel>Workflow name</FormLabel>
                  <Input
                    {...register('workflowName', {
                      pattern: {
                        value: /^(DEFAULT|[A-Z_]+:[A-Z_]+:[A-Z_]+)$/,
                        message: 'Workflow name must be either DEFAULT or follow format CLIENT_ID:TYPE:CLAIMTYPE (e.g., ABC_COMPANY:FNOL:AUTO)',
                      },
                      required: 'Workflow name is required',
                    })}
                    isDisabled={type === 'edit'}
                  />
                  <FormHelperText color={errors.workflowName ? 'red.500' : 'gray.600'}>
                    {errors.workflowName ? errors.workflowName.message
                      : (type === 'edit' && 'This can only be set for new workflows')}
                  </FormHelperText>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Keyword trigger</FormLabel>
                  <Input
                    {...register('keywordTrigger')}
                    isDisabled={type === 'edit'}
                  />
                  <FormHelperText>
                    This can only be set for new workflows
                  </FormHelperText>
                </FormControl>
              </GridItem>

              <GridItem>
                <Text fontSize="lg" fontWeight="medium">Display values</Text>
                <Text color="gray.600">Used in messages to identify sender</Text>
              </GridItem>

              <GridItem>
                <RadioGroup
                  defaultValue="default"
                  value={organizationNameTypeValue}
                  onChange={(value) => setValue('organizationNameType', value as 'default' | 'custom')}
                >
                  <Stack spacing={4}>
                    <Radio value="default">Default organization name</Radio>
                    <Radio value="custom">Customize organization name</Radio>
                  </Stack>
                </RadioGroup>
              </GridItem>

              <GridItem>
                <FormControl isInvalid={!!errors.organizationName}>
                  <FormLabel>Organization name</FormLabel>
                  <Input
                    {...register('organizationName')}
                    isDisabled={organizationNameTypeValue === 'default'}
                  />
                  {errors.organizationName && (
                    <FormHelperText color="red.500">
                      {errors.organizationName.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl isInvalid={!!errors.phoneNumber}>
                  <FormLabel>Phone number</FormLabel>
                  <Input
                    {...register('phoneNumber', {
                      required: 'Phone number is required',
                      validate: validatePhoneNumber,
                    })}
                    placeholder="1-555-555-5555"
                    type="tel"
                  />
                  {errors.phoneNumber && (
                    <FormHelperText color="red.500">
                      {errors.phoneNumber.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl isInvalid={!!errors.redirectPhoneNumber}>
                  <FormLabel>Redirect landline number</FormLabel>
                  <Input
                    {...register('redirectPhoneNumber', {
                      required: 'Redirect landline number is required',
                      validate: validatePhoneNumber,
                    })}
                    placeholder="1-555-555-5555"
                    type="tel"
                  />
                  {errors.redirectPhoneNumber && (
                    <FormHelperText color="red.500">
                      {errors.redirectPhoneNumber.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl isInvalid={!!errors.website}>
                  <FormLabel>Website</FormLabel>
                  <Input
                    {...register('website', {
                      required: 'Website is required',
                    })}
                    placeholder="www.example.com"
                  />
                  {errors.website && (
                    <FormHelperText color="red.500">
                      {errors.website.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Completion email</FormLabel>
                  <Text color="gray.600" fontSize="sm" mb={2}>
                    Used to inform after case is closed
                  </Text>
                  <Switch
                    {...register('completionEmail')}
                    size="lg"
                  />
                </FormControl>
              </GridItem>

              {completionEmailEnabled && (
                <GridItem>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Stack spacing={2}>
                      {completionEmailAddresses.map((_, index) => (
                        <Flex key={index} direction="column" gap={2}>
                          <Flex gap={2}>
                            <Input
                              {...register(`completionEmailAddresses.${index}`, {
                                required: 'Email address is required',
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: 'Invalid email address format',
                                },
                              })}
                              placeholder="completion-email@example.com"
                              isInvalid={!!errors.completionEmailAddresses?.[index]}
                            />
                            {completionEmailAddresses.length > 1 && (
                              <IconButton
                                aria-label="Remove email"
                                icon={<Icon as={TrashIcon} />}
                                variant="ghost"
                                onClick={() => handleRemoveEmail(index, 'completionEmailAddresses')}
                              />
                            )}
                            {index === getValues('completionEmailAddresses').length - 1 && (
                              <IconButton
                                aria-label="Add email"
                                icon={<AddCircleIcon />}
                                variant="outline"
                                onClick={() => handleAddEmail('completionEmailAddresses')}
                              />
                            )}
                          </Flex>
                          {errors.completionEmailAddresses?.[index] && (
                            <FormHelperText color="red.500">
                              {errors.completionEmailAddresses[index]?.message}
                            </FormHelperText>
                          )}
                        </Flex>
                      ))}
                    </Stack>
                  </FormControl>
                </GridItem>
              )}

              <GridItem>
                <Text fontSize="lg" fontWeight="medium">Other settings</Text>
              </GridItem>

              <GridItem>
                <Stack spacing={3}>
                  <FormControl isInvalid={!!errors.enableSingleOptIn}>
                    <Checkbox
                      {...register('enableSingleOptIn', {
                        validate: (value) => value !== undefined || value !== null || 'Single opt-in selection is required',
                      })}
                    >
                      Enable single opt-in (Recommended)
                    </Checkbox>
                    {errors.enableSingleOptIn && (
                      <FormHelperText color="red.500">
                        {errors.enableSingleOptIn.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors.enablePolicyLookup}>
                    <Checkbox
                      {...register('enablePolicyLookup', {
                        validate: (value) => value !== undefined || value !== null || 'Policy lookup selection is required',
                      })}
                    >
                      Enable policy lookup
                    </Checkbox>
                    {errors.enablePolicyLookup && (
                      <FormHelperText color="red.500">
                        {errors.enablePolicyLookup.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors.enableClaimSubmission}>
                    <Checkbox
                      {...register('enableClaimSubmission', {
                        validate: (value) => value !== undefined || value !== null || 'Claim submission selection is required',
                      })}
                    >
                      Enable claim submission
                    </Checkbox>
                    {errors.enableClaimSubmission && (
                      <FormHelperText color="red.500">
                        {errors.enableClaimSubmission.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </GridItem>

              <GridItem>
                <FormControl>
                  <Flex justify="space-between" align="center">
                    <Box>
                      <FormLabel margin={0}>Escalation</FormLabel>
                      <Text color="gray.600" fontSize="sm">
                        Enable escalation to notify a team member when the case needs attention
                      </Text>
                    </Box>
                    <Switch
                      {...register('escalation')}
                      size="lg"
                      ml={2}
                      mr={0}
                    />
                  </Flex>
                </FormControl>
              </GridItem>

              {watch('escalation') && (
                <>
                  <GridItem>
                    <FormControl>
                      <FormLabel>Timezone</FormLabel>
                      <Select {...register('timezone')}>
                        <option value={TimezoneState.AMERICA_NEW_YORK}>
                          (UTC-05:00) Eastern Time (US)
                        </option>
                        <option value={TimezoneState.AMERICA_CHICAGO}>
                          (UTC-06:00) Central Time (US)
                        </option>
                        <option value={TimezoneState.AMERICA_DENVER}>
                          (UTC-07:00) Mountain Time (US)
                        </option>
                        <option value={TimezoneState.AMERICA_PHOENIX}>
                          (UTC-07:00) Mountain Time - Arizona (US)
                        </option>
                        <option value={TimezoneState.AMERICA_LA}>
                          (UTC-08:00) Pacific Time (US)
                        </option>
                        <option value={TimezoneState.AMERICA_ANCHORAGE}>
                          (UTC-09:00) Alaska Time (US)
                        </option>
                        <option value={TimezoneState.PACIFIC_HONOLULU}>
                          (UTC-10:00) Hawaii Time (US)
                        </option>
                        <option value={TimezoneState.AMERICA_DETROIT}>
                          (UTC-05:00) Eastern Time - Michigan (US)
                        </option>
                        <option value={TimezoneState.AMERICA_INDIANA_INDIANAPOLIS}>
                          (UTC-05:00) Eastern Time - Indiana (US)
                        </option>
                      </Select>
                    </FormControl>
                  </GridItem>

                  {Object.keys(defaultBusinessHours).map((day) => (
                    <GridItem key={day}>
                      <Flex gap={4} align="center">
                        <Checkbox
                          {...register(`escalationSchedule.${day}.enabled`)}
                        >
                          {day}
                        </Checkbox>
                        <Flex flex={1} gap={2} align="center">
                          <Select
                            {...register(`escalationSchedule.${day}.startTime`)}
                            isDisabled={!watch(`escalationSchedule.${day}.enabled`)}
                          >
                            {generate24HoursInHoursAndMinutes().map(
                              ({ displayTime, timeValue }:
                              { displayTime: string, timeValue:string }) => (
                                <option key={`time-${timeValue}`} value={timeValue}>
                                  {displayTime}
                                </option>
                              ),
                            )}
                          </Select>
                          <Text>to</Text>
                          <Select
                            {...register(`escalationSchedule.${day}.endTime`)}
                            isDisabled={!watch(`escalationSchedule.${day}.enabled`)}
                          >
                            {generate24HoursInHoursAndMinutes().map(
                              ({ displayTime, timeValue }:
                              { displayTime: string, timeValue:string }) => (
                                <option key={`time-${timeValue}`} value={timeValue}>
                                  {displayTime}
                                </option>
                              ),
                            )}
                          </Select>
                        </Flex>
                      </Flex>
                    </GridItem>
                  ))}

                  <GridItem>
                    <FormControl>
                      <FormLabel>Escalation email</FormLabel>
                      <Stack spacing={2}>
                        {escalationEmailAddresses.map((_, index) => (
                          <Flex key={`escalation-email-${index}`} direction="column" gap={2}>
                            <Flex gap={2}>
                              <Input
                                {...register(`escalationEmailAddresses.${index}`, {
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address format',
                                  },
                                })}
                                placeholder="escalation-example@test.com"
                                isInvalid={!!errors.escalationEmailAddresses?.[index]}
                              />
                              {escalationEmailAddresses.length > 1 && (
                                <IconButton
                                  aria-label="Remove email"
                                  icon={<Icon as={TrashIcon} />}
                                  variant="ghost"
                                  onClick={() => handleRemoveEmail(index, 'escalationEmailAddresses')}
                                />
                              )}
                              {index === escalationEmailAddresses.length - 1 && (
                                <IconButton
                                  aria-label="Add email"
                                  icon={<AddCircleIcon />}
                                  variant="outline"
                                  onClick={() => handleAddEmail('escalationEmailAddresses')}
                                />
                              )}
                            </Flex>
                            {errors.escalationEmailAddresses?.[index] && (
                              <FormHelperText color="red.500">
                                {errors.escalationEmailAddresses[index]?.message}
                              </FormHelperText>
                            )}
                          </Flex>
                        ))}
                      </Stack>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>Escalation phone number</FormLabel>
                      <Input
                        {...register('escalationPhoneNumber', {
                          validate: (value) => value === '' || validatePhoneNumber(value),
                        })}
                        placeholder="1-555-555-5555"
                        type="tel"
                      />
                      {errors.escalationPhoneNumber && (
                      <FormHelperText color="red.500">
                        {errors.escalationPhoneNumber.message}
                      </FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </>
              )}

            </Grid>
          </ModalBody>

          <ModalFooter borderTop="1px solid" borderColor="gray.200">
            <ButtonGroup spacing={3}>
              <Button
                variant="ghost"
                onClick={closeModalAndReset}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};

export default WorkflowSettingsModal;
