/* eslint-disable max-len */
import _ from 'lodash';
import { v4 } from 'uuid';
import { getCreationSource } from '@app/helpers/creation-source';
import { makeRequest } from './index';
import { API_HOST } from '../../constants/config';
import { showSnackbar } from '../notification';

/** @constant {object} */
// if there is a difference in how we are defining the entities in our redux actions and how they need to be represented in our API calls then it should be defined here in this object
// usually the mapping is just adding camelcase to multiple words that make up the entity name
// key = what the entity is called in redux action
// value = what the entity should be represented as in the API calls
const entityMap = {
  AUTOREPLIES: 'autoReplies',
  PINNEDCHATS: 'pinnedChats',
  VIEWEDCHATS: 'viewedChats',
};

export const apiRequest = {
  get: {
    chat: {
      scheduledMessages: (chatId) => makeRequest({
        host: API_HOST,
        endpoint: `chats/${chatId}/messages?populate=scheduledMessages`,
        method: 'GET',
      }),
    },
    message: { // having difficulty resolving url
      unredactedMessage: (orgId, messageId) => {
        const body = { chatServerUrl: `api/message/${orgId}/${messageId}/view-body` };
        return makeRequest({
          host: API_HOST,
          endpoint: `message/${orgId}/${messageId}/view-body`,
          method: 'GET',
          body,
        });
      },
    },
    user: {
      pinnedChats: (userId) => makeRequest({
        host: API_HOST,
        endpoint: `users/${userId}/pinnedChats`,
        method: 'GET',
      }),
      viewedChats: (userId) => makeRequest({
        host: API_HOST,
        endpoint: `users/${userId}/viewedChats`,
        method: 'GET',
      }),
      pinnedTemplates: (userId) => makeRequest({
        host: API_HOST,
        endpoint: `users/${userId}/pinnedTemplates`,
        method: 'GET',
      }),
    },
  },
  create: {
    message: {
      queue: ({
        chatId, jobId, message, eventTime,
      }) => {
        const body = {
          chatId,
          jobId,
          eventTime,
          messageBody: message,
          messageId: v4(),
          creationSource: getCreationSource(),
          chatServerUrl: `api/chats/${chatId}/messages`,
        };

        return makeRequest({
          host: API_HOST,
          endpoint: `chats/${chatId}/messages`,
          method: 'POST',
          body,
        });
      },
    },
  },
  update: {
    user: {
      pinnedChats: (userId, chatId) => makeRequest({
        host: API_HOST,
        endpoint: `users/${userId}/pinnedChats/${chatId}`,
        method: 'PUT',
      }),
      viewedChats: (userId, chatId) => makeRequest({
        host: API_HOST,
        endpoint: `users/${userId}/viewedChats/${chatId}`,
        method: 'PUT',
      }),
      pinnedTemplates: (userId, templateId) => makeRequest({
        host: API_HOST,
        endpoint: `users/${userId}/pinnedTemplates/${templateId}`,
        method: 'PUT',
      }),
    },
  },
  delete: {
    message: {
      queued: (id) => makeRequest({
        host: API_HOST,
        endpoint: `chats/message/${id}`,
        method: 'DELETE',
      }),
    },
    user: {
      pinnedChats: (userId, chatId) => makeRequest({
        host: API_HOST,
        endpoint: `users/${userId}/pinnedChats/${chatId}`,
        method: 'DELETE',
      }),
      pinnedTemplates: (userId, templateId) => makeRequest({
        host: API_HOST,
        endpoint: `users/${userId}/pinnedTemplates/${templateId}`,
        method: 'DELETE',
      }),
      viewedChats: (userId, chatId) => makeRequest({ // will mark a chat unread
        host: API_HOST,
        endpoint: `users/${userId}/viewedChats/${chatId}`,
        method: 'DELETE',
      }),
    },
  },
};

/**
 * Builds the api endpoint from the entity names and ids
 * only works with APIs that have been created in a standardized REST format
 *
 * @param {string} primaryEntityName 'name of the primary entity'
 * @param {string} secondaryEntityName  'name of the secondary entity, if one is present'
 * @param {Array} entityIds  'contains all the ids for the entities involved, if we need them'
 *
 * @returns {string} endpoint
 */
const buildEndpoint = (primaryEntityName, secondaryEntityName, entityIds) => {
  let endpoint = `${primaryEntityName}`;

  if (entityIds && entityIds.length > 0) {
    endpoint = `${endpoint}/${entityIds[0]}`;

    if (secondaryEntityName && secondaryEntityName.length > 0) {
      endpoint = `${endpoint}/${secondaryEntityName}`;
      if (entityIds && entityIds.length > 1) {
        endpoint = `${endpoint}/${entityIds[1]}`;
      }
    }
  }
  return endpoint;
};
/**
 * Receives the data needed from the frontend component required to make an API call,
 * makes the API call and
 * creates all the redux REQUEST actions needed to track the lifecycle of the API call
 *
 * @param {string} actionType 'strictly formatted to contain api method type and entities to access'
 * @param {Array} entityIds  'contains all the ids for the entities involved, if we need them'
 * @param {object} data  'the data that we need to modify in some way, if at all'
 * @param {boolean} isMobileApi  'extends function so it can be used for mobile api and chat server'
 *
 * @returns dispatchRequest
 */
export const makeApiRequest = (actionType, entityIds = [], data = null, isMobileApi = true, snackBarSuccessMessage = '') => {
  // the format of the actionType must start with the api method, then the names of the entities
  // e.g. GET_USERS_AUTOREPLIES, method = GET, primaryEntityName = USERS, secondaryEntityName = AUTOREPLIES
  const [method, primaryEntityName, secondaryEntityName] = actionType.split(
    '_',
  );
  const primaryEntityApiName = _.get(entityMap, primaryEntityName, primaryEntityName.toLowerCase());
  const secondaryEntityApiName = _.get(entityMap, secondaryEntityName, secondaryEntityName.toLowerCase());
  const endpoint = buildEndpoint(
    primaryEntityApiName,
    secondaryEntityApiName,
    entityIds,
  );
  const options = {
    endpoint,
    method,
  };
  if (isMobileApi) {
    options.host = API_HOST;
  } else {
    options.endpoint = `/api/${options.endpoint}`;
  }
  if (data) {
    options.body = data;
  }
  // eslint-disable-next-line no-use-before-define
  return dispatchRequest(makeRequest(options), {
    type: actionType,
    snackBarSuccessMessage,
  });
};

export const dispatchRequest = (madeRequestCall, {
  type, processPayload, successCallback, snackBarSuccessMessage,
}) => (dispatch, getStore) => {
  dispatch({
    type: `REQUEST_${type}_INIT`,
  });
  madeRequestCall
    .then((res) => {
      dispatch({
        type,
        payload: processPayload ? processPayload(res.data) : res.data,
      });

      dispatch({
        type: `REQUEST_${type}_SUCCESS`,
      });

      if (successCallback) {
        dispatch(successCallback(res.data));
      }
      if (snackBarSuccessMessage) {
        dispatch(
          showSnackbar({ text: snackBarSuccessMessage, isError: false }),
        );
      }
    })
    .catch((err) => {
      dispatch({
        type: `REQUEST_${type}_ERROR`,
        payload: err,
      });
      const errorMessage = (getStore()?.ui?.errors || {})[`REQUEST_${type}_ERROR_MSG`] || 'Something went wrong, please try again';
      dispatch(showSnackbar({ text: errorMessage, isError: true }));
    });
};
