import {
  IconButton,
  Menu,
  MenuDivider,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import {
  CaretDownIcon,
  CalendarIcon,
} from '@himarley/unity';
import React, { Dispatch, SetStateAction } from 'react';

type ScheduleMenuProps = {
  setShowScheduleMessageModal: (value: boolean) => void;
  queueMessage: (object: unknown) => void;
  message: string;
  chatId: string;
  jobId: string;
  disabled: boolean;
  setMessageValue: Dispatch<SetStateAction<string>>;
};

const ScheduleMenu: React.FC<ScheduleMenuProps> = ({
  setShowScheduleMessageModal,
  queueMessage,
  message,
  chatId,
  jobId,
  disabled,
  setMessageValue,
}) => {
  const scheduleMessage = (option: string) => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    let scheduledTime: Date;
    switch (option) {
      case '9am':
        scheduledTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0);
        break;
      case '1pm':
        scheduledTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0, 0);
        break;
      case '5pm':
        scheduledTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0, 0);
        break;
      case '9am-tomorrow':
        scheduledTime = new Date(
          tomorrow.getFullYear(),
          tomorrow.getMonth(),
          tomorrow.getDate(),
          9,
          0,
          0,
        );
        break;
      default:
        throw new Error('Invalid option');
    }

    const formObject = {
      message,
      eventTime: scheduledTime.getTime(),
    };
    queueMessage({
      ...formObject,
      chatId,
      jobId,
    });
    setMessageValue('');
  };

  const currentTime = new Date();
  const isBefore855AM = currentTime.getHours() < 8
    || (currentTime.getHours() === 8 && currentTime.getMinutes() < 55);
  const isBefore1255PM = currentTime.getHours() < 12
    || (currentTime.getHours() === 12 && currentTime.getMinutes() < 55);
  const isBefore455PM = currentTime.getHours() < 16
    || (currentTime.getHours() === 16 && currentTime.getMinutes() < 55);

  return (
    <Menu>
      <Tooltip maxW="175px" textAlign="center" hasArrow label="Schedule Message">
        <MenuButton
          isDisabled={disabled}
          aria-label="Schedule Message"
          as={IconButton}
          icon={<Icon as={CaretDownIcon} />}
          size="sm"
          borderLeft="1px"
          borderColor="white"
          data-testid="scheduled-message-button"
        />
      </Tooltip>
      <MenuList>
        {isBefore855AM && (
          <MenuItem
            data-testid="schedule-item-9am"
            onClick={() => scheduleMessage('9am')}
          >
            Send today at 9:00 AM
          </MenuItem>
        )}
        {isBefore1255PM && (
          <MenuItem
            data-testid="schedule-item-1pm"
            onClick={() => scheduleMessage('1pm')}
          >
            Send today at 1:00 PM
          </MenuItem>
        )}
        {isBefore455PM && !isBefore855AM && !isBefore1255PM && (
          <MenuItem
            data-testid="schedule-item-5pm"
            onClick={() => scheduleMessage('5pm')}
          >
            Send today at 5:00 PM
          </MenuItem>
        )}
        <MenuItem
          data-testid="schedule-item-9am-tomorrow"
          onClick={() => scheduleMessage('9am-tomorrow')}
        >
          Send tomorrow at 9:00 AM
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={<Icon as={CalendarIcon} />}
          data-testid="schedule-item-custom"
          onClick={() => setShowScheduleMessageModal(true)}
        >
          Custom Time
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ScheduleMenu;
