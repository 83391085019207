/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import TootipInfo from '../../elements/Tootip/TooltipInfo';
import './UserNotificationsSettings.less';
import {
  useGetUserAssistRuleQuery,
  useUpdateUserAssistRuleMutation,
} from '../../../services/assist';
import GhostRows from '../../elements/Ghosts/Ghosts';
import SettingsCheckBox from '../../elements/SettingsCheckBox/SettingsCheckBox';
import {
  SettingsTableWrapper,
  SettingsTable,
  SettingsTableInputTitle,
  SettingsTableRow,
  SettingsTableData,
} from '../../elements/table/SettingsTable/SettingsTable';
import {
  IN_TESTING_MODE,
  TEST_ORGANIZATION_ID,
  TEST_USER_ID,
} from '../../../tests/constants';
import { showSnackbar } from '../../../actions/notification';
import usePermissionVerify from '../../elements/hooks/usePermissionVerify';
import { FEATURE_FLAG_TOTAL_LOSS_ASSIST } from '../../../constants/permissions';

const UserNotificationsSettings = () => {
  const { orgId, userId } = useSelector(
    (state) => ({
      orgId: IN_TESTING_MODE
        ? TEST_ORGANIZATION_ID
        : state?.profile?.organizationId,
      userId: IN_TESTING_MODE ? TEST_USER_ID : state?.profile?._id,
    }),
    shallowEqual,
  );
  const { hasAccess: hasTotalLossEnabled } = usePermissionVerify({
    neededPermissions: [FEATURE_FLAG_TOTAL_LOSS_ASSIST],
  });

  const { data, error, isLoading } = useGetUserAssistRuleQuery({
    orgId,
    userId,
    hasTotalLossEnabled,
  });
  // putUserAssistRule is the function that update the userAssistSettings invoking the assist api.
  const [putUserAssistRule, { isLoading: isPutLoading }] = useUpdateUserAssistRuleMutation();
  const [modifiedSettings, setModifiedSettings] = useState([]);
  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      await putUserAssistRule({
        eventTypeArray: modifiedSettings,
        orgId,
        userId,
      }).unwrap();
      dispatch(showSnackbar({ text: 'Settings updated successfully' }));
    } catch (_error) {
      // eslint-disable-next-line no-console
      dispatch(showSnackbar({ text: 'An error occurred while updating user settings' }));
    }
  };

  const snakeToCamelCase = (s) => s.toLowerCase().replace(/(_\w)/g, (w) => w.toUpperCase().substr(1));

  const handleChange = (changedEventType, changedChannels, event) => {
    const channelState = event ? 'selected' : 'unselected';
    const updatedModifiedSettings = modifiedSettings.map((item) => {
      if (item.eventType === changedEventType) {
        const ch = item.channels.map((c) => {
          if (c.type === changedChannels) {
            return {
              ...c,
              state: channelState,
            };
          }
          return c;
        });
        return {
          ...item,
          channels: ch,
        };
      }
      return item;
    });
    setModifiedSettings(updatedModifiedSettings);
    handleSave();
  };

  useEffect(() => {
    // initial load
    if (data && !modifiedSettings.length) {
      setModifiedSettings(data.data);
    }
  }, [data, modifiedSettings, setModifiedSettings]);

  useEffect(() => {
    if (error) {
      dispatch(showSnackbar({ text: 'An error occurred while retrieving user settings', isError: true })); // show snackbar if there's an error getting (GET ) the admin assist rules
    }
  }, [error]);

  return (
    <div className="user-notifications-settings-container">
      <h1
        data-testid="user-notifications-settings-title"
        className="user-notifications-settings-title"
      >
        Notifications Settings
      </h1>
      <div className="user-notifications-settings-intro-container">
        <p className="user-notifications-settings-intro">
          Configure your notification settings.
        </p>
        <TootipInfo message="Your organization determines which notifications you are able to configure below. Please contact your org. admin for additional help" />
      </div>
      {/* TODO:  As a team, we disabled 'restore settings functionally' and will revisit it in the future. As some API work is needed. */}
      {/* <div className="user-notifications-default-container">
        <button type="button" className="user-notifications-settings-restore-btn" onClick={handleRestoreToDefault}>
          Restore to Default
        </button>
      </div> */}
      {error && (
        <div data-testid="user-notifications-server-error-container">
          <p className="user-notifications-settings-server-error">
            An error occurred while retrieving user settings
          </p>
        </div>
      )}
      {isLoading ? (
        <div data-testid="ghost-rows-container">
          <GhostRows type="card" />
        </div>
      ) : modifiedSettings?.length ? (
        <div data-testid="user-notifications-container">
          {modifiedSettings.map((config) => (
            <form
              key={config.eventType}
              data-testid={`user-notification-${snakeToCamelCase(
                config.eventType,
              )}`}
              className="form-body-reset"
            >
              <SettingsTableWrapper>
                <SettingsTable>
                  <SettingsTableInputTitle
                    inputTitle={sortBy(config.channelOptions, (item) => item)}
                  />
                  <SettingsTableRow>
                    <SettingsTableData width="large">
                      <h3>{config.eventTypeHeader}</h3>
                      <p>{config.eventTypeDescription}</p>
                    </SettingsTableData>
                    {sortBy(config.channels, (item) => item.type).map(
                      (channel, index) => (
                        <SettingsTableData key={index} textAlign="center">
                          <SettingsCheckBox
                            disabled={isPutLoading}
                            dataTestId={`checkbox-${snakeToCamelCase(
                              config.eventType,
                            )}-${channel.type}`}
                            type={channel.state}
                            name={channel.type}
                            onChange={(event) => handleChange(
                              config.eventType,
                              channel.type,
                              event,
                            )}
                            selected={
                              channel.state === 'selected'
                              || channel.state === 'optional'
                            }
                          />
                        </SettingsTableData>
                      ),
                    )}
                  </SettingsTableRow>
                </SettingsTable>
              </SettingsTableWrapper>
            </form>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default UserNotificationsSettings;
