import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';
import { isLoadedFromHiMarleyConnect } from '@app/helpers/platform';
import OktaSignInWidget from './OktaSignInWidget';
import './Login.less';

/**
 * Login component that was constructed by very closely following the guidelines outlined
 * in the Okta Sign In Widget documentation for an embedded widget:
 * https://github.com/okta/okta-signin-widget
 * https://developer.okta.com/code/react/okta_react_sign-in_widget/
 *
 */
const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [sessionLookupPending, setSessionLookupPending] = useState(true);
  const [remoteSessionExists, setRemoteSessionExists] = useState(false);

  useEffect(() => {
    if (isLoadedFromHiMarleyConnect()) {
      const handleExtensionMessage = async (event) => {
        // Allow messages from our extension
        const allowedOrigin = `chrome-extension://${process.env.CONNECT_EXTENSION_ID || 'cfmbeleemgicjlggjbddlmckdfllmfch'}`;

        if (event.origin !== allowedOrigin) {
          return;
        }

        if (event.data.action === 'getAccessToken') {
          const accessToken = oktaAuth.getAccessToken();
          const idToken = oktaAuth.getIdToken();
          const refreshToken = oktaAuth.getRefreshToken();

          if (accessToken && idToken) {
            event.source.postMessage(
              {
                action: 'accessToken',
                accessToken,
                idToken,
                refreshToken,
              },
              event.origin,
            );
          } else {
            event.source.postMessage(
              { action: 'needsAuth', source: 'webapp' },
              event.origin,
            );
          }
        }
      };
      window.addEventListener('message', handleExtensionMessage);
      return () => window.removeEventListener('message', handleExtensionMessage);
    }

    // Normal Okta flow for non-iframe
    oktaAuth.session.exists().then((exists) => {
      if (exists) {
        setRemoteSessionExists(exists);
        oktaAuth.token.getWithRedirect({
          responseType: ['token', 'id_token'],
          scopes: ['openid', 'profile', 'email', 'offline_access'],
        });
      }
      setSessionLookupPending(false);
    });
  }, []);

  const onSuccess = (tokens) => {
    console.log('successfully logging in with password');
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log('error logging in with password', err);
  };

  if (
    sessionLookupPending
    || (remoteSessionExists && !authState?.isAuthenticated)
  ) return <div />;

  if (authState?.isAuthenticated) return <Redirect to={{ pathname: '/' }} />;

  return (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  );
};

Login.propTypes = {
  config: PropTypes.instanceOf(Object).isRequired,
};

export default Login;
