import _ from 'lodash';
import isJSON from 'is-json';
import { buildIdList } from './helpers';
import { formatSuccess, formatInit } from '../constants/endpoints';
import commonForm from './commonForm';

export const initialState = {
  list: [],
  listById: {},
  activeItem: {},
  errors: [],
  loadedItem: false,
  loadingItem: false,
  requestCompleted: false,
  editObject: {},
  readonly: false,
  errorMessage: '',
  fullList: [],
  bulkUploadProgressCount: 0,
};

export const matchesSearchFilter = (newItem, filter) => {
  // invalid filter, just return
  if (typeof (filter) !== 'string' || filter === '') return false;
  const customerName = _.get(newItem, 'customer.name', '').toLowerCase(); // customerName, name of the customer, full name
  const displayId = _.get(newItem, 'displayId', '').toLowerCase(); // displayId
  const lowerFilter = filter.toLowerCase();
  const hasCustomerNameMatch = customerName.includes(lowerFilter);
  const hasDisplayIdMatch = displayId.includes(lowerFilter);
  return hasCustomerNameMatch || hasDisplayIdMatch; // if either the customer name or the display id match, add the item to the list of jobs
};

export const reducer = (reducerId, uniqueReducer) => (prestate, action) => {
  const state = commonForm(reducerId, prestate, action);
  switch (action.type) {
    case `LOAD_${reducerId}S`: {
      const { refresh } = action.payload;
      const items = _.get(action.payload, 'items');
      const list = refresh ? items : _.unionBy(state.list, items, 'id');

      return {
        ...state,
        list,
        ...buildIdList(list),
      };
    }
    case `NEW_${reducerId}`: { // TODO different for claims and templates (work like temp)
      const newItem = action.payload;

      /*
        Checks whether updates from pollbox match the current filter in the chats list.
        Without this any new job added will pop up in the chats list even if an operator has already filtered the list down to a few items.
        Ideally I think this would move into live updates when it has been refactored.
      */
      const { filter } = state;
      const hasChatsListFilter = isJSON(JSON.stringify(filter)); // if it is a valid filter json
      const isValidNewItem = newItem.id; // is it REALLY a valid new item?

      // if there is no filter or it's not a valid item, don't bother
      const addItem = hasChatsListFilter && isValidNewItem ? matchesSearchFilter(newItem, filter) : true;

      let changes = {};
      const doesItemAlreadyExist = _.get(state, `listById.${newItem.id}`, false); // No double adding!!!
      if (addItem && isValidNewItem && !doesItemAlreadyExist) {
        let list = [...state.list];
        list.push(newItem);
        if (action?.type === 'NEW_GROUP') {
          list = _.orderBy(list, ['name'], ['asc']);
        }
        changes = {
          list,
          ...buildIdList(list),
        };
      }
      return { ...state, ...changes };
    }
    case `REQUEST_ADD_${reducerId}_ERROR`: {
      return {
        ...state,
        errorMessage: _.get(action, 'payload.error', ''),
      };
    }
    case `ADD_${reducerId}`: {
      return {
        ...state,
        requestCompleted: true,
      };
    }
    case `MODIFIED_${reducerId}`: {
      const updatedItem = action.payload;
      const list = _.map(
        state.list,
        (item) => ((item.id === updatedItem.id) ? updatedItem : item),
      );
      return {
        ...state, list, ...buildIdList(list), requestCompleted: true,
      };
    }
    case `UPDATE_${reducerId}`: {
      const updatedItem = action.payload;
      const list = _.map(
        state.list,
        (item) => ((item.id === updatedItem.id) ? updatedItem : item),
      );
      return { ...state, list, requestCompleted: true };
    }
    case formatInit(`LOAD_${reducerId}S`): {
      const loadingItem = _.get(action, 'payload.refresh', false);
      return { ...state, loadedItem: false, loadingItem };
    }
    case formatSuccess(`LOAD_${reducerId}S`): {
      return { ...state, loadedItem: true, loadingItem: false };
    }
    case formatSuccess(`EDIT_${reducerId}`): {
      return {
        ...state, requestCompleted: true, editObject: {}, httpRequestError: false,
      };
    }
    case formatSuccess(`ADD_${reducerId}`): {
      return {
        ...state, requestCompleted: true, editObject: {}, httpRequestError: false,
      };
    }
    default:
      return uniqueReducer && uniqueReducer(state, action);
  }
};
