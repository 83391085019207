/* eslint-disable import/prefer-default-export */
import {
  useInfiniteQuery,
} from '@tanstack/react-query';
import axios from 'axios';

import { QUERY_KEYS } from './constants';
import {
  buildPaginatedQueryString,
} from './helpers';
import { GET_GROUPS } from '../constants/endpoints';
import { getAccessToken } from '../helpers/localStorage';
import { IN_TESTING_MODE, TEST_AUTH_TOKEN } from '../tests/constants';

export function useGroupsQuery(customFilters: { searchText: string; ids?: string[]; }) {
  const filters = { ...customFilters, limit: 32 };
  async function fetchGroups({ pageParam = 1 }) {
    const filterQueryString = buildPaginatedQueryString(pageParam, filters);
    const url = `${GET_GROUPS}${
      filterQueryString ? `?${filterQueryString}` : ''
    }`;

    const token = IN_TESTING_MODE ? TEST_AUTH_TOKEN : await getAccessToken();

    return axios({
      url,
      method: 'GET',
      headers: { Authorization: token },
    }).then((response) => response.data);
  }

  return useInfiniteQuery([QUERY_KEYS.GROUPS, filters], fetchGroups, {
    getNextPageParam: (lastPage, pages) => {
      if ((lastPage?.groups?.length || 0) < filters.limit) return undefined;
      return (pages?.length || 0) + 1;
    },
  });
}
