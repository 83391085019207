export interface EmailRecipient {
  name: string;
  role: string;
  value: string;
  enabled: boolean;
}

export enum TimezoneState {
  AMERICA_CHICAGO = 'America/Chicago',
  AMERICA_NEW_YORK = 'America/New_York',
  AMERICA_LA = 'America/Los_Angeles',
  AMERICA_DENVER = 'America/Denver',
  AMERICA_DETROIT = 'America/Detroit',
  PACIFIC_HONOLULU = 'Pacific/Honolulu',
  AMERICA_ANCHORAGE = 'America/Anchorage',
  AMERICA_PHOENIX = 'America/Phoenix',
  AMERICA_INDIANA_INDIANAPOLIS = 'America/Indiana/Indianapolis',
}

export interface BusinessHours {
  start: string;
  end: string;
}

export interface WeeklyBusinessHours {
  Monday: BusinessHours;
  Tuesday: BusinessHours;
  Wednesday: BusinessHours;
  Thursday: BusinessHours;
  Friday: BusinessHours;
  Saturday: BusinessHours;
  Sunday: BusinessHours;
}

export interface EscalationSettings {
  outsideBusinessHours?: {
    carrierEscalationNumber: string;
  };
  withinBusinessHours?: {
    emailTo: EmailRecipient[];
  };
}

export interface WorkflowSettings {
  carrierDisplayName: string;
  timeZone: TimezoneState;
  businessHours?: WeeklyBusinessHours | null;
  escalation?: EscalationSettings | null;
  carrierPhone: string;
  redirectPhone?: string | null;
  carrierWebsite: string;
  policyLookupEnabled: boolean;
  claimSubmissionEnabled: boolean;
  emailEnabled: boolean;
  emailTo: EmailRecipient[];
  singleOptInEnabled: boolean;
}

export interface WorkflowSettingsWithMetadata extends WorkflowSettings {
  workflowName: string;
  keywordTrigger?: string;
}
